import {useDispatch, useSelector} from "react-redux";
import {set_graph_date} from "../redux/Common/actions";

const useGraphDate = () => {
    const dispatch = useDispatch()
    const graphDate = useSelector(state => {
        const {commonReducer} = state
        return commonReducer.graphDate
    })

    const setGraphDate = value => {
        dispatch(set_graph_date(value))
    }

    return {
        graphDate,
        setGraphDate
    }
}

export default useGraphDate