import Container from "../../components/Container/Container";
import './Main.css'
import ErrorField from "../../components/ErrorField/ErrorField";
import LoaderWindow from "../../components/LoaderWindow/LoaderWindow";
import MainViewManager from "../../components/managers/MainViewManager";

/**
 * Block for content. Here we have all products and selector for them.
 * This Component shows window with scope of instruments for selected product
 *
 *
 *
 * @returns {JSX.Element} Return component to render into Pricing Component
 * @constructor
 */
const Main = () => {
    return (
        <div className="main">
            <ErrorField/>
            <LoaderWindow/>
            {/*<Container>*/}
                <div className="main-wrapper">
                    <MainViewManager/>
                </div>
            {/*</Container>*/}
        </div>
    )
}
export default Main
