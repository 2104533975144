import {useDispatch, useSelector} from "react-redux";
import {
    set_fixing_dates_spread_option,
    set_period_mode_spread_option,
    set_time_to_maturity_spread_option
} from "../../redux/SpreadOption/actions";

export const useProductExpiry = () => {
    const dispatch = useDispatch()
    const [fixingDates, tradingDate, periodMode, time2maturity] = useSelector(state => {
         const {spreadOptionReducer} = state
         return [spreadOptionReducer.fixingDates, spreadOptionReducer.tradingDate, spreadOptionReducer.periodMode, spreadOptionReducer.timeToMaturity]
    })

    const setFixingDates = (value) => {
        if (periodMode === "years") {
            value = Math.round(value * 365)
        }
        const tmp = new Date(tradingDate)
        tmp.setDate(tmp.getDate() + value)
        // dispatch(set_fixing_date_spread_option([tmp.toISOString().slice(0, 10)]))
        dispatch(set_fixing_dates_spread_option([tmp.toISOString().slice(0, 10)]))
        dispatch(set_time_to_maturity_spread_option((value / 365).toFixed(2)))
    }

    const setFixingDate = (value) => {
        const tmpDate = new Date(value)
        const tmp = new Date(tradingDate)
        dispatch(set_time_to_maturity_spread_option(((tmpDate - tmp) / (1000 * 60 * 60 * 24) / 365).toFixed(2)))
        dispatch(set_fixing_dates_spread_option([value]))
        // dispatch(set_fixing_date_spread_option([value]))
    }

    const setPeriodMode = (value) => {
        dispatch(set_period_mode_spread_option(value))
    }

    return {
        title: "Expiry",
        data: [
            {
                name: `Term, ${periodMode}`,
                value: periodMode === "days" ? Math.round(time2maturity * 365) : time2maturity,
                onChange: setFixingDates,
                type: {
                    name: "number"
                }
            },
            {
                name: "Term, prd",
                value: periodMode,
                onChange: setPeriodMode,
                type: {
                    name: "selector",
                    options: ["days", "years"]
                }
            },
            {
                name: "Expiry date",
                value: fixingDates,
                onChange: setFixingDate,
                type: {
                    name: "date"
                }
            }
        ]
    }
}