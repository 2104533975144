import {useDispatch, useSelector} from "react-redux";
import {set_refresh_results} from "../../../redux/Autocall/actions";

const SettingsRecalculate = () => {
    const dispatch = useDispatch()
    const recalculate = useSelector(state => state.autocallReducer.refreshResult)
    return (
        <div>
            <label className={"settings--label"}>
                Recalculate
                <input type="checkbox" checked={recalculate}
                       onChange={() => dispatch(set_refresh_results(!recalculate))}/>
            </label>
        </div>
    )
}

export default SettingsRecalculate;