import {useSelector} from "react-redux";
import useViewMode from "./useViewMode";
import useProductVanilla from "./pricingParts/useProductVanilla";
import useProductAutocall from "./pricingParts/useProductAutocall";
import useProductBasket from "./pricingParts/useProductBasket";
import useTradeDetails from "./useTradeDetails";
import useSystemData from "./useSystemData";
import useProductParticipation from "./pricingParts/useProductParticipation";
import useEvents from "./useEvents";
import useProductDigitalCall from "./pricingParts/useProductDigitalCall";
import useProductDigitalCallShort from "./pricingParts/useProductDigitalCallShort";
import {useProductSpreadOption} from "./pricingParts/useProductSpreadOption";

const useStringifyData = () => {
    const viewMode = useViewMode()
    const vanillaData = useProductVanilla()
    const autocallData = useProductAutocall()
    const basketData = useProductBasket()
    const participationData = useProductParticipation()
    const digitalCallData = useProductDigitalCall()
    const digitalCallShortData = useProductDigitalCallShort()
    const spreadOptionData = useProductSpreadOption()
    const tradeDetails = useTradeDetails()
    const system = useSystemData()
    const {events} = useEvents()


    const [divs, fixings, spots, rate, vols, pricingDate, corrMtx, serviceVols, serviceDivs] = useSelector((state) => {
        const {MarketDataReducer} = state
        return [
            MarketDataReducer.divs,
            MarketDataReducer.fixings,
            MarketDataReducer.spots,
            MarketDataReducer.rate,
            MarketDataReducer.vols,
            MarketDataReducer.pricingDate,
            MarketDataReducer.corrMtx,
            MarketDataReducer.serviceVols,
            MarketDataReducer.serviceDivs
        ]
    })

    const resultVols = {}
    Object.keys(serviceVols).forEach((key) => {
        // console.log('typeof vols[key]', typeof vols[key], vols[key])
        if (typeof serviceVols[key] === "number") {
            resultVols[key] = serviceVols[key]
        } else {
            resultVols[key] = vols[key]
        }
    })

    const resultDivs = {}
    Object.keys(serviceDivs).forEach((key) => {
        if (typeof serviceDivs[key] === "number") {
            resultDivs[key] = serviceDivs[key]
        } else {
            resultDivs[key] = divs[key]
        }
    })

    let product = undefined
    if (viewMode.viewMode === "Vanilla") {
        product = vanillaData
    } else if (viewMode.viewMode === "Autocall") {
        product = autocallData
    } else if (viewMode.viewMode === "Call On Basket") {
        product = basketData
    } else if (viewMode.viewMode === "Protected Participation") {
        product = participationData
    } else if (viewMode.viewMode === "Digital Call on Basket") {
        product = digitalCallData
    } else if (viewMode.viewMode === "Digital Call") {
        product = digitalCallShortData
    } else if (viewMode.viewMode === "Spread Option") {
        product = spreadOptionData
    }


    return JSON.stringify({
        product: product,
        events: events ?? {},
        market_data: {
            class_name: "_MarketData_v2",
            pricing_date: pricingDate,
            rate: rate,
            version: "v1.0_20230407",
            spots: spots,
            corr_mtx: corrMtx,
            vols: resultVols,
            divs: resultDivs,
            fixings: fixings // TODO что это?
        },
        position: {
            notional: tradeDetails.notional,
        },
        system: system
    })
}

export default useStringifyData