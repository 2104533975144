import useViewMode from "../../../hooks/useViewMode";
import SettingsAutocall from "../SettingsAutocall/SettingsAutocall";
import SettingsVanilla from "../SettingsAutocall/SettingsVanilla";
import SettingsBasket from "../SettingsAutocall/SettingsBasket";
import SettingsParticipation from "../SettingsAutocall/SettingsParticipation";

const SettingsManager = () => {
    const viewMode = useViewMode()
    if (viewMode.viewMode === "Autocall") return <SettingsAutocall/>
    else if (viewMode.viewMode === "Vanilla") return <SettingsVanilla/>
    else if (viewMode.viewMode === "MarketData") return null
    else if (viewMode.viewMode === "Call On Basket") return <SettingsBasket/>
    else if (viewMode.viewMode === "Protected Participation") return <SettingsParticipation/>
    else return null
}

export default SettingsManager;