import PricingView from "../../routes/Main/PricingView/PricingView";
import {useEffect} from "react";
import useRefreshMarketData from "../../hooks/useRefreshMarketData";
import useSecondRefresh from "../../hooks/useSecondRefresh";
import {useParams} from "react-router-dom";
import useRefreshByRequestId from "../../hooks/useRefreshByRequestId";

const Vanilla = ({mode}) => {
    const refreshManager = useRefreshMarketData()
    const {iid} = useParams()
    const updateManager = useRefreshByRequestId()
    const secondUpdate = useSecondRefresh()

    // useEffect(() => {
    //     refreshManager.refreshMD()
    //         .then(() => {
    //             if (iid !== undefined) {
    //                 setTimeout(() => {
    //                     updateManager.refresh(iid)
    //                 }, 1000)
    //             }
    //         })
    // }, [])
    //
    // // Это прячем в refreshManager (логика - которая описана для вторичного обновления дублируется или же в целом не используется при последующих обновлениях. Надо это учесть и сразу описать в рефреше, чтобы не дублировать код
    // useEffect(() => {
    //     if (refreshManager.isRefresh) {
    //         secondUpdate.refreshVanilla()
    //     }
    // }, [refreshManager.isRefresh])


    return (
        <PricingView mode={mode}/>
    )
}

export default Vanilla;