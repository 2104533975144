import image from './../../../../static/selected_option.svg'
import './SelectedOption.css'

const SelectedOption = ({setState}) => {
    return (
        <div className={`selected_option`} onClick={() => {setState(false)}}>
            <img src={image} alt={`selected option`}/>
        </div>
    )
}
export default SelectedOption