import './Footer.css'
import {useState} from "react";
import Button from "../../components/Button/Button";
import ResultField from "../../components/ResultField/ResultField";
import {useSelector} from "react-redux";
import {setLocalStorageHistory, setLSPricingHistory} from "../../utils/localStorageHandler";
import PricingHistory from "../PricingHistory/PricingHistory";
import Modal from "../../components/Modal/Modal";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import Settings from "../../components/Settings/Settings";
import useStringifyData from "../../hooks/useStringifyData";
import useLocalStorage from "../../hooks/useLocalStorage";
import useChangeState from "../../hooks/useChangeState";
import useViewMode from "../../hooks/useViewMode";
import useRefreshByRequestId from "../../hooks/useRefreshByRequestId";
import useInfoPopup from "../../hooks/useInfoPopup";
import useGetId from "../../hooks/DataGetters/useGetId";
import InputText from "../../components/InputText/InputText";
import searchImg from "../../static/refresh.svg";
import copy from "clipboard-copy";
import copyImg from "../../static/copy.svg";
import useGreeks from "../../hooks/useGreeks";
import useRequest from "../../hooks/useRequest";
import {makeLog} from "../../utils/logger";

const Footer = () => {
    const [price, setPrice] = useState('PV')
    const [description, setDescription] = useState('No description yet')
    const [modalActive, setModalActive] = useState(false)
    const [pricingRequestId, setPricingRequestId] = useState('')
    const [isModalActive, setIsModalActive] = useState(false)
    const requestData = useStringifyData()
    const {get, post} = useRequest()
    const getId = useGetId()
    const changeState = useChangeState()
    const localStorageDriver = useLocalStorage()
    const greeks = useGreeks()
    const viewMode = useViewMode()
    const updateManager = useRefreshByRequestId()
    const infoPopup = useInfoPopup()
    const [load, setLoad] = useState(false)
    const [refresh_results, tickers, tradingDate,
        fixingDates, currency] = useSelector((store) => {
        const {autocallReducer} = store
        return [autocallReducer.refreshResult, autocallReducer.tickers, autocallReducer.tradingDate,
            autocallReducer.fixingDates,  autocallReducer.currency]
    })

    const [corrMtx, divs, vols,
        fixings, pricingDate, rate,
        spots] = useSelector(state => {
        const {MarketDataReducer} = state
        return [MarketDataReducer.corrMtx, MarketDataReducer.divs, MarketDataReducer.vols,
            MarketDataReducer.fixings, MarketDataReducer.pricingDate, MarketDataReducer.rate,
            MarketDataReducer.spots]
    })

    const saveHistory = (pv, requestId, description) => {
        console.log("-- tickers --", tickers)
        setLSPricingHistory(JSON.stringify({
            N: Number(localStorage.getItem("pricingHistoryLen")) + 1,
            requestID: requestId,
            PV: `${(pv).toFixed(1)}%`,
            TickerX: tickers.join("/"),
            Trading: tradingDate,
            Expiry: fixingDates.at(-1),
            description: description
        }))
    }

    const saveHistoryVanilla = (pv, requestId, description) => {
        const priceData = JSON.parse(requestData)
        setLSPricingHistory(JSON.stringify({
            N: Number(localStorage.getItem("pricingHistoryLen")) + 1,
            requestID: requestId,
            PV: `${(pv).toFixed(1)}%`,
            TickerX: priceData.product.tickers.join("/"),
            Trading: priceData.product.trading_date,
            Expiry: priceData.product.fixing_dates.at(-1),
            description: description
        }))
    }

    const calcPrice = () => {
        if (!Object.keys(rate).includes(currency)) {
            infoPopup.setPopupStatus(true, "multicurrency autocall not implemented. please choose same currency for all", false)
            return
        }
        if (JSON.parse(requestData).product.tickers.length === 0) {
            infoPopup.setPopupStatus(true, "tickers not found", false)
            return
        }
        if (viewMode.viewMode === "Spread Option") {
            if (JSON.parse(requestData).product.tickers.length < 2) {
                infoPopup.setPopupStatus(true, "tickers count must be 2", false)
                return
            }
            if (JSON.parse(requestData).product.tickers[0] === JSON.parse(requestData).product.tickers[1]) {
                infoPopup.setPopupStatus(true, "tickers must be different", false)
                return
            }
        }
        setLoad(true)
        post(
            `/rdict/post`,
            JSON.parse(requestData.replaceAll("null", 0)),
            undefined,
            (data) => {
                console.log("[ requesting data ]", JSON.parse(requestData))
                getId.updateMdId(JSON.parse(requestData).market_data)
                setLocalStorageHistory(`[ success push ]\t / ${new Date().toUTCString()} / ${data} `)
                get(
                    `/price/${data}?refresh_results=${refresh_results ?? false}`,
                    undefined,
                    (resp) => {
                        setLocalStorageHistory(`[success update]\t / ${new Date().toUTCString()} / ${resp} `)
                        console.warn(resp)
                        greeks.setGreeks(resp)
                        const pv = Number(resp.PV) * 100
                        setPrice(pv.toFixed(2) + '%')
                        setDescription(resp.description)
                        if (viewMode.viewMode === "Autocall") saveHistory(pv, data, resp.description)
                        else saveHistoryVanilla(pv, data, resp.description)
                        setPricingRequestId(data)
                        changeState.setChange(false)
                        localStorageDriver.setLocalStoreLastData()
                        infoPopup.setPopupStatus(true, `Success request: ${data}`, true)
                    },
                    (err) => {
                        setLocalStorageHistory(`[fail update]\t / ${new Date().toUTCString()} / ${err.message} `)
                        setPricingRequestId(data)
                    })
                    .finally(() => {
                        setLoad(false)
                    })

            },
            (err) => {
                setLocalStorageHistory(`[fail push]\t / ${new Date().toUTCString()} / ${err.message} `)
                // infoPopup.setPopupStatus(true, "Error server request. See browser's log", false)
                console.log(err)
                console.warn(JSON.parse(requestData))
            }
        )
            .then(() => {
                console.log(" __ pricing is Done __ ")
            })

        // axios.post(`/rdict/post`, JSON.parse(requestData))
        //     .then(resp => {
        //         console.log("[ requesting data ]", JSON.parse(requestData))
        //         // hh = resp.data
        //         getId.updateMdId(JSON.parse(requestData).market_data)
        //         setLocalStorageHistory(`[ success push ]\t / ${new Date().toUTCString()} / ${resp.data} `)
        //         return resp.data
        //     })
        //     .then(data => {
        //         console.log("[ requesting ID ]", data)
        //         axios({
        //             url: `/price/${data}?refresh_results=${refresh_results}`
        //         })
        //             .then(resp => {
        //                 setLocalStorageHistory(`[success update]\t / ${new Date().toUTCString()} / ${resp.data} `)
        //                 const result = resp.data
        //                 console.warn(result)
        //                 greeks.setGreeks(result)
        //                 const pv = Number(result.PV) * 100
        //                 setPrice(pv.toFixed(2) + '%')
        //                 console.log("work")
        //                 setDescription(result.description)
        //                 if (viewMode.viewMode === "Autocall") saveHistory(pv, data, result.description)
        //                 else saveHistoryVanilla(pv, data, result.description)
        //                 console.log("work 2")
        //                 setPricingRequestId(data)
        //                 changeState.setChange(false)
        //                 localStorageDriver.setLocalStoreLastData()
        //                 infoPopup.setPopupStatus(true, `Success request: ${data}`, true)
        //             })
        //             .catch(e => {
        //                 infoPopup.setPopupStatus(true, "Error Request", false)
        //                 setLocalStorageHistory(`[fail update]\t / ${new Date().toUTCString()} / ${e.message} `)
        //             })
        //             .finally(() => {
        //                 setLoad(false)
        //             })
        //     }).catch((e) => {
        //     setLocalStorageHistory(`[fail push]\t / ${new Date().toUTCString()} / ${e.message} `)
        //     infoPopup.setPopupStatus(true, "Error server request. See browser's log", false)
        //     console.log(e)
        //     console.warn(JSON.parse(requestData))
        // })
    }

    const pushResult = () => {
        const res = {
            pricing_date: pricingDate,
            rate: rate,
            spots: spots,
            corr_mtx: corrMtx,
            fixings,
            vols,
            divs
        }
        setDescription(undefined)
        setLoad(true)
        post (
            "/rdict/post",
            res,
            undefined,
            (data) => {
                setPrice(data)
            }
        )
            .finally(() => setLoad(false))
        // axios.post(`/rdict/post`, res)
        //     .then(resp => setPrice(resp.data))
        //     .catch(e => console.error(e))
        //     .finally(() => setLoad(false))
    }

    return (
        <div className={`footer`}>
            <Modal isActive={modalActive}>
                <PricingHistory isActive={modalActive} setIsActive={(st) => setModalActive(st)}/>
            </Modal>
            <ModalWindow content={<Settings/>} isActive={isModalActive} setIsActive={setIsModalActive} smallSize/>
            <div className={`footer-wrapper`}>
                <div className={`price-nav`}>
                    {viewMode.viewMode === "MarketData"
                        ? <Button text={"Push"} styleClass={"price_btn"} callback={() => pushResult()}/>
                        : <Button text={`Price`} styleClass={"price_btn"} callback={() => {
                            makeLog("__price__", "CLICK", "CLICK")
                            calcPrice()
                        }}/>
                    }
                    <Button text={`Settings`} styleClass={"settings_btn"} callback={() => setIsModalActive(true)}/>
                </div>
                <div className={`price-result_block`}>
                    <ResultField load={load} state={price} description={description}/>
                    {/*<Input type={'text'} defaultValue={pricingRequestId} label={'Request ID'}*/}
                    {/*       styleClassName={'pricing-result_request'}*/}
                    {/*       extraClass={`${changeState.isChange ? "through-line" : ""}`}*/}
                    {/*       changeCallback={(val) => {*/}
                    {/*           setPricingRequestId(val)*/}
                    {/*       }}*/}
                    {/*       propertyBtn={{*/}
                    {/*           content: 'img',*/}
                    {/*           img: img,*/}
                    {/*           onClickCallback: (value) => {*/}
                    {/*               updateManager.refresh(value, true)*/}
                    {/*               changeState.setChange(false)*/}
                    {/*           }*/}
                    {/*       }}/>*/}
                    <div className={"request-id__search-line"}>
                        <InputText value={pricingRequestId}
                                   changeCallback={value => setPricingRequestId(value)}
                                   title={`Request ID`}/>
                        <div className={`request-id__search-btn`}
                             onClick={() => {
                                 updateManager.refresh(pricingRequestId, true)
                                 changeState.setChange(false)
                             }}>
                            <img src={searchImg} alt=""/>
                        </div>
                        <div className={`request-id__search-copy`}
                             onClick={() => {
                                 copy(pricingRequestId)
                                     .then(() => {
                                         infoPopup.setPopupStatus(true, "Success copy", true)
                                     })
                             }}>

                            <img src={copyImg} alt="copy"/>
                        </div>
                    </div>
                </div>
                <Button text={`Pricing History`}
                        callback={() => setModalActive(true)}
                        styleClass={"button-pricing-history-footer"}/>
            </div>
        </div>
    )
}
export default Footer