import {useSelector} from "react-redux";

const useProductDigitalCall = () => {
    const [acStrike, noACPeriod, acStepdown, isWithoutNotional,
        timeToMaturity, scheduleFreq, cpnStrike, fixedCPNCNT,
        cpnAMTpa, strike, barrier, tradingDate,
        acStrikes, cpnStrikes, fixedCPNAMT, fixingDates,
        isMemory, refSpots, tickers, acPerformance,
        cpnPerformance, finalPerformance, currency, isCallPayoff] = useSelector((state) => {
        const {digitalCallReducer} = state
        return [
            digitalCallReducer.acStrike, digitalCallReducer.noACPeriod,
            digitalCallReducer.acStepdown, digitalCallReducer.isWithoutNotional,
            digitalCallReducer.timeToMaturity, digitalCallReducer.scheduleFreq,
            digitalCallReducer.cpnStrike, digitalCallReducer.fixedCPNCNT,
            digitalCallReducer.cpnAMTpa, digitalCallReducer.strike,
            digitalCallReducer.barrier,
            digitalCallReducer.tradingDate, digitalCallReducer.acStrikes,
            digitalCallReducer.cpnStrikes, digitalCallReducer.fixedCPNAMT,
            digitalCallReducer.fixingDates, digitalCallReducer.isMemory,
            digitalCallReducer.refSpots, digitalCallReducer.tickers,
            digitalCallReducer.acPerformance,
            digitalCallReducer.cpnPerformance, digitalCallReducer.finalPerformance,
            digitalCallReducer.currency, digitalCallReducer.isCallPayoff
        ]
    })
    return {
        ac_stepdown: acStepdown,
        ac_strike: acStrike,
        ac_strikes: acStrikes,
        barrier: barrier,
        cpn_amt_pa: cpnAMTpa,
        cpn_strike: cpnStrike,
        cpn_strikes: cpnStrikes,
        class_name: "Autocall",
        currency: currency,
        fixed_cpn_amt: fixedCPNAMT,
        fixed_cpn_cnt: fixedCPNCNT,
        fixing_dates: fixingDates,
        is_call_payoff: isCallPayoff,
        is_memory: isMemory,
        is_without_notional: isWithoutNotional, // TODO tmp
        no_ac_period: noACPeriod,
        ref_spots: refSpots,
        schedule_freq: scheduleFreq,
        strike: strike,
        tickers: tickers,
        time2maturity: Math.round(timeToMaturity * 365),
        trading_date: tradingDate,
        typology: "EQS", // TODO Где взять?
        version: "v1.0_20232701_1",
        barrier_types: {
            ac: acPerformance,
            cpn: cpnPerformance,
            put: finalPerformance
        }
    }
}

export default useProductDigitalCall