import {useDispatch, useSelector} from "react-redux";
import {set_refresh_mode} from "../redux/Common/actions";

const useRefreshMode = () => {
    const dispatch = useDispatch();
    const [refreshMode, refreshModes] = useSelector(state => {
        const {commonReducer} = state;
        return [commonReducer.refreshMode, commonReducer.refreshModes]
    })

    const setRefreshMode = (mode) => {
        if (mode === undefined) {
            console.log("[ receive undefined mode ]")
            return
        }
        // if (mode === "DEFAULT" || mode === "HEDGING") {
        //     localStorage.setItem("refresh_mode", mode)
        // }
        dispatch(set_refresh_mode(mode))
    }

    return {
        refreshMode,
        refreshModes,
        setRefreshMode
    }
}

export default useRefreshMode;