import "./HistoryMini.css"
import {getLSPricingHistory} from "../../utils/localStorageHandler";
import copy from "clipboard-copy";
import useInfoPopup from "../../hooks/useInfoPopup";
import {Tooltip} from "@mui/material";

const HistoryMini = ({close}) => {
    const history = getLSPricingHistory()
    const infoPopup = useInfoPopup()


    const popupCopy = value => {
        copy(value)
            .then(() => {
                infoPopup.setPopupStatus(true, "Copied to clipboard", true)
            })
            .catch(e => {
                console.error(e)
                infoPopup.setPopupStatus(true, "Error", false)
            })
    }

    return (
        <div className={"history__window"}>
            <div className={"history__head"}>
                <p className={"history__row--id"}>ID</p>
                <p className={"history__row--pv"}>PV</p>
                <p className={"history__row--des"}>Description</p>
            </div>
            {history.length === 0 &&
                <div className={"history__show-all"}>
                    <p>No trades</p>
                </div>
            }
            {history.slice(0, 5).map((elem, i) => {
                const el = JSON.parse(elem)
                let resDes = el.description
                if (el.description.length >= 35 * 3) {
                    resDes = el.description.slice(0, 35 * 3) + "..."
                }
                return <div key={i} className={"history__row"}>
                    <p onClick={() => popupCopy(el.requestID)} className={"history__row--id copy"}>{el.requestID}</p>
                    <p className={"history__row--pv"}>{el.PV}</p>
                    <Tooltip title={el.description} arrow>
                        <p onClick={() => popupCopy(el.description)} className={"history__row--des copy"}>{resDes}</p>
                    </Tooltip>

                </div>
            })}
            {history.length > 5 &&
                <div className={"history__show-all"}
                     onClick={() => close(true)}>
                    Full History
                </div>
            }

        </div>
    )
}

export default HistoryMini;