import './Modal.css'
import {useEffect} from "react";

const Modal = ({isActive, children}) => {
    useEffect(() => {
        document.body.style.overflow = isActive ? 'hidden' : 'auto'
    }, [isActive])
  return (
      <div className={`modal ${isActive ? 'active' : ''}`}>
          {children}
      </div>
  )
}
export default Modal