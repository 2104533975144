import {digitalCallReducer} from "../../redux/DigitalCall/digitalCallReducer";
import './MarketDataWindow.css'
import {useSelector} from "react-redux";
import MarketDataLine from "../../components/MarketDataLine/MarketDataLine";
import useViewMode from "../../hooks/useViewMode";
import useRefreshFields from "../../hooks/useRefreshFields";
import useRefreshMode from "../../hooks/useRefreshMode";
import useInfoPopup from "../../hooks/useInfoPopup";

const MarketDataWindow = () => {
    const viewMode = useViewMode()
    const refreshFields = useRefreshFields()
    const refreshMode = useRefreshMode()
    const infoPopup = useInfoPopup()
    const tickers = useSelector((store) => {
        const {autocallReducer, VanillaReducer, MarketDataProductReducer, BasketReducer,
            ParticipationReducer, digitalCallReducer, digitalCallShortReducer,
        spreadOptionReducer} = store
        // TODO :product:
        if (viewMode.viewMode === 'Autocall') {
            return autocallReducer.tickers
        } else if (viewMode.viewMode === 'Vanilla') {
            return VanillaReducer.tickersVanilla
        } else if (viewMode.viewMode === 'MarketData') {
            return MarketDataProductReducer.tickersMD
        } else if (viewMode.viewMode === 'Call On Basket') {
            return BasketReducer.tickersBasket
        } else if (viewMode.viewMode === "Protected Participation") {
            return ParticipationReducer.tickersParticipation
        } else if (viewMode.viewMode === "Digital Call on Basket") {
            return digitalCallReducer.tickers
        } else if (viewMode.viewMode === "Digital Call") {
            return digitalCallShortReducer.tickers
        } else if (viewMode.viewMode === "Spread Option") {
            return spreadOptionReducer.tickers
        }
    })

    const changeRefreshFields = (field, value) => {
        if (refreshMode.refreshMode !== "CUSTOM") {
            infoPopup.setPopupStatus(true, "Switched source of refresh MarketData to CUSTOM", true)
            refreshMode.setRefreshMode("CUSTOM")
        }
        refreshFields.setRefreshFields(field, value)
    }

    return (
        <div className="market_data_window-scroll">
            <div className={"market_data_window"}>
                <div className="market_data_window-header">
                    <div className="market_data_line--name">
                        Name
                    </div>
                    <div className="market_data_line--spot market_data-checkbox">
                        <p>Spot</p>
                        <input type="checkbox" checked={refreshFields.refreshFields.spots}
                               onChange={() => changeRefreshFields("spots", !refreshFields.refreshFields.spots)}/>
                    </div>
                    <div className="market_data_line--vol market_data-checkbox">
                        <p>Vol</p>
                        <input type="checkbox" checked={refreshFields.refreshFields.vols}
                               onChange={() => changeRefreshFields("vols", !refreshFields.refreshFields.vols)}/>
                    </div>
                    <div className="market_data_line--div market_data-checkbox">
                        <p>Divs</p>
                        <input type="checkbox" checked={refreshFields.refreshFields.divs}
                               onChange={() => changeRefreshFields("divs", !refreshFields.refreshFields.divs)}/>
                    </div>
                    <div className="market_data_line--ref_spot">
                        Ref
                    </div>
                </div>
                {tickers.map((ticker, i) => (
                    <div key={i} className={'market-data-window'}>
                        <MarketDataLine ticker={ticker}/>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default MarketDataWindow