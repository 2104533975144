import './Logo.css'
import logo from '../../static/logo.jpg'
import {useNavigate} from "react-router-dom";
import useViewMode from "../../hooks/useViewMode";
import useMainView from "../../hooks/useMainView";
// import logo from '../../static/logo_full.svg'

const Logo = () => {
    const navigate = useNavigate()
    const viewMode = useViewMode()
    const mainView = useMainView()
    return (
        <div className="logo" onClick={() => {
            viewMode.setViewMode("Autocall")
            mainView.directSetMainView()
            navigate("/")
        }}>
            <img src={logo} alt={`logo`}/>
        </div>
    )
}

export default Logo