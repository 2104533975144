import {
    ALL_DATA_COMMON,
    ALL_TICKERS,
    COMMANDS, GRAPH_DATE, GRAPH_MODE, GRAPH_PARAMS,
    IS_CHANGED, IS_LOADER_WINDOW, IS_MODAL_WINDOW, IS_REFRESH_BY_ID,
    LAST_DATA_AUTOCALL, MAIN_VIEW,
    POPUP_CONTENT,
    POPUP_STATUS,
    POPUP_TYPE, REFRESH_FIELDS, REFRESH_MODE, TAB_OPTION,
    TRUNCATE_TICKER,
    VIEW_MODE
} from "./types";
import {makeLog, makeLogService} from "../../utils/logger";

const initialState = {
    lastDataAutocall: "9a5f553a99bb258c61752ab9ed3262cb9a70bc8946a33afee82d1fb7d6b3a2f9", // store last Product data for Autocall (without MarketData and so on)
    lastDataVanilla: "d33181e34cad3395e13b88334b62d675852b14e16b7090e0f1bf1876debd4ea7", // store last Product data for Vanilla (without MarketData and so on)
    lastMDAutocall: "7616d94d8001bf81d3013f9cebc881de86d14daee12791b8edee7b3d4e19a73c", // store last MarketData for Autocall
    lastMDVanilla: "032685949660fc5dcf7e2feecfaeeafc8115c880f1245f07e614419036379096", // store last MarketData for Vanilla
    isChanged: false,
    viewMode: "Autocall",
    viewModes: ["Autocall", "Vanilla", "Call On Basket", "MarketData", "Protected Participation", "Digital Call on Basket", "Digital Call", "Spread Option"],
    mainView: "default",
    mainViews: ["default", "GP", "HVG", "GN", "CORR", "GV", "HS", "GPC", "GPO", "HLVG", "GPUSD", "GPCUSD", "DES", "ZC", "DVD", "RCOR", "OVDV", "IGP", "IGPC", "IHS",  "IHST", "IGPCT", "IGV", "IRST", "OMON", "IGSV", "DASH", "FVT", "HELP", "IRS", "IGVT", "IVS", "IVST", "IGBA", "IGBAT", "CLOSE", "MIVS", "MIVST"],
    mainViewsPermissions: {
      "default": ["full", "demo", "tiny"],
        "GP": ["full", "demo", "tiny"],
        "HVG": ["full", "demo", "tiny"],
        "GN": ["full", "demo", "tiny"],
        "CORR": ["full", "demo", "tiny"],
        "GV": ["full", "demo", "tiny"],
        "HS": ["full", "demo", "tiny"],
        "GPC": ["full", "demo", "tiny"],
        "GPO": ["full", "demo", "tiny"],
        "HLVG": ["full", "demo", "tiny"],
        "GPUSD": ["full", "demo", "tiny"],
        "GPCUSD": ["full", "demo", "tiny"],
        "DES": ["full", "demo", "tiny"],
        "ZC": ["full", "demo", "tiny"],
        "DVD": ["full", "demo", "tiny"],
        "RCOR": ["full", "demo", "tiny"],
        "OVDV": ["full", "demo", "tiny"],
        "IGP": ["full", "demo", "tiny"],
        "IGPC": ["full", "demo", "tiny"],
        "IHS": ["full", "demo", "tiny"],
        "IHST": ["full"],
        "IGPCT": ["full"],
        "IGV": ["full", "demo", "tiny"],
        "IRST": ["full"],
        "OMON": ["full", "demo", "tiny"],
        "IGSV": ["full"],
        "DASH": ["full"],
        "FVT": ["full", "demo", "tiny"],
        "HELP": ["full", "demo", "tiny"],
        "IRS": ["full", "demo", "tiny"],
        "IGVT": ["full"],
        "IGBA": ["full", "demo", "tiny"],
        "IGBAT": ["full", "demo", "tiny"],
        "IVS": ["full", "demo", "tiny"], 
        "IVST": ["full", "demo", "tiny"],
        "CLOSE": ["full", "demo", "tiny"],
        "MIVS": ["full"],
        "MIVST": ["full"],
    },
    command: "",

    truncateTicker: true,
    graphDate: new Date(new Date().setDate(new Date().getDate() - 365)).toISOString().slice(0, 10),

    // Info Popup
    popupStatus: false,
    popupContent: "",
    popupType: false,

    isLoaderWindow: false,
    isModalWindow: {
        status: false,
        ticker: "MTSS"
    },
    graphMode: 0,

    refreshMode: localStorage.getItem("refresh_mode") ?? "DEFAULT",
    refreshModes: [{name: "DEFAULT", roles: ["full", "demo", "tiny"]}, {name: "HEDGING", roles: ["full", "demo"]}, {name: "CUSTOM", roles: ["full", "demo", "tiny"]}],
    refreshFields: {
        "spots": false,
        "vols": false,
        "divs": false,
        "corr_mtx": false,
        "rate": false,
    },

    graphParams: {
        hvg: [15, 90, 365],
        corr: [15, 90, 365],
        rcor: [15, 90, 365],
        gpc: [5, 50],
        gv: [30],
        hlvg: [15, 90, 365],
        gpcusd: [5, 50]
    },

    isRefreshById: false,
    allTickers: [],
    tabOption: 0
}

export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case LAST_DATA_AUTOCALL:
            return {
                ...state,
                lastDataAutocall: action.value
            }
        case IS_CHANGED:
            return {
                ...state,
                isChanged: action.value
            }
        case VIEW_MODE:
            makeLog("VIEW_MODE", state.viewMode, action.value)
            return {
                ...state,
                viewMode: action.value
            }
        case POPUP_STATUS:
            // makeLogService("POPUP_STATUS", state.popupStatus, action.value)
            return {
                ...state,
                popupStatus: action.value
            }
        case POPUP_CONTENT:
            // makeLogService("POPUP_CONTENT", state.popupContent, action.value)
            return {
                ...state,
                popupContent: action.value
            }
        case POPUP_TYPE:
            // makeLogService("POPUP_TYPE", state.popupType, action.value)
            return {
                ...state,
                popupType: action.value
            }
        case TRUNCATE_TICKER:
            return {
                ...state,
                truncateTicker: action.value
            }
        case IS_LOADER_WINDOW:
            makeLogService("IS_LOADER_WINDOW", state.isLoaderWindow, action.value)
            return {
                ...state,
                isLoaderWindow: action.value
            }
        case MAIN_VIEW:
            makeLog("MAIN_VIEW", state.mainView, action.value)
            return {
                ...state,
                mainView: action.value
            }
        case COMMANDS:
            return {
                ...state,
                command: action.value
            }
        case REFRESH_MODE:
            makeLog("REFRESH_MODE", state.refreshMode, action.value)
            return {
                ...state,
                refreshMode: action.value
            }
        case REFRESH_FIELDS:
            return {
                ...state,
                refreshFields: action.value
            }
        case GRAPH_PARAMS:
            makeLog("GRAPH_PARAMS", state.graphParams, action.value)
            return {
                ...state,
                graphParams: action.value
            }
        case GRAPH_DATE:
            makeLog("GRAPH_DATE", state.graphDate, action.value)
            return {
                ...state,
                graphDate: action.value
            }
        case IS_REFRESH_BY_ID:
            makeLog("IS_REFRESH_BY_ID", state.isRefreshById, action.value)
            return {
                ...state,
                isRefreshById: action.value
            }
        case ALL_TICKERS:
            makeLogService("ALL_TICKERS", state.allTickers, action.value)
            return {
                ...state,
                allTickers: action.value
            }
        case TAB_OPTION:
            makeLog("TAB_OPTION", state.tabOption, action.value)
            return {
                ...state,
                tabOption: action.value
            }
        case IS_MODAL_WINDOW:
            makeLogService("IS_MODAL_WINDOW", state.isModalWindow, action.value)
            return {
                ...state,
                isModalWindow: action.value
            }
        case GRAPH_MODE:
            makeLog("GRAPH_MODE", state.graphMode, action.value)
            return {
                ...state,
                graphMode: action.value
            }
        case ALL_DATA_COMMON:
            const currentState = {...state}

            Object.keys(currentState).forEach(key => {
                currentState[key] = key in action.value ? action.value[key] : undefined
            })
            return currentState
        default:
            return state
    }
}
