import './Divs.css'
import {useEffect, useState} from "react";
import Button from "../Button/Button";
import Plot from "react-plotly.js"
import Input from "../Input/Input";
import SelectedOption from "../Selector/utils/SelectedOption/SelectedOption";
import UnselectOption from "../Selector/utils/UnselectOption/UnselectOption";
import {useDispatch, useSelector} from "react-redux";
import {set_divs, set_market_data_id} from "../../redux/MarketData/actions";
import useAllMarketData from "../../hooks/useAllMarketData";
import useRequest from "../../hooks/useRequest";
import {set_is_modal_window} from "../../redux/Common/actions";
import SnapShotBtn from "../../routes/Header/SnapShotBtn/SnapShotBtn";

const Divs = ({ticker, setDivChange}) => {
    const [mode, setMode] = useState("poc")
    const [data, setData] = useState([])
    const divs = useSelector(state => state.MarketDataReducer.divs)
    const spots = useSelector(state => state.MarketDataReducer.spots)
    const dispatch = useDispatch()
    const allData = useAllMarketData()
    const {post} = useRequest()
    const [localChange, setLocalChange] = useState(false)

    useEffect(() => {
        console.log("[ OPT OPEN DIV ]", ticker)
    }, [])

    const getDivs = () => {
        const tmp = []
        const price = spots[`${ticker}`]
        if (typeof divs[`${ticker}`] === "number") {
            tmp[0] = {
                state: false,
                date: new Date().toISOString().slice(0, 10),
                value: divs[`${ticker}`] * price / 100,
                percent: divs[`${ticker}`]
            }
            setData(tmp)
        } else if (divs[`${ticker}`]) {
            console.log(divs[`${ticker}`], "divs")
            divs[`${ticker}`].map((el, i) => {
                tmp[i] = {
                    state: false,
                    date: el.date,
                    value: el.div_amt,
                    percent: el.div_amt * 100 / price
                }
                return undefined
            })
            setData(tmp)
        }
        console.log("[ divs ]", tmp)
    }

    // Адаптер вставки текущего состояния divs
    useEffect(() => {
        getDivs()
    }, [])

    const reset = () => {
        getDivs()
        setLocalChange(false)
    }

    const addRow = () => {
        const price = spots[`${ticker}`]
        const tmp = [...data, {
            state: false,
            date: new Date().toISOString().slice(0, 10),
            value: 0.001,
            percent: 0.001 * 100 / price
        }]
        tmp.sort((a, b) => {
            const dateA = new Date(a.date)
            const dateB = new Date(b.date)
            if (dateA < dateB) {
                return -1
            }
            if (dateA > dateB) {
                return 1
            }
            return 0
        })
        setData(tmp)
    }
    const deleteRow = () => {
        setData(data.filter(el => !el.state))
    }
    const changeState = (st, i) => {
        let tmp = [...data]
        tmp[i].state = st

        // experimental
        tmp = sortData(tmp)
        setData(tmp)
    }
    const changeDate = (val, i) => {
        const tmp = [...data]
        tmp[i].date = val
        setData(tmp)
    }
    const changeValue = (val, i) => {
        const price = spots[`${ticker}`]
        const tmp = [...data]
        tmp[i].value = val
        tmp[i].percent = val * 100 / price
        setData(tmp)
    }

    const changePercent = (val, i) => {
        const price = spots[`${ticker}`]
        const tmp = [...data]
        tmp[i].value = val * price / 100
        tmp[i].percent = val
        setData(tmp)
    }

    const sortData = arr => {
        arr.sort((a, b) => {
            const dateA = new Date(a.date)
            const dateB = new Date(b.date)
            if (dateA < dateB) {
                return -1
            }
            if (dateA > dateB) {
                return 1
            }
            return 0
        })
        return arr
    }

    const save = () => {
        const tmpData = sortData(data)
        const tmp = []
        tmpData.map((el, i) => {
            tmp[i] = {
                date: el.date,
                div_amt: el.value,
                quarter: 1
            }
            return undefined
        })
        dispatch(set_divs({...divs, [ticker]: tmp}))
        const tmpAllData = JSON.parse(JSON.stringify(allData))
        tmpAllData.divs = {...divs, [ticker]: tmp}
        post(
            "/rdict/post",
            tmpAllData,
            undefined,
            (data) => {
                dispatch(set_market_data_id(data))
            }
        )
        setDivChange(true)
        dispatch(set_is_modal_window(false))
        // setActive(false)
    }

    return (
        <div className="div_window-content">
            <div className="div_window-settings">
                <div className="div_window-settings-content">
                    <div className="settings-header">
                        {ticker}
                    </div>
                    <div className="settings-title">
                        Chart Type
                    </div>
                    <div className="settings-mode">
                        <div onClick={() => setMode("abs")}
                             className={`node_button ${mode === "abs" ? "active" : ""}`}>Value
                        </div>
                        <div onClick={() => setMode("poc")}
                             className={`node_button ${mode === "poc" ? "active" : ""}`}>%
                        </div>
                    </div>
                    <div className="settings-buttons">
                        <div className={`edit_nav`}>
                            <Button text={`+ Add Row`} styleClass={`setting-button`}
                                    style={{marginRight: `8px`}} callback={addRow}/>
                            <Button styleClass={"setting-button"} text={`- Delete Row`} callback={deleteRow}/>
                        </div>
                    </div>
                    <div className="settings-table">
                        <div className="settings-table-header">
                            <div className={'settings-state settings-table-header-elem'}></div>

                            <div className={'settings-date settings-table-header-elem'}>Date</div>
                            <div className={'settings-value settings-table-header-elem'}>Value</div>
                            <div className={'settings-name settings-table-header-elem'}>%</div>
                        </div>
                        {data.map((el, i) => (
                            <div key={i} className="settings-table-row">
                                <div className={'settings-state'}>
                                    {el.state === true
                                        ? <SelectedOption setState={() => changeState(false, i)}/>
                                        : <UnselectOption setState={() => changeState(true, i)}/>
                                    }
                                </div>

                                <div className={'settings-date'}>
                                    <Input styleClassName={`settings-date-field`} defaultValue={el.date}
                                           type={'date'} counter={i}
                                           changeCallback={(val) => changeDate(val, i)}/>
                                </div>
                                <div className={'settings-value'}>
                                    <Input styleClassName={`settings-date-field`} defaultValue={el.value.toFixed(3)}
                                           type={'number'} counter={i}
                                           focusOutAction={val => changeValue(Number(val), i)}/>
                                </div>
                                <div className={'settings-percent'}>
                                    <Input styleClassName={`settings-date-field`} defaultValue={el.percent.toFixed(3)}
                                           type={'number'} counter={i}
                                           focusOutAction={val => changePercent(Number(val), i)}/>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
                <div className="div_window-settings-footer">
                    <div onClick={() => save()} className={"div-window-save"}>
                        Save
                    </div>
                    <div onClick={() => reset()} className={"div-window-remove"}>
                        Reset
                    </div>
                    <SnapShotBtn/>
                </div>
            </div>
            <div className="div_window-graph">
                <div className="div-graph-content">
                    <Plot
                        data={
                            [{
                                x: data.map(el => el.date.replaceAll("-", '.')),
                                y: mode === "abs" ? data.map(el => el.value) : data.map(el => el.percent),
                                type: 'bar',
                                marker: {
                                    color: '#656EF2'
                                }
                            }]
                        }
                        layout={{
                            margin: {
                                l: 50,
                                r: 50,
                                t: 20,
                                b: 70
                            },
                            autosize: true,
                            paper_bgcolor: '#000',
                            font: {
                                color: "#fff",
                                size: 10
                            },
                            scene: {
                                xaxis: {
                                    gridcolor: "#fff"
                                },
                                yaxis: {
                                    gridcolor: "#fff"
                                }
                            },
                            xaxis: {
                                gridcolor: "rgba(0,0,0,0.8)",
                                fontSize: 10,
                                size: 10
                            },
                            yaxis: {
                                gridcolor: "rgba(108,116,131,0.8)",
                                title: {
                                    text: mode === "abs" ? "Cash Div" : "%",
                                    standoff: 30,
                                }
                            },
                            plot_bgcolor: "#000"
                        }}
                        config={{
                            responsive: true,
                            displayModeBar: false,
                            staticPlot: true
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Divs
