export const TICKERS_DIGITAL_CALL = 'TICKERS_DIGITAL_CALL'

export const REF_SPOTS_DIGITAL_CALL = 'REF_SPOTS_DIGITAL_CALL'

export const NOTIONAL_DIGITAL_CALL = 'NOTIONAL_DIGITAL_CALL'

export const TRADING_DATE_DIGITAL_CALL = 'TRADING_DATE_DIGITAL_CALL'

export const DATE_EXPIRY_DIGITAL_CALL = 'DATE_EXPIRY_DIGITAL_CALL'

export const AC_STRIKES_DIGITAL_CALL = 'AC_STRIKES_DIGITAL_CALL'

export const CPN_STRIKES_DIGITAL_CALL = 'CPN_STRIKES_DIGITAL_CALL'

export const FIXING_DETAILS_STATE_DIGITAL_CALL = 'FIXING_DETAILS_STATE_DIGITAL_CALL'

export const FIXING_DATES_DIGITAL_CALL = 'FIXING_DATES_DIGITAL_CALL'

export const FIXED_CPN_AMT_DIGITAL_CALL = 'FIXED_CPN_AMT_DIGITAL_CALL'

export const CURRENCY_DIGITAL_CALL = 'CURRENCY_DIGITAL_CALL'

export const IS_WITHOUT_NOTIONAL_DIGITAL_CALL = 'IS_WITHOUT_NOTIONAL_DIGITAL_CALL'

export const TIME_TO_MATURITY_DIGITAL_CALL = 'TIME_TO_MATURITY_DIGITAL_CALL'

export const SCHEDULE_FREQ_DIGITAL_CALL = 'SCHEDULE_FREQ_DIGITAL_CALL'

export const PERIOD_MODE_DIGITAL_CALL = 'PERIOD_MODE_DIGITAL_CALL'

export const CPN_STRIKE_DIGITAL_CALL = 'CPN_STRIKE_DIGITAL_CALL'

export const FIXED_CPN_CNT_DIGITAL_CALL = 'FIXED_CPN_CNT_DIGITAL_CALL'

export const FIXED_CPN_AMT_PA_DIGITAL_CALL = 'FIXED_CPN_AMT_PA_DIGITAL_CALL'

export const CPN_PERFORMANCE_DIGITAL_CALL = 'CPN_PERFORMANCE_DIGITAL_CALL'

export const ALL_DATA_DIGITAL_CALL = 'ALL_DATA_DIGITAL_CALL'

export const GREEKS_DIGITAL_CALL = 'GREEKS_DIGITAL_CALL'