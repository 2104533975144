import axios from "axios";
import useInfoPopup from "./useInfoPopup";
import {useNavigate} from "react-router-dom";

const useRequest = () => {
    const {setPopupStatus} = useInfoPopup()
    const navigate = useNavigate()
    const get = async (url, config, callbackOk, callbackErr) => {
        const uuid = localStorage.getItem("oAuth_pricing_uuid")
        const role = localStorage.getItem("oAuth_pricing")
        const session_id = sessionStorage.getItem("session_id")
        try {
            const before = new Date()
            const response = await axios.get(url, config)
            const after = new Date()
            callbackOk(response.data)
            axios.post("/logger/log", {
                message: `Success request`,
                uuid: uuid ?? "login process",
                role: role ?? "login process",
                level: "INFO",
                description: JSON.stringify(config),
                response: JSON.stringify(response.data),
                url,
                session_id,
                time: (after - before) / 1000
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(data => {
                if (data.data.status_code === 1) {
                    localStorage.removeItem("oAuth_pricing")
                    localStorage.removeItem("oAuth_pricing_uuid")
                    sessionStorage.removeItem("session_id")
                    navigate("/auth")
                }
            })
            return response.data
        } catch (e) {
            if (e.response) {
                if (e.response.data.detail) {
                    axios.post("/logger/log", {
                        message: `Error request`,
                        uuid,
                        role,
                        level: "ERROR",
                        description: JSON.stringify(config),
                        error: e.response.data.detail,
                        url,
                        session_id
                    }, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(data => {
                        if (data.data.status_code === 1) {
                            localStorage.removeItem("oAuth_pricing")
                            localStorage.removeItem("oAuth_pricing_uuid")
                            sessionStorage.removeItem("session_id")
                            navigate("/auth")
                        }
                    })
                    setPopupStatus(true, e.response.data.detail, false)
                } else {
                    axios.post("/logger/log", {
                        message: `Error request`,
                        uuid,
                        role,
                        level: "ERROR",
                        description: JSON.stringify(config),
                        error: e.response.data,
                        url,
                        session_id
                    }, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(data => {
                        if (data.data.status_code === 1) {
                            localStorage.removeItem("oAuth_pricing")
                            localStorage.removeItem("oAuth_pricing_uuid")
                            sessionStorage.removeItem("session_id")
                            navigate("/auth")
                        }
                    })
                    setPopupStatus(true, e.response.data, false)
                }
            } else if (e.request) {
                axios.post("/logger/log", {
                    message: `Error request to ${url}. Message: ${e.request}. User: ${uuid}`,
                    uuid,
                    role,
                    level: "ERROR",
                    description: JSON.stringify(config),
                    error: e.request,
                    url,
                    session_id
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(data => {
                    if (data.data.status_code === 1) {
                        localStorage.removeItem("oAuth_pricing")
                        localStorage.removeItem("oAuth_pricing_uuid")
                        sessionStorage.removeItem("session_id")
                        navigate("/auth")
                    }
                })
                setPopupStatus(true, e.request, false)
            } else {
                axios.post("/logger/log", {
                    message: `Error request to ${url}. Message: ${e.request}. User: ${uuid}`,
                    uuid,
                    role,
                    level: "ERROR",
                    description: JSON.stringify(config),
                    error: e.message,
                    url,
                    session_id
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(data => {
                    if (data.data.status_code === 1) {
                        localStorage.removeItem("oAuth_pricing")
                        localStorage.removeItem("oAuth_pricing_uuid")
                        sessionStorage.removeItem("session_id")
                        navigate("/auth")
                    }
                })
                setPopupStatus(true, e.message, false)
            }
            console.error(e)
            callbackErr(e)
        }

    }

    const post = async (url, data, config, callbackOk, callbackErr) => {
        const uuid = localStorage.getItem("oAuth_pricing_uuid")
        const role = localStorage.getItem("oAuth_pricing")
        const session_id = sessionStorage.getItem("session_id")
        try {
            const before = new Date()
            const response = await axios.post(url, data, config)
            const after = new Date()
            axios.post("/logger/log", {
                message: `Success request.`,
                uuid: uuid ?? "login process",
                role: role ?? "login process",
                level: "INFO",
                description: JSON.stringify(data),
                response: JSON.stringify(response.data),
                url,
                session_id,
                time: (after - before) / 1000
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(data => {
                if (data.data.status_code === 1) {
                    localStorage.removeItem("oAuth_pricing")
                    localStorage.removeItem("oAuth_pricing_uuid")
                    sessionStorage.removeItem("session_id")
                    navigate("/auth")
                }
            })
            callbackOk(response.data)
            return response.data
        } catch (e) {
            if (e.response) {
                if (e.response.data.detail) {
                    axios.post("/logger/log", {
                        message: `Error request`,
                        uuid,
                        role,
                        level: "ERROR",
                        description: JSON.stringify(data),
                        error: e.response.data.detail,
                        url,
                        session_id
                    }, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(data => {
                        if (data.data.status_code === 1) {
                            localStorage.removeItem("oAuth_pricing")
                            localStorage.removeItem("oAuth_pricing_uuid")
                            sessionStorage.removeItem("session_id")
                            navigate("/auth")
                        }
                    })
                    setPopupStatus(true, e.response.data.detail, false)
                } else {
                    axios.post("/logger/log", {
                        message: `Error request`,
                        uuid,
                        role,
                        level: "ERROR",
                        description: JSON.stringify(data),
                        error: e.response.data,
                        url,
                        session_id
                    }, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(data => {
                        if (data.data.status_code === 1) {
                            localStorage.removeItem("oAuth_pricing")
                            localStorage.removeItem("oAuth_pricing_uuid")
                            sessionStorage.removeItem("session_id")
                            navigate("/auth")
                        }
                    })
                    setPopupStatus(true, e.response.data, false)
                }
            } else if (e.request) {
                axios.post("/logger/log", {
                    message: `Error request`,
                    uuid,
                    role,
                    level: "ERROR",
                    description: JSON.stringify(data),
                    error: e.request,
                    url,
                    session_id
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(data => {
                    if (data.data.status_code === 1) {
                        localStorage.removeItem("oAuth_pricing")
                        localStorage.removeItem("oAuth_pricing_uuid")
                        sessionStorage.removeItem("session_id")
                        navigate("/auth")
                    }
                })
                setPopupStatus(true, e.request, false)
            } else {
                axios.post("/logger/log", {
                    message: `Error request`,
                    uuid,
                    role,
                    level: "ERROR",
                    description: JSON.stringify(data),
                    error: e.message,
                    url,
                    session_id
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(data => {
                    if (data.data.status_code === 1) {
                        localStorage.removeItem("oAuth_pricing")
                        localStorage.removeItem("oAuth_pricing_uuid")
                        sessionStorage.removeItem("session_id")
                        navigate("/auth")
                    }
                })
                setPopupStatus(true, e.message, false)
            }
            console.error(e)
            callbackErr(e)
        }
    }

    return {
        get,
        post
    }
}

export default useRequest