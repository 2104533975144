import './MarketDataRate.css'
import InputNumber from "../InputNumber/InputNumber";
import {set_rate} from "../../redux/MarketData/actions";
import {useDispatch, useSelector} from "react-redux";
import useRate from "../../hooks/MarketData/useRate";
import useInfoPopup from "../../hooks/useInfoPopup";
import useRefreshMode from "../../hooks/useRefreshMode";
import useRefreshFields from "../../hooks/useRefreshFields";

const MarketDataRate = () => {
    const rates = useRate()
    const refreshFields = useRefreshFields()
    const refreshMode = useRefreshMode()
    const infoPopup = useInfoPopup()

    const changeRefreshFields = (field, value) => {
        if (refreshMode.refreshMode !== "CUSTOM") {
            infoPopup.setPopupStatus(true, "Switched source of refresh MarketData to CUSTOM", true)
            refreshMode.setRefreshMode("CUSTOM")
        }
        refreshFields.setRefreshFields(field, value)
    }

    if ((localStorage.getItem("oAuth_pricing") !== "full") &&
        localStorage.getItem("oAuth_pricing") !== "demo") {
        return null
    }

    return (
        <div className="market_table-section">
            <div>
                <div className="market_table-section-title">
                    <p>Other</p>
                    <input type="checkbox"
                           checked={refreshFields.refreshFields.rate}
                           onChange={() => changeRefreshFields("rate", !refreshFields.refreshFields.rate)}
                    />
                </div>
                <div className={`market-table-input-block`}>
                    {rates.map((rate, index) => (
                        <div key={index} className={`market-table-input`}>
                            <InputNumber changeCallback={value => rate.onChange(value)}
                                         value={rate.value}
                                         label={`Interest Rate (${rate.name})`}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MarketDataRate