import "./DVDData.css"

const DVDData = ({dvd}) => {
    if (typeof dvd !== "object") return undefined
    return (
        <div className="dvd__container">
            {Object.keys(dvd).map((elem, i) => (
                <div key={i} className="dvd__card">
                    <div className="dvd__label">{elem}</div>
                    <div className="dvd__line headhead">
                        <p className={"dvd__line-date"}>Date</p>
                        <p className={"dvd__line-amt"}>Div Amt</p>
                        <p className={"dvd__line-quarter"}>Quarter</p>
                        <p className={"dvd__line-spot"}>Spot</p>
                    </div>
                    {dvd[elem].map((elem, i) => (
                        <div key={i} className="dvd__line">
                            <p className={"dvd__line-date"}>{elem.date}</p>
                            <p className={"dvd__line-amt"}>{elem.div_amt}</p>
                            <p className={"dvd__line-quarter"}>{elem.quarter}</p>
                            <p className={"dvd__line-spot"}>{elem.spot}</p>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default DVDData;