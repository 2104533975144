import {useProductNotional} from "./useProductNotional";
import {useProductFixings} from "./useProductFixings";
import {useProductCoupon} from "./useProductCoupon";
import {useProductPerformance} from "./useProductPerformance";

export const useProductDetailDigitalCallShort = () => {
    const notional = useProductNotional()
    const fixings = useProductFixings()
    const coupon = useProductCoupon()
    // const performance = useProductPerformance()

    // return [notional, fixings, coupon, performance]
    return [notional, fixings, coupon]
}