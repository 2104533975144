import {useDispatch, useSelector} from "react-redux";
import {
    set_ref_spots_spread_option,
    set_spread_option_order_spread_option,
    set_tickers_spread_option
} from "../redux/SpreadOption/actions";
import {set_divs, set_service_divs, set_service_vols, set_spots, set_vols} from "../redux/MarketData/actions";
import {
    removeTickerFromDivs,
    removeTickerFromRefSpots, removeTickerFromServiceDivs, removeTickerFromServiceVols,
    removeTickerFromTickers,
    removeTickerFromVols
} from "../utils/utilsFunctions";
import {useState} from "react";

export const useTickerDependenciesSpreadOption = () => {
    const dispatch = useDispatch()
    const [isSaved, setIsSaved] = useState(false)
    const [tickers, refSpots] = useSelector((store) => {
        const {spreadOptionReducer} = store
        return [spreadOptionReducer.tickers, spreadOptionReducer.refSpots]
    })

    const [corrMtx, vols, divs, spots, serviceVols, serviceDivs] = useSelector((store) => {
        const {MarketDataReducer} = store
        return [MarketDataReducer.corrMtx, MarketDataReducer.vols, MarketDataReducer.divs, MarketDataReducer.spots,
            MarketDataReducer.serviceVols, MarketDataReducer.serviceDivs]
    })

    const saveTicker = (ticker) => {
        if (tickers.length === 0) {
            dispatch(set_spread_option_order_spread_option([ticker.toUpperCase()]))
            dispatch(set_tickers_spread_option([ticker.toUpperCase()]))
            dispatch(set_ref_spots_spread_option({[ticker.toUpperCase()]: 100}))
            dispatch(set_spots({[ticker.toUpperCase()]: 100}))
            dispatch(set_vols({[ticker.toUpperCase()]: {
                    "amax": 0.9,
                    "amin": 0.9,
                    "curve": 50,
                    "eps_curve": 50,
                    "eps_skew": 50,
                    "long": 33.9,
                    "model": "parametric",
                    "short": 17.5,
                    "skew": -6,
                    "speed": 50,
                    "vol_cap": 10,
                    "vol_floor": 0.1
                }}))
            dispatch(set_divs({[ticker.toUpperCase()]: []}))
            dispatch(set_service_vols({[ticker.toUpperCase()]: '📈'}))
            dispatch(set_service_divs({[ticker.toUpperCase()]: '📈'}))
        } else {
            dispatch(set_spread_option_order_spread_option([tickers[tickers.length - 1], ticker.toUpperCase()]))
            dispatch(set_tickers_spread_option([tickers[tickers.length - 1], ticker.toUpperCase()]))
            dispatch(set_ref_spots_spread_option({
                [tickers[tickers.length - 1]]: 100,
                [ticker.toUpperCase()]: 100
            }))
            dispatch(set_spots({
                [tickers[tickers.length - 1]]: 100,
                [ticker.toUpperCase()]: 100
            }))
            dispatch(set_vols({
                [tickers[tickers.length - 1]]: {
                    "amax": 0.9,
                    "amin": 0.9,
                    "curve": 50,
                    "eps_curve": 50,
                    "eps_skew": 50,
                    "long": 33.9,
                    "model": "parametric",
                    "short": 17.5,
                    "skew": -6,
                    "speed": 50,
                    "vol_cap": 10,
                    "vol_floor": 0.1
                },
                [ticker.toUpperCase()]: {
                    "amax": 0.9,
                    "amin": 0.9,
                    "curve": 50,
                    "eps_curve": 50,
                    "eps_skew": 50,
                    "long": 33.9,
                    "model": "parametric",
                    "short": 17.5,
                    "skew": -6,
                    "speed": 50,
                    "vol_cap": 10,
                    "vol_floor": 0.1
                }
            }))
            dispatch(set_divs({
                [tickers[tickers.length - 1]]: [],
                [ticker.toUpperCase()]: []
            }))
            dispatch(set_service_vols({
                [tickers[tickers.length - 1]]: '📈',
                [ticker.toUpperCase()]: '📈'
            }))
            dispatch(set_service_divs({
                [tickers[tickers.length - 1]]: '📈',
                [ticker.toUpperCase()]: '📈'
            }))
        }
        setIsSaved(true)
    }

    const removeTicker = (ticker) => {
        dispatch(set_tickers_spread_option(removeTickerFromTickers(tickers, ticker.toUpperCase())))
        dispatch(set_ref_spots_spread_option(removeTickerFromRefSpots(refSpots, ticker.toUpperCase())))
        dispatch(set_spots(removeTickerFromRefSpots(spots, ticker.toUpperCase())))
        dispatch(set_vols(removeTickerFromVols(vols, ticker.toUpperCase())))
        dispatch(set_divs(removeTickerFromDivs(divs, ticker.toUpperCase())))
        dispatch(set_service_vols(removeTickerFromServiceVols(serviceVols, ticker.toUpperCase)))
        dispatch(set_service_divs(removeTickerFromServiceDivs(serviceDivs, ticker.toUpperCase)))
    }

    const removeAllTickers = () => {
        dispatch(set_tickers_spread_option([]))
        dispatch(set_ref_spots_spread_option({}))
        dispatch(set_spots({}))
        dispatch(set_vols({}))
        dispatch(set_divs([]))
        dispatch(set_service_vols({}))
        dispatch(set_service_divs({}))
    }

    return {
        tickers,
        saveTicker,
        removeTicker,
        removeAllTickers,
        isSaved,
        setIsSaved
    }
}

