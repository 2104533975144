import {useDispatch, useSelector} from "react-redux";
import {set_corr_floor, set_corr_history, set_corr_len, set_corr_percentile} from "../../redux/MarketData/actions";

const useCorrelationData = () => {
    const dispatch = useDispatch();
    const [corrFloor, corrPercentile, corrLen, corrHistory] = useSelector(state => {
        const {MarketDataReducer} = state;
        const {corrFloor, corrPercentile, corrLen, corrHistory} = MarketDataReducer
        return [corrFloor, corrPercentile, corrLen, corrHistory]
    })

    const setCorrFloor = (value) => {
        dispatch(set_corr_floor(value))
    }

    const setCorrPercentile = (value) => {
        dispatch(set_corr_percentile(value))
    }

    const setCorrLen = (value) => {
        dispatch(set_corr_len(value))
    }

    const setCorrHistory = (value) => {
        dispatch(set_corr_history(value))
    }

    return {
        corrFloor,
        corrPercentile,
        corrLen,
        corrHistory,
        setCorrFloor,
        setCorrPercentile,
        setCorrLen,
        setCorrHistory
    }

}

export default useCorrelationData;