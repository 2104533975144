import {useDispatch, useSelector} from "react-redux";
import {
    set_ref_spots,
    set_tickers,
} from "../redux/Autocall/actions";
import {
    set_corr_mtx,
    set_divs,
    set_service_divs,
    set_service_vols,
    set_spots,
    set_vols
} from "../redux/MarketData/actions";
import {
    insertNewTickerToCorrMtx,
    insertNewTickerToDivs,
    insertNewTickerToRefSpots,
    insertNewTickerToServiceDivs,
    insertNewTickerToServiceVols,
    insertNewTickerToSpots,
    insertNewTickerToTickers,
    insertNewTickerToVols, removeTickerFromCorrMtx, removeTickerFromDivs,
    removeTickerFromRefSpots, removeTickerFromServiceDivs, removeTickerFromServiceVols,
    removeTickerFromSpots,
    removeTickerFromTickers, removeTickerFromVols
} from "../utils/utilsFunctions";
import {useState} from "react";
import useViewMode from "./useViewMode";

const useTickerDependencies = () => {
    const dispatch = useDispatch()
    const viewMode = useViewMode()
    const [isSaved, setIsSaved] = useState(false)
    const [tickers, refSpots] = useSelector((store) => {
        const {autocallReducer} = store
        return [autocallReducer.tickers, autocallReducer.refSpots]
    })
    const [tickersVanilla] = useSelector((store) => {
        const {VanillaReducer} = store
        return [VanillaReducer.tickersVanilla]
    })
    const [corrMtx, vols, divs, spots, serviceVols, serviceDivs] = useSelector((store) => {
        const {MarketDataReducer} = store
        return [MarketDataReducer.corrMtx, MarketDataReducer.vols, MarketDataReducer.divs, MarketDataReducer.spots,
            MarketDataReducer.serviceVols, MarketDataReducer.serviceDivs]
    })

    const [tickersMD] = useSelector((store) => {
        const {MarketDataProductReducer} = store
        return [MarketDataProductReducer.tickersMD]
    })

    const saveTicker = (ticker) => {
        dispatch(set_tickers(insertNewTickerToTickers(tickers, ticker.toUpperCase())))
        dispatch(set_ref_spots(insertNewTickerToRefSpots(refSpots, ticker.toUpperCase())))
        dispatch(set_spots(insertNewTickerToSpots(spots, ticker.toUpperCase())))
        dispatch(set_corr_mtx(insertNewTickerToCorrMtx(corrMtx, ticker.toUpperCase())))
        dispatch(set_vols(insertNewTickerToVols(vols, ticker.toUpperCase())))
        dispatch(set_divs(insertNewTickerToDivs(divs, ticker.toUpperCase())))
        dispatch(set_service_vols(insertNewTickerToServiceVols(serviceVols, ticker.toUpperCase())))
        dispatch(set_service_divs(insertNewTickerToServiceDivs(serviceDivs, ticker.toUpperCase())))
        setIsSaved(true)
    }

    const removeTicker = (ticker) => {
        dispatch(set_tickers(removeTickerFromTickers(tickers, ticker)))
        dispatch(set_ref_spots(removeTickerFromRefSpots(refSpots, ticker)))
        dispatch(set_spots(removeTickerFromSpots(spots, ticker)))
        dispatch(set_corr_mtx(removeTickerFromCorrMtx(corrMtx, ticker)))
        dispatch(set_vols(removeTickerFromVols(vols, ticker)))
        dispatch(set_divs(removeTickerFromDivs(divs, ticker)))
        dispatch(set_service_vols(removeTickerFromServiceVols(serviceVols, ticker)))
        dispatch(set_service_divs(removeTickerFromServiceDivs(serviceDivs, ticker)))
    }

    const removeAllTickers = () => {
        dispatch(set_tickers([]))
        dispatch(set_ref_spots({}))
        dispatch(set_spots({}))
        dispatch(set_corr_mtx({}))
        dispatch(set_vols({}))
        dispatch(set_divs([]))
        dispatch(set_service_vols({}))
        dispatch(set_service_divs({}))
    }

    return {
        tickers: viewMode.viewMode === "Autocall" ?  tickers : viewMode.viewMode === "MarketData" ? tickersMD : tickersVanilla,
        refSpots,
        corrMtx,
        vols,
        divs,
        spots,
        serviceVols,
        serviceDivs,
        saveTicker,
        removeTicker,
        removeAllTickers,
        isSaved,
        setIsSaved
    }
}

export default useTickerDependencies