import './FixingDetailsMobile.css'
import {useState} from "react";
import SimpleMainButton from "../SimpleMainButton/SimpleMainButton";
import useFixingDetails from "../../hooks/useFixingDetails";
import InputDate from "../InputDate/InputDate";
import InputNumber from "../InputNumber/InputNumber";
import GraphFixings from "../GraphFixings/GraphFixings";

const FixingDetailsMobile = () => {
    const [isEditMode, setIsEditMode] = useState(false)
    const data = useFixingDetails()
    return (
        <div className={"fixing_details_mobile"}>
            <div className="fixing_details_mobile-actions">
                <div className="fixing_details_mobile-actions__item">
                    {isEditMode
                        ?
                        <SimpleMainButton text={"Done"} style_={"grey-m"} callback={() => setIsEditMode(!isEditMode)}/>
                        :
                        <SimpleMainButton text={"Edit"} style_={"grey-m"} callback={() => setIsEditMode(!isEditMode)}/>
                    }
                </div>
                {/*<div className="fixing_details_mobile-actions__item">*/}
                {/*    <SimpleMainButton text={"Show Chart"} style_={"grey-m"} callback={() => alert("In develop")}/>*/}
                {/*</div>*/}
            </div>
            <div className="fixing_details_mobile-details__header">
                {isEditMode
                    ? <div
                        className="fixing_details_mobile-details__header-item fixing_details_mobile-details--selector"></div>
                    : null
                }

                <div
                    className="fixing_details_mobile-details__header-item fixing_details_mobile-details--pricing">Pricing
                </div>
                <div className="fixing_details_mobile-details__header-item fixing_details_mobile-details--ac">AC Strike,
                    %
                </div>
                <div className="fixing_details_mobile-details__header-item fixing_details_mobile-details--cpn">Cpn
                    Strike, %
                </div>
            </div>
            <div className="fixing_details_mobile-details__body">
                {data !== undefined
                    ? data.fixingDates.map((elem, i) => (
                        <div className="fixing_details_mobile-details__body-row" key={i}>
                            {isEditMode
                                ? <div
                                    className="fixing_details_mobile-details__body-item fixing_details_mobile-details--selector">
                                    <input type="checkbox" checked={data.fixingDetailsState[i]}
                                           onChange={() => data.setFixingDetailsState(i)}
                                           className="fixing_details_mobile-details--checkbox"/>
                                </div>
                                : null
                            }

                            <div
                                className="fixing_details_mobile-details__body-item fixing_details_mobile-details--pricing">
                                <InputDate value={elem} changeCallback={val => data.setFixingDates(val, i)}/>
                            </div>
                            <div className="fixing_details_mobile-details__body-item fixing_details_mobile-details--ac">
                                <InputNumber value={data.acStrikes[i] * 100}
                                             changeCallback={val => data.setAcStrikes(val, i)}/>
                            </div>
                            <div
                                className="fixing_details_mobile-details__body-item fixing_details_mobile-details--cpn">
                                <InputNumber value={data.cpnStrikes[i] * 100}
                                             changeCallback={val => data.setCpnStrikes(val, i)}/>
                            </div>
                        </div>
                    ))
                    : null
                }
            </div>
            <GraphFixings/>
            {isEditMode
                ? <div className={"fixing_details_mobile-action-btn"}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1.5rem",
                    }} onClick={data.removeFixingDetails}>-
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1.5rem",
                    }} onClick={data.addFixingDetails}>+
                    </div>
                </div>
                : null
            }

        </div>
    )

}

export default FixingDetailsMobile