import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {
    set_corr_mtx,
    set_divs,
    set_service_divs,
    set_service_vols,
    set_spots,
    set_vols
} from "../redux/MarketData/actions";
import {set_ref_spots_digital_call_short, set_tickers_digital_call_short} from "../redux/DigitalCallShort/actions";

export const useTickerDependenciesDigitalCallShort = () => {
    const dispatch = useDispatch()
    const [isSaved, setIsSaved] = useState(false)
    const [tickers] = useSelector((store) => {
        const {digitalCallShortReducer} = store
        return [digitalCallShortReducer.tickers]
    })

    const saveTicker = (ticker) => {
        dispatch(set_tickers_digital_call_short([ticker.toUpperCase()]))
        dispatch(set_ref_spots_digital_call_short( [ticker.toUpperCase()]))
        dispatch(set_spots({[ticker.toUpperCase()]: 100}))
        // dispatch(set_corr_mtx(insertNewTickerToCorrMtx(corrMtx, ticker.toUpperCase())))
        dispatch(set_vols({[ticker.toUpperCase()]: {
                "amax": 0.9,
                "amin": 0.9,
                "curve": 50,
                "eps_curve": 50,
                "eps_skew": 50,
                "long": 33.9,
                "model": "parametric",
                "short": 17.5,
                "skew": -6,
                "speed": 50,
                "vol_cap": 10,
                "vol_floor": 0.1
            }}))
        dispatch(set_divs({[ticker.toUpperCase()]: []}))
        dispatch(set_service_vols({[ticker.toUpperCase()]: '📈'}))
        dispatch(set_service_divs({[ticker.toUpperCase()]: '📈'}))
        setIsSaved(true)
    }

    const removeTicker = () => {
        dispatch(set_tickers_digital_call_short([]))
        dispatch(set_spots({}))
        dispatch(set_vols({}))
        dispatch(set_divs({}))
        dispatch(set_service_vols({}))
        dispatch(set_service_divs({}))
    }

    const removeAllTickers = () => {
        dispatch(set_tickers_digital_call_short([]))
        dispatch(set_ref_spots_digital_call_short({}))
        dispatch(set_spots({}))
        dispatch(set_corr_mtx({}))
        dispatch(set_vols({}))
        dispatch(set_divs([]))
        dispatch(set_service_vols({}))
        dispatch(set_service_divs({}))
    }

    return {
        tickers,
        saveTicker,
        removeTicker,
        removeAllTickers,
        isSaved,
        setIsSaved
    }
}