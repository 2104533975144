import {useDispatch, useSelector} from "react-redux";
import {set_view_mode} from "../redux/Common/actions";

const useViewMode = () => {
    const dispatch = useDispatch();
    const [viewMode, viewModes] = useSelector((state) => {
        const {commonReducer} = state
        return [commonReducer.viewMode, commonReducer.viewModes]
    })

    const setViewMode = (value) => {
        dispatch(set_view_mode(value))
    }


    return {
        viewMode,
        setViewMode,
        viewModes
    }
}

export default useViewMode;