import './ProductDetailView.css'
import ProductDetailLine from "../ProductDetailLine/ProductDetailLine";
import useProductDetails from "../../hooks/AutocallProductData/useProductDetails";

const ProductDetailView = () => {
    const data = useProductDetails()
    return (
        <div className={"product_detail_view"}>
            {data.map((item, index) => {
                return <ProductDetailLine key={index} dataset={item}/>
            })}
        </div>
    )
}

export default ProductDetailView