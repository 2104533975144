import {useSelector} from "react-redux";

const useAutocallSnapshot = () => {
    return useSelector((store) => {
        const {autocallReducer, MarketDataReducer, commonReducer} = store
        return {
            product: autocallReducer,
            marketData: MarketDataReducer,
            common: commonReducer
        }
    })
}

export default useAutocallSnapshot;