import {useDispatch, useSelector} from "react-redux";
import {set_refresh_fields} from "../redux/Common/actions";

const useRefreshFields = () => {
    const dispatch = useDispatch()
    const [refreshFields] = useSelector(store => {
        const {commonReducer} = store
        return [commonReducer.refreshFields]
    })

    const setRefreshFields = (field, value) => {
        const tmp = {...refreshFields, [field]: value}
        dispatch(set_refresh_fields(tmp))
    }

    const resetAllFields = () => {
        const tmp = {}
        for (let key in refreshFields) {
            tmp[key] = false
        }
        dispatch(set_refresh_fields(tmp))
    }

    return {
        refreshFields,
        setRefreshFields,
        resetAllFields
    }
}

export default useRefreshFields