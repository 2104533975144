import {useSelector} from "react-redux";
import axios from "axios";
import useProductDataRefresh from "./useProductDataRefresh";
import {useState} from "react";
import useMarketDataRefresh from "./useMarketDataRefresh";
import useViewMode from "./useViewMode";
import useRefreshMarketData from "./useRefreshMarketData";


/**
 * Hook for initialize store by data from localStorage (or from redux store if localStorage is empty)
 * @returns {{init: boolean, update: function}} - init - is store initialized, update - function for update store
 */
const useInitialStates = () => {
    const viewMode = useViewMode()
    const refreshManager = useRefreshMarketData()
    const saveProduct = useProductDataRefresh()
    const saveMarketData = useMarketDataRefresh()
    const [init, setInit] = useState(false)
    const [lastDataAutocall, lastDataVanilla, lastMDAutocall, lastMDVanilla] = useSelector((store) => {
        const {commonReducer} = store
        return [commonReducer.lastDataAutocall, commonReducer.lastDataVanilla, commonReducer.lastMDAutocall, commonReducer.lastMDVanilla]
    })


    /**
     * Function for update store (from localStorage or from redux store)
     */
    const updateFunc = () => {
        let data = localStorage.getItem('initialData')

        console.log("[ useInitialStates ]", lastDataAutocall, lastDataVanilla, lastMDAutocall, lastMDVanilla)
        if (!data) {
            data = {
                lastDataAutocall: lastDataAutocall,
                lastDataVanilla: lastDataVanilla,
                lastMDAutocall: lastMDAutocall,
                lastMDVanilla: lastMDVanilla
            }
            localStorage.setItem('initialData', JSON.stringify(data))
        } else {
            data = JSON.parse(data)
        }
        console.log("[ data ILS ]", data)
        if (!data.lastDataAutocall || !data.lastMDAutocall || !data.lastDataVanilla || !data.lastMDVanilla) {
            data = {
                lastDataAutocall: lastDataAutocall,
                lastDataVanilla: lastDataVanilla,
                lastMDAutocall: lastMDAutocall,
                lastMDVanilla: lastMDVanilla
            }
        }
        console.log("[ data after ILS ]", data)
        console.log("[ updateFunc ]", data)
        if (viewMode.viewMode === "Autocall") {
            Promise.all([
                axios(`/rdict/get/${data.lastDataAutocall}`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                }),
                axios(`/rdict/get/${data.lastMDAutocall}`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                })
            ])
                .then(receivedV => {
                    console.log("[ receivedV ]", receivedV)
                    saveProduct.setAllParamsAutocall(receivedV[0].data)
                    saveMarketData.saveMarketData(receivedV[1].data, receivedV[0].data)
                    setInit(true)
                    return [receivedV[0].data, receivedV[1].data]
                })
        } else if (viewMode.viewMode === "Vanilla") {
            Promise.all([
                axios(`/rdict/get/${data.lastDataVanilla}`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                }),
                axios(`/rdict/get/${data.lastMDVanilla}`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                })
            ])
                .then(receivedV => {
                    console.log("[ receivedV ]", receivedV)
                    saveProduct.setAllParamsVanilla(receivedV[0].data)
                    // saveMarketData.saveMarketData(receivedV[1].data, receivedV[0].data)
                    setInit(true)
                    console.log('setter')
                })
        }

    }




    return {
        update: updateFunc,
        init
    }

}

export default useInitialStates;