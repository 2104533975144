import useMainView from "../../hooks/useMainView";
import ProductSelector from "../../routes/Main/ProductSelector/ProductSelector";
import settings from "../../static/settings.svg";
import open from "../../static/open.png";
import ProductNav from "../../routes/Main/ProductNav/ProductNav";
import ViewManager from "../ViewManager/ViewManager";
import useViewMode from "../../hooks/useViewMode";
import {useEffect, useState} from "react";
import Settings from "../Settings/Settings";
import ModalWindow from "../ModalWindow/ModalWindow";
import Footer from "../../routes/Footer/Footer";
import PricingHistory from "../../routes/PricingHistory/PricingHistory";
import ChartManager from "./ChartManager";
import ErrorBoundary from "../ErrorHandler";
import HistoryMini from "../HistoryMini/HistoryMini";
import {useDispatch, useSelector} from "react-redux";
import {set_tab_option} from "../../redux/Common/actions";
import Container from "../Container/Container"

const MainViewManager = () => {
    const mainView = useMainView()
    const dispatch = useDispatch()
    const viewManager = useViewMode()
    const products = viewManager.viewModes
    const [mode, setMode] = useState(0)
    const [isSettingsOpen, setIsSettingsOpen] = useState(false)
    const [isHistoryOpen, setIsHistoryOpen] = useState(false)
    const tab_option = useSelector(state => state.commonReducer.tabOption)

    useEffect(() => {
        setMode(tab_option)
        console.log(1)
    }, [tab_option])

    useEffect(() => {
        dispatch(set_tab_option(mode))
        console.log(2)
    }, [mode])


    if (mainView.mainView === 'default') {
        return (
            <Container>
                <div className={`main-container`}>
                    <div className={'main-content'}>
                        <div className="main-content-selection">
                            {/*TODO в этом блоке контент надо заменить*/}
                            <div className={"product-selector-block"}>
                                <ModalWindow
                                    content={<Settings/>}
                                    isActive={isSettingsOpen}
                                    setIsActive={setIsSettingsOpen}
                                    smallSize
                                />
                                <ModalWindow
                                    content={<PricingHistory isActive={isHistoryOpen}
                                                             setIsActive={(st) => setIsHistoryOpen(st)}/>}
                                    isActive={isHistoryOpen}
                                    setIsActive={(st) => setIsHistoryOpen(st)}
                                />
                                <ProductSelector
                                    products={products}
                                    productSelected={viewManager.viewMode}
                                    setProductSelected={value => {
                                        viewManager.setViewMode(value)
                                        setMode(0)
                                    }}
                                />
                                <div className={"settings_desktop"} onClick={() => setIsSettingsOpen(true)}>
                                    <img className={"settings_img"} src={settings} alt={`settings icon`}/>
                                    <p className={"setting_word"}>Settings</p>
                                </div>
                            </div>
                            <ProductNav mode={mode} setMode={setMode}/>
                            {/*<ErrorBoundary mode={mode} setMode={setMode}>*/}
                                <ViewManager mode={mode}/>
                            {/*</ErrorBoundary>*/}
                        </div>
                        <Footer/>
                    </div>
                    <div className={"mini-history"}>
                        <HistoryMini close={setIsHistoryOpen}/>
                    </div>
                    <div className={"button-pricing-history"}
                         onClick={() => setIsHistoryOpen(true)}>
                        <img src={open} alt=""/>
                    </div>
                </div>
            </Container>
        )
    } else if (mainView.mainViews.includes(mainView.mainView.toUpperCase())) {
        return (
            <ChartManager/>
        )
    }
}

export default MainViewManager