import {
    ALL_DATA_CALL_ON_BASKET,
    CALL_UP_LIMIT_CALL_ON_BASKET,
    CURRENCY_CALL_ON_BASKET,
    FIXING_DATE_CALL_ON_BASKET,
    FIXING_DATES_CALL_ON_BASKET,
    GREEKS_CALL_ON_BASKET,
    IS_WITHOUT_NOTIONAL_CALL_ON_BASKET,
    MODEL_CALL_ON_BASKET, N_SIMS_CALL_ON_BASKET,
    NOTIONAL_CALL_ON_BASKET,
    PERIOD_MODE_CALL_ON_BASKET,
    REF_SPOTS_CALL_ON_BASKET,
    TICKERS_CALL_ON_BASKET, TRADING_DATE_CALL_ON_BASKET
} from "./types";

export const set_tickers_basket = (value) => {
    return {
        type: TICKERS_CALL_ON_BASKET,
        value
    }
}

export const set_is_without_notional_basket = (value) => {
    return {
        type: IS_WITHOUT_NOTIONAL_CALL_ON_BASKET,
        value
    }
}

export const set_currency_basket = (value) => {
    return {
        type: CURRENCY_CALL_ON_BASKET,
        value
    }
}

export const set_fixing_dates_basket = (value) => {
    return {
        type: FIXING_DATES_CALL_ON_BASKET,
        value
    }
}

export const set_fixing_date_basket = (value) => {
    return {
        type: FIXING_DATE_CALL_ON_BASKET,
        value
    }
}

export const set_ref_spots_basket = (value) => {
    return {
        type: REF_SPOTS_CALL_ON_BASKET,
        value
    }
}

export const set_call_up_limit_basket = (value) => {
    return {
        type: CALL_UP_LIMIT_CALL_ON_BASKET,
        value
    }
}

export const set_greeks_basket = (value) => {
    return {
        type: GREEKS_CALL_ON_BASKET,
        value
    }
}

export const set_notional_basket = (value) => {
    return {
        type: NOTIONAL_CALL_ON_BASKET,
        value
    }
}

export const set_period_mode_basket = (value) => {
    return {
        type: PERIOD_MODE_CALL_ON_BASKET,
        value
    }
}

export const set_model_basket = value => {
    return {
        type: MODEL_CALL_ON_BASKET,
        value
    }
}

export const set_n_sims_basket = value => {
    return {
        type: N_SIMS_CALL_ON_BASKET,
        value
    }
}

export const set_trading_date_basket = value => {
    return {
        type: TRADING_DATE_CALL_ON_BASKET,
        value
    }
}

export const set_all_data_basket = value => {
    return {
        type: ALL_DATA_CALL_ON_BASKET,
        value: value.product
    }
}
