import {useDispatch, useSelector} from "react-redux";
import {set_graph_params} from "../redux/Common/actions";

const useGraphParams = () => {
    const dispatch = useDispatch();
    const [graphParams] = useSelector((state) => {
        const {commonReducer} = state;
        return [commonReducer.graphParams];
    })


    const setGraphParams = (field, value, pos) => {
        const tmp = {...graphParams};
        tmp[field][pos] = value;
        dispatch(set_graph_params(tmp));
    }

    // const setGraphParamsRow = (field, row) => {
    //     const tmp = {...graphParams};
    //     tmp[field] = row;
    //     dispatch(set_graph_params(tmp));
    // }

    return {
        graphParams,
        setGraphParams
    }
}

export default useGraphParams;