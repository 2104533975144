import './InputDate.css'
import {useEffect, useState} from "react";

const InputDate = ({value, changeCallback, title, withIcon, withTime}) => {
    const [localValue, setLocalValue] = useState(value)

    useEffect(() => {
        setLocalValue(value)
    }, [value])

    const save = () => {
        let saveValue = value
        console.log(localValue, typeof localValue)
        // if (isNaN(localValue)) {
        //     console.log("first if")
        //     saveValue = value
        // } else
        if (localValue !== "") {
            console.log("second if")
            saveValue = localValue
        }
        console.log(saveValue, typeof saveValue)
        setLocalValue(saveValue)
        changeCallback(saveValue)
    }

    const blurAction = () => {
        save()
    }

    const KeyDownAction = (e) => {
        if (e.key === "Enter") {
            // save()
            e.target.blur()
        }
    }

    return (
        <>
            <label className={"input_date"}>
                {title}
                <input className={`${withIcon ? "input_date-input-with-icon": "input_date-input"} ${withTime ? "input_date-input-with-time" : ""}`}
                       type={withTime ? "datetime-local" : "date"}
                       value={localValue}
                       onChange={(e) => setLocalValue(e.target.value)}
                       onBlur={() => blurAction()} onKeyDown={(e) => KeyDownAction(e)}
                />
            </label>
        </>
    )
}

export default InputDate