import {useDispatch, useSelector} from "react-redux";
import {set_tickers_basket} from "../redux/CallOnBasket/action";
import {
    insertNewTickerToDivs,
    insertNewTickerToServiceDivs,
    insertNewTickerToServiceVols,
    insertNewTickerToSpots,
    insertNewTickerToTickers,
    insertNewTickerToVols,
    removeTickerFromDivs, removeTickerFromServiceDivs,
    removeTickerFromServiceVols,
    removeTickerFromSpots,
    removeTickerFromTickers,
    removeTickerFromVols
} from "../utils/utilsFunctions";
import {set_divs, set_service_divs, set_service_vols, set_spots, set_vols} from "../redux/MarketData/actions";
import {useState} from "react";

const useTickerDependenciesCallOnBasket = () => {
    const dispatch = useDispatch()
    const [isSaved, setIsSaved] = useState(false)
    const [tickers] = useSelector((store) => {
        const {BasketReducer} = store
        return [BasketReducer.tickersBasket]
    })

    const [spots, vols, divs, serviceVols, serviceDivs] = useSelector((store) => {
        const {MarketDataReducer} = store
        return [MarketDataReducer.spots, MarketDataReducer.vols, MarketDataReducer.divs,
            MarketDataReducer.serviceVols, MarketDataReducer.serviceDivs]
    })

    const saveTicker = (ticker) => {
        dispatch(set_tickers_basket(insertNewTickerToTickers(tickers, ticker.toUpperCase())))
        dispatch(set_spots(insertNewTickerToSpots(spots, ticker.toUpperCase())))
        dispatch(set_vols(insertNewTickerToVols(vols, ticker.toUpperCase())))
        dispatch(set_divs(insertNewTickerToDivs(divs, ticker.toUpperCase())))
        dispatch(set_service_vols(insertNewTickerToServiceVols(serviceVols, ticker.toUpperCase())))
        dispatch(set_service_divs(insertNewTickerToServiceDivs(serviceDivs, ticker.toUpperCase())))
        setIsSaved(true)
    }

    const removeTicker = (ticker) => {
        dispatch(set_tickers_basket(removeTickerFromTickers(tickers, ticker.toUpperCase())))
        dispatch(set_spots(removeTickerFromSpots(spots, ticker)))
        dispatch(set_vols(removeTickerFromVols(vols, ticker)))
        dispatch(set_divs(removeTickerFromDivs(divs, ticker)))
        dispatch(set_service_vols(removeTickerFromServiceVols(serviceVols, ticker)))
        dispatch(set_service_divs(removeTickerFromServiceDivs(serviceDivs, ticker)))
    }

    const removeAllTickers = () => {
        dispatch(set_tickers_basket([]))
        dispatch(set_spots({}))
        dispatch(set_vols({}))
        dispatch(set_divs({}))
        dispatch(set_service_vols({}))
        dispatch(set_service_divs({}))
    }

    return {
        tickers,
        saveTicker,
        removeTicker,
        removeAllTickers,
        isSaved,
        setIsSaved
    }
}

export default useTickerDependenciesCallOnBasket