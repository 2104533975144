import {useDispatch, useSelector} from "react-redux";
import {set_corr_mtx, set_market_data_id} from "../../redux/MarketData/actions";
import useAllMarketData from "../useAllMarketData";
import useRequest from "../useRequest";

const useCorrMtx = () => {
    const dispatch = useDispatch()
    const allData = useAllMarketData()
    const {post} = useRequest()
    const corrMtx = useSelector(state => {
        const {MarketDataReducer} = state;
        return MarketDataReducer.corrMtx
    })

    const changeCorrMtx = (row, col, value) => {
        if (value > 1) value = 1
        const tmp = corrMtx
        tmp[row][col] = value
        tmp[col][row] = value
        const tmpAllData = JSON.parse(JSON.stringify(allData))
        tmpAllData.corr_mtx = tmp
        post("/rdict/post",
            tmpAllData,
            undefined,
            (data) => {dispatch(set_market_data_id(data))})
        dispatch(set_corr_mtx(tmp))
    }

    return {
        corrMtx,
        changeCorrMtx
    }
}

export default useCorrMtx