import image from './../../../../static/unselect_option.svg'
import './UnselectOption.css'

const UnselectOption = ({setState}) => {
    return (
        <div className={`unselected_option`} onClick={() => {setState(true)}}>
            <img src={image} alt={`unselected option`}/>
        </div>
    )
}
export default UnselectOption