import useNotionalValue from "./useNotionalValue";
import useDetailsVanilla from "./useDetailsVanilla";
import useFixingsVanilla from "./useFixingsVanilla";
import useCapVanilla from "./useCapVanilla";

const useProductDetailVanilla = () => {
    const product = useNotionalValue()
    const fixings = useFixingsVanilla()
    const details = useDetailsVanilla()
    const cap = useCapVanilla()

    return [product, fixings, details, cap]
}

export default useProductDetailVanilla;