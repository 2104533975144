import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const Logout = () => {
    const navigate = useNavigate()
    useEffect(() => {
        localStorage.removeItem("oAuth_pricing")
        localStorage.removeItem("oAuth_pricing_uuid")
        sessionStorage.removeItem("session_id")
        navigate("/")
        console.log("logout")
    })
}

export default Logout