import {useDispatch, useSelector} from "react-redux";
import {set_leverage_rage_participation} from "../../redux/Participation/actions";

const useLeverageParticipation = () => {
    const dispatch = useDispatch()
    const [leverageRate] = useSelector(store => {
        const {ParticipationReducer} = store
        return [ParticipationReducer.leverage_rate]
    })

    const setLeverageRate = (val) => {
        const tmp = Number((val / 100).toFixed(2))
        dispatch(set_leverage_rage_participation(tmp))
    }

    return {
        title: "Participation",
        data: [
            {
                name: "Rate, %",
                value: leverageRate * 100,
                onChange: setLeverageRate,
                type: {
                    name: "number"
                },
                extra: "small"
            }
        ]
    }
}

export default useLeverageParticipation