import './Graph.css'
import Plot from "react-plotly.js";



const Graph = ({data, customLayout}) => {
    const layout = {
        margin: {
            l: 50,
            r: 20,
            t: 20,
            b: 20
        },
        hovermode: 'x unified',
        plot_bgcolor: "#ffffff",
        // autosize: true,
        paper_bgcolor: '#ffffff',
        font: {
            color: "#000000"
        },
        scene: {
            xaxis: {
                gridcolor: "#000000"
            },
            yaxis: {
                gridcolor: "#000000"
            },
            zaxis: {
                gridcolor: "#000000"
            }
        },
        xaxis: {
            // dtick: 5,
            gridcolor: "rgba(108,116,131,0.8)"
        },
        yaxis: {
            gridcolor: "rgba(108,116,131,0.8)"
        },
        showlegend: true,
        legend: {
            orientation: "h",
            x: 0,
            y: 1.01,
            yanchor: "bottom",
        }//!isMobile
    }


    return (
        <Plot data={data}
              layout={customLayout ?? layout}
              config={{
                  responsive: true,
                  displayModeBar: false,
                  staticPlot: false
              }}/>
    )
}

export default Graph