import './InputText.css'
import {useEffect, useState} from "react";

const InputText = ({value, changeCallback, title}) => {
    const [localValue, setLocalValue] = useState(value)

    useEffect(() => {
        setLocalValue(value)
    }, [value])

    const save = () => {
        let saveValue = value
        if (localValue !== "") {
            saveValue = localValue
        }
        setLocalValue(saveValue)
        changeCallback(saveValue)
    }

    const blurAction = () => {
        save()
    }

    const KeyDownAction = (e) => {
        if (e.key === "Enter") {
            save()
            e.target.blur()
        }
    }

    return (
        <>
            <label className={"input_text"}>
                {title}
                <input className={`input_text-input ${title ? "input_text-input--with_label" : null}`}
                       type="text"
                       value={localValue}
                       onChange={(e) => setLocalValue(e.target.value)}
                       onBlur={() => blurAction()}
                       onKeyDown={(e) => KeyDownAction(e)}
                />
            </label>
        </>
    )
}

export default InputText