import useViewMode from "./useViewMode";
import useProductAutocall from "./pricingParts/useProductAutocall";
import useProductVanilla from "./pricingParts/useProductVanilla";
import useMarketData from "./pricingParts/useMarketData";
import axios from "axios";

const useLocalStorage = () => {
    const mode = useViewMode()
    const productAutocall = useProductAutocall()
    const productVanilla = useProductVanilla()
    const marketData = useMarketData()

    const setLocalStoreLastData = () => {
        console.log("setLocalStoreLastData")
        let data = localStorage.getItem('initialData')
        console.log("[ data LS ]", data)
        if (data) {
            data = JSON.parse(data)
        } else {
            data = {}
        }
        console.log("[ data after parse ]", data)
        if (mode.viewMode === "Autocall") {
            Promise.all([
                axios.post(`/rdict/post`, productAutocall, {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                }),
                axios.post(`/rdict/post`, marketData, {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                })
            ])
                .then(receivedV => {
                    data['lastDataAutocall'] = receivedV[0].data
                    data['lastMDAutocall'] = receivedV[1].data
                    console.log("[ data ]", data)
                    localStorage.setItem('initialData', JSON.stringify(data))
                })
        }
        else if (mode.viewMode === "Vanilla") {
            Promise.all([
                axios.post(`/rdict/post`, productVanilla, {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                }),
                axios.post(`/rdict/post`, marketData, {
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    }
                })
            ])
                .then(receivedV => {
                    data['lastDataVanilla'] = receivedV[0].data
                    data['lastMDVanilla'] = receivedV[1].data
                    console.log("[ data ]", data)
                    localStorage.setItem('initialData', JSON.stringify(data))
                })
        }
    }

    return {
        setLocalStoreLastData
    }
}

export default useLocalStorage