import {getFavorites} from "../../utils/localStorageHandler";
import {useEffect, useState} from "react";
import {MultipleGraph} from "./MultipleGraph";
import './MultipleGraph.css'

export const ManagerMultiple = ({command, params}) => {
    const favorites = getFavorites()
    const access = ["IGP", "IGPC", "IHS", "IHST", "IGPCT", "OMON", "DASH"]
    const [validData, setValidData] = useState([])
    const [resData, setResData] = useState([])
    let date = new Date()
    date.setHours(7, 0, 0, 0)
    let utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
    const links = {
        "IGV": {
            url: "/chart/option_candles",
            isGraph: true,
            params: (tickers) => {
                return {
                    instrument: tickers[0],
                    start_time: utcDate,
                    end_time: new Date(new Date() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 16),
                }
            }
        },
        "IGSV": {
            url: "/chart/put_and_call_option",
            isGraph: true,
            params: (tickers) => {
                return {
                    instrument: tickers[0],
                    start_time: utcDate,
                    end_time: new Date(new Date() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 16),
                }
            }
        },
        "IRST": {
            url: "/chart/futures_implied_rate_and_trades",
            isGraph: true,
            params: (tickers) => {
                return {
                    instrument: tickers[0],
                    start_time: utcDate,
                    end_time: new Date(new Date() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 16),
                }
            }
        },
        "IHS": {
            url: "/chart/spread",
            isGraph: true,
            params: (tickers) => {
                return {
                    l_asset: tickers[0],
                    r_asset: tickers[1],
                    start_time: utcDate,
                    end_time: new Date(new Date() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 16),
                    l_lot_size: 1,
                    r_lot_size: 1
                }
            }
        },
        "IVS": {         
            url: "/chart/volatility_smile",
            isGraph: true,
            params: tickers => {
                return {
                    instrument: tickers[0],
                    time: tickers.length > 2 ? tickers[1] : 1,
                    trades: false,
                }
            }
        },
        "IVST": {
            url: "/chart/volatility_smile",
            isGraph: true,
            params: tickers => {
                return {
                    instrument: tickers[0],
                    time: tickers.length > 2 ? tickers[1] : 1,
                    trades: true,
                }
            }
        },
    }

    console.log('TEST MULTIPLE')
    useEffect(() => {
        console.log("TEST FVT favorites all", Object.keys(favorites))
        const t = []
        var commands = {};
        if (command === 'MIVS' || command === 'MIVST') {
            const with_trades = command === 'MIVS'? 'IVS' : 'IVST'
            const input = [
                `${params[0]} 1 ${with_trades}`,
                `${params[0]} 2 ${with_trades}`,
                `${params[0]} 3 ${with_trades}`, 
                `${params[0]} 4 ${with_trades}`,
            ]
            console.log(input)
            input.forEach((key) => {
                commands[key] = 0;
            })
        } else {
            commands = favorites;
        }
        console.log("TEST commands", Object.keys(commands), "command", command)
        Object.keys(commands).forEach((elem) => {
            const tmp = elem.split(" ")
            if (Object.keys(links).includes(tmp[tmp.length - 1])) {
                t.push(elem)
            }
            setValidData(t)
        })
    }, [])

    useEffect(() => {
        const tmp = []
        validData.forEach((elem) => {
            const v = elem.split(" ")
            const instrument = v[v.length - 1]
            const data = {
                name: elem,
                args: {
                    url: links[instrument].url,
                    data: links[instrument].params(v)
                }
            }
            tmp.push(<MultipleGraph tmp={data}/>)
        })
        setResData(tmp)
    }, [validData])

    return (
        <div className={"multigraph-area"}>
            {resData.map((elem, i) => (
                <div className={"multigraph-area__chart"} key={i}>
                    {elem}
                </div>
            ))}
        </div>

    )
}