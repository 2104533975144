import './TradeDetails.css'
import {useState} from "react";
import Input from "../../../components/Input/Input";
import useTradeDetails from "../../../hooks/useTradeDetails";
import InputNumber from "../../../components/InputNumber/InputNumber";

const TradeDetails = () => {
    const tradeDetails = useTradeDetails()
    const values = ["Booking", "Buying", "Holding"]
    const [selected, setSelected] = useState(values[0])
    const [client, setClient] = useState("")
    const [isin, setIsin] = useState("")
    const [notional, setNotional] = useState("")
    return (
        <div className={`trade-details`}>
            {/*<Select label={`Portfolio`} values={values} selected={selected} setSelected={setSelected} />*/}
            <Input label={`Portfolio`} allValues={values} type={`select`} changeCallback={setSelected}
                   defaultValue={selected} styleClassName={`trade-detail-select`}/>
            <Input type={`text`} defaultValue={client} placeholder={`Name (Optional)`} changeCallback={setClient}
                   label={`Client`} styleClassName={`trade-detail-input`}/>
            <Input type={`text`} defaultValue={isin} placeholder={`Optional`} changeCallback={setIsin} label={`ISIN`}
                   styleClassName={`trade-detail-input`}/>
            {/*<Input type={`text`} defaultValue={notional} placeholder={`Optional`} changeCallback={setNotional}*/}
            {/*       label={`Notional`} styleClassName={`trade-detail-input`}/>*/}
            <InputNumber label={`Notional`} value={tradeDetails.notional} changeCallback={tradeDetails.setNotional}/>
        </div>
    )
}

export default TradeDetails