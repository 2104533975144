import useViewMode from "./useViewMode";
import {useDispatch, useSelector} from "react-redux";
import {set_event_autocall} from "../redux/Autocall/actions";

const useEvents = () => {
    const {viewMode} = useViewMode()
    const dispatch = useDispatch()
    const events = useSelector(state => {
        const {autocallReducer} = state
        if (viewMode === "Autocall") {
            return autocallReducer.events
        } else {
            return {}
        }
    })

    const setEvents = (event) => {
        console.log("setEvents", viewMode, event)
        if (viewMode === "Autocall") {
            console.log("setEvents", event)
            dispatch(set_event_autocall(event))
        }
    }

    return {
        events,
        setEvents
    }
}

export default useEvents;