import {useDispatch, useSelector} from "react-redux";
import {
    set_fixing_date_participation,
    set_fixing_dates_participation,
    set_period_mode_participation
} from "../../redux/Participation/actions";

const useExpiryParticipation = () => {
    const dispatch = useDispatch();
    const [tradingDate, fixingDate, fixingDates, periodMode] = useSelector((state) => {
        const {ParticipationReducer} = state;
        return [ParticipationReducer.tradingDate, ParticipationReducer.fixingDate, ParticipationReducer.fixingDates, ParticipationReducer.periodMode]
    })

    const setFixingDates = (value) => {
        if (periodMode === "years") {
            value = Math.round(value * 365)
        }
        const tmp = new Date(tradingDate)
        tmp.setDate(tmp.getDate() + value)
        dispatch(set_fixing_date_participation([tmp.toISOString().slice(0, 10)]))
        dispatch(set_fixing_dates_participation(value))
    }

    const setFixingDate = (value) => {
        const tmpDate = new Date(value)
        const tmp = new Date(tradingDate)
        dispatch(set_fixing_date_participation([value]))
        dispatch(set_fixing_dates_participation((tmpDate - tmp) / (1000 * 60 * 60 * 24)))
    }

    const setPeriodMode = (value) => {
        dispatch(set_period_mode_participation(value))
    }

    return {
        title: "Expiry",
        data: [
            {
                name: `Term, ${periodMode}`,
                value: periodMode === "days" ? fixingDates : (fixingDates / 365).toFixed(2),
                onChange: setFixingDates,
                type: {
                    name: "number"
                }
            },
            {
                name: "Term, prd",
                value: periodMode,
                onChange: setPeriodMode,
                type: {
                    name: "selector",
                    options: ["days", "years"]
                }
            },
            {
                name: "Expiry Date",
                value: fixingDate[0],
                onChange: setFixingDate,
                type: {
                    name: "date",
                    picker: true
                }
            }
        ]
    }
}

export default useExpiryParticipation