// PRICING_REQUEST
export const IS_WITHOUT_NOTIONAL = 'IS_WITHOUT_NOTIONAL';
export const AC_STEPDOWN = 'AC_STEPDOWN';
export const TIME_TO_MATURITY = 'TIME_TO_MATURITY';
export const SCHEDULE_FREQ = 'SCHEDULE_FREQ';
export const AC_STRIKE = 'AC_STRIKE';
export const NO_AC_PERIOD = 'NO_AC_PERIOD';
export const CPN_STRIKE = 'CPN_STRIKE';
export const FIXED_CPN_CNT = 'FIXED_CPN_CNT';
export const FIXED_CPN_AMT = 'FIXED_CPN_AMT';
export const CPN_AMT_PA = 'CPN_AMT_PA';
export const PAYOFF = 'PAYOFF';
export const STRIKE = 'STRIKE';
export const BARRIER = 'BARRIER';
export const FIXING_DETAILS_STATE = 'FIXING_DETAILS_STATE';
// export const VOLS = 'VOLS';

export const TRADING_DATE = 'TRADING_DATE';

export const TICKERS = 'TICKERS'
export const REFRESH_RESULTS = 'REFRESH_RESULTS'


export const FIXING_DATES = 'FIXING_DATES';
export const AC_STRIKES = 'AC_STRIKES';
export const CPN_STRIKES = 'CPN_STRIKES';


export const REF_SPOTS = 'REF_SPOTS' // ?

export const HISTORY = "HISTORY"
export const PRICING_HISTORY = "PRICING_HISTORY"


export const AC_PERFORMANCE = "AC_PERFORMANCE"
export const CPN_PERFORMANCE = "CPN_PERFORMANCE"
export const FINAL_PERFORMANCE = "FINAL_PERFORMANCE"

export const CURRENCY_AUTOCALL = "CURRENCY_AUTOCALL"

export const DATE_EXPIRY_AUTOCALL = "DATE_EXPIRY_AUTOCALL"

export const PERIOD_MODE = "PERIOD_MODE"

export const IS_CALL_PAYOFF = "IS_CALL_PAYOFF"

export const NOTIONAL_AUTOCALL = "NOTIONAL_AUTOCALL"

export const GREEKS_AUTOCALL = "GREEKS_AUTOCALL"

export const MODEL_AUTOCALL = "MODEL_AUTOCALL"

export const N_SIMS_AUTOCALL = "N_SIMS_AUTOCALL"

export const ALL_DATA_AUTOCALL = "ALL_DATA_AUTOCALL"

export const EVENT_AUTOCALL = "EVENT_AUTOCALL"

export const SNAPSHOT_AUTOCALL = "SNAPSHOT_AUTOCALL"
