import {useDispatch, useSelector} from "react-redux";
import Select from "../../Select/Select";
import InputNumber from "../../InputNumber/InputNumber";
import {set_model_autocall, set_n_sims_autocall} from "../../../redux/Autocall/actions";
import useViewMode from "../../../hooks/useViewMode";
import {set_model_vanilla, set_n_sims_vanilla} from "../../../redux/Vanilla/actions";
import {set_model_basket, set_n_sims_basket} from "../../../redux/CallOnBasket/action";
import {set_model_participation, set_n_sims_participation} from "../../../redux/Participation/actions";

const SettingsSystem = () => {
    const dispatch = useDispatch()
    const viewMode = useViewMode()
    const [model, modelOptions, nSims] = useSelector(store => {
        const {autocallReducer, VanillaReducer, BasketReducer, ParticipationReducer} = store
        if (viewMode.viewMode === "Autocall") {
            return [autocallReducer.model, autocallReducer.modelOptions, autocallReducer.n_sims]
        } else if (viewMode.viewMode === "Vanilla") {
            return [VanillaReducer.model, VanillaReducer.modelOptions, VanillaReducer.n_sims]
        } else if (viewMode.viewMode === "Call On Basket") {
            return [BasketReducer.model, BasketReducer.modelOptions, BasketReducer.n_sims]
        } else if (viewMode.viewMode === "Protected Participation") {
            return [ParticipationReducer.model, ParticipationReducer.modelOptions, ParticipationReducer.n_sims]
        }
    })



    const setModel = (val) => {
        if (viewMode.viewMode === "Autocall") {
            dispatch(set_model_autocall(val))
        } else if (viewMode.viewMode === "Vanilla") {
            dispatch(set_model_vanilla(val))
        } else if (viewMode.viewMode === "Call On Basket") {
            dispatch(set_model_basket(val))
        } else if (viewMode.viewMode === "Protected Participation") {
            dispatch(set_model_participation(val))
        }

    }

    const setSims = (val) => {
        if (viewMode.viewMode === "Autocall") {
            dispatch(set_n_sims_autocall(val))
        } else if (viewMode.viewMode === "Vanilla") {
            dispatch(set_n_sims_vanilla(val))
        } else if (viewMode.viewMode === "Call On Basket") {
            dispatch(set_n_sims_basket(val))
        } else if (viewMode.viewMode === "Protected Participation") {
            dispatch(set_n_sims_participation(val))
        }
    }

    return (
        <>
            <Select selected={model} values={modelOptions} setSelected={setModel} label={"Model"} />
            <InputNumber value={nSims} changeCallback={setSims} label={"MonteCarlo Simulations"} />
        </>
    )
}

export default SettingsSystem