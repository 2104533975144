import './Link.css'

const Link = ({title, selection}) => {
    if (title === "Home") {
        return (
            <a href="/" className={selection === title.toLowerCase() ? 'link active' : 'link'}>
                {title}
            </a>
        )
    }
    return (
        <a href={`/${title.toLowerCase()}`} className={selection === title.toLowerCase() ? 'link active' : 'link'}>
            {title}
        </a>
    )
}
export default Link