import './SearchLine.css'
import img from './../../../static/search.svg'
import img_close from './../../../static/close.svg'
import {useEffect, useState} from "react";
import useMainView from "../../../hooks/useMainView";
import TickerHint from "../../../components/TickerHint/TickerHint";
import useAllTickers from "../../../hooks/useAllTickers";
import {useLocation, useNavigate} from "react-router-dom";

const SearchLine = () => {
    const [command, setCommand] = useState('')
    const [isActive, setIsActive] = useState(false)
    const [countDown, setCountDown] = useState(0)
    const mainView = useMainView()
    const location = useLocation()
    const navigate = useNavigate()
    const allTickers = useAllTickers()
    let isSelected = false
    let isReceived = false

    const IsMobile = window.outerWidth < 768
    useEffect(() => {
        if (!isReceived) {
            setCommand(mainView.command)
            isReceived = true
        }
    }, [mainView.command])

    const targetCommand = command.split(' ').pop()
    const tickers = allTickers.searchTicker(targetCommand, true)

    const setMode = () => {
        if (command === '') return
        const trimCommand = command.trim()
        const commandItem = trimCommand.split(' ')
        const commandName = commandItem[commandItem.length - 1]
        mainView.setMainView(commandName, command)
        if (location.pathname !== "/pricing") {
            navigate("/pricing")
        }
        document.getElementById("search_line").blur()
    }

    const addCommand = (cmd) => {
        isSelected = true
        const commandItem = command.split(' ')
        commandItem[commandItem.length - 1] = cmd + " "
        setCommand(commandItem.join(' '))
    }

    const doBlur = () => {
        setTimeout(() => {
            if (isSelected) {
                document.getElementById("search_line").focus()
                isSelected = false
            } else {
                setIsActive(false)
            }
        }, 100)
    }

    const onClickArrowDown = () => {
        setCountDown(prev => prev + 1)
    }

    return (
        <div className="search_line">
            <input type="text"
                   autoComplete={"off"}
                   id={"search_line"}
                   placeholder="Enter Command"
                   value={command}
                   onFocus={() => setIsActive(true)}
                   onBlur={() => doBlur()}
                   onChange={e => setCommand(e.target.value)}
                   onKeyDown={e => {
                       if (e.key === 'Enter') {
                           if (countDown > 0) {
                               addCommand(tickers[countDown - 1])
                               setCountDown(0)
                           } else {
                               setMode()
                               setIsActive(false)
                           }

                       } else if (e.key === 'ArrowDown') {
                           e.preventDefault()
                           onClickArrowDown()
                       } else if (e.key === 'ArrowUp') {
                           e.preventDefault()
                           if (countDown > 0) setCountDown(prev => prev - 1)
                       }
                   }}
            />
            {!IsMobile &&
            <button className="search_line-btn"
                    onClick={() => setMode()}
            >
                <img src={img} alt="desc"/>
            </button>
            }
            {IsMobile &&
            <button className="search_line-btn"
                    onClick={() => setCommand('')}
            >
                <img src={img_close} alt="clear"/> 
            </button>
            }
            <TickerHint isActive={isActive}
                        command={command}
                        setCommand={addCommand}
                        resetClickDown={setCountDown}
                        countDown={countDown}
                        targetCommand={targetCommand}
                        tickers={tickers}
            />
        </div>
    )
}

export default SearchLine