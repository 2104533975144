import './ViewManager.css'
import Autocall from "../../views/products/Autocall";
import useViewMode from "../../hooks/useViewMode";
import Vanilla from "../../views/products/Vanilla";
import MarketData from "../../views/products/MarketData";
import CallOnBasket from "../../views/products/CallOnBasket";
import Participation from "../../views/products/Participation";
import useRefreshMarketData from "../../hooks/useRefreshMarketData";
import {useParams} from "react-router-dom";
import useFixingDetails from "../../hooks/useFixingDetails";
import {useEffect, useState} from "react";
import useRestoreSnapshot from "../../hooks/snapshots/useRestoreSnapshot";
import useInfoPopup from "../../hooks/useInfoPopup";
import useQueryParams from "../../hooks/useQueryParams";
import useRefreshByRequestId from "../../hooks/useRefreshByRequestId";
import {useDispatch, useSelector} from "react-redux";
import {MarketDataReducer} from "../../redux/MarketData/MarketDataReducer";
import {set_is_local_window} from "../../redux/Common/actions";
import DigitalCall from "../../views/products/DigitalCall";
import {DigitalCallShort} from "../../views/products/DigitalCallShort";
import {SpreadOption} from "../../views/products/SpreadOption";

/**
 * This component make decision about which product to render
 * @param mode - details option (0 - pricing request, 1 - fixing details, 2 - market data, 3 - trade details)
 * @param productSelected - selected product (AUTOCALL, ...)
 * @returns {JSX.Element} - return component to render
 * @constructor
 */
const ViewManager = ({mode}) => {
    const viewManager = useViewMode()
    const refreshManager = useRefreshMarketData()
    const {iid} = useParams()
    const {setPopupStatus} = useInfoPopup()
    const fixingDetails = useFixingDetails()
    const {restore} = useRestoreSnapshot()
    const [restoreDone, setRestoreDone] = useState(true)
    const queryParams = useQueryParams()
    const {refresh} = useRefreshByRequestId()
    const dispatch = useDispatch()

    const event = useSelector(state => state.MarketDataReducer.events)

    useEffect(() => {
        if (iid !== undefined && restoreDone) {
            restore(iid)
                .then(() => {
                    setPopupStatus(true, "Success restored", true)
                    setRestoreDone(false)
                })
        } else if (queryParams.get("restore") !== null && restoreDone) {
            console.log(queryParams.get("restore"))
            refresh(queryParams.get("restore"))
            setRestoreDone(false)
        } else {
            if (viewManager.viewMode !== "MarketData") {
                // refreshManager.refreshMD()
                //     .then(() => {
                //         console.log("EVENT", event)
                //         fixingDetails.refreshFixingDetailsFull()
                //     })
                dispatch(set_is_local_window(true))
                if (viewManager.viewMode === "Autocall" || viewManager.viewMode === "Digital Call on Basket" || viewManager.viewMode === "Digital Call") {
                    fixingDetails.refreshFixingDetailsFull()
                        .then((result) => {
                            console.warn("REFRESH FIXING DETAILS", result)
                            refreshManager.refreshMD(result)
                                .then(() => {
                                    console.log("EVENT", event)
                                })
                        })
                } else {
                    refreshManager.refreshMD()
                        .then(() => {
                            console.log("EVENT", event)
                        })
                }
            }
        }
    }, [viewManager.viewMode])

    // TODO :product:
    if (viewManager.viewMode === "Autocall") return <Autocall mode={mode} />
    else if (viewManager.viewMode === "Vanilla") return <Vanilla mode={mode}/>
    else if (viewManager.viewMode === "Call On Basket") return <CallOnBasket mode={mode}/>
    else if (viewManager.viewMode === "MarketData") return <MarketData mode={mode}/>
    else if (viewManager.viewMode === "Protected Participation") return <Participation mode={mode}/>
    else if (viewManager.viewMode === "Digital Call on Basket") return <DigitalCall mode={mode}/>
    else if (viewManager.viewMode === "Digital Call") return <DigitalCallShort mode={mode}/>
    else if (viewManager.viewMode === "Spread Option") return <SpreadOption mode={mode}/>
    else if (viewManager.viewMode === "GP") return <div>GP Test</div>
    return (
        <div>Unrecognized product: {viewManager.viewMode}</div>
    )
}

export default ViewManager