export const LAST_DATA_AUTOCALL = "LAST_DATA_AUTOCALL"

export const IS_CHANGED = "IS_CHANGED"

export const VIEW_MODE = "VIEW_MODE"

export const POPUP_STATUS = "POPUP_STATUS"

export const POPUP_CONTENT = "POPUP_CONTENT"

export const POPUP_TYPE = "POPUP_TYPE"

export const TRUNCATE_TICKER = "TRUNCATE_TICKER"

export const IS_LOADER_WINDOW = "IS_LOADER_WINDOW"

export const IS_MODAL_WINDOW = "IS_MODAL_WINDOW"

export const GRAPH_MODE = "GRAPH_MODE"

export const MAIN_VIEW = "MAIN_VIEW"

export const COMMANDS = "COMMANDS"

export const REFRESH_MODE = "REFRESH_MODE"

export const REFRESH_FIELDS = "REFRESH_FIELDS"

export const GRAPH_PARAMS = "GRAPH_PARAMS"

export const GRAPH_DATE = "GRAPH_DATE"

export const IS_REFRESH_BY_ID = "IS_REFRESH_BY_ID"

export const ALL_TICKERS = "ALL_TICKERS"

export const TAB_OPTION = "TAB_OPTION"

export const ALL_DATA_COMMON = "ALL_DATA_COMMON"
