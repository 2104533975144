import {useDispatch, useSelector} from "react-redux";
import {set_currency_spread_option, set_is_without_notional_spread_option} from "../../redux/SpreadOption/actions";

export const useProductNotional = () => {
    const dispatch = useDispatch()
    const [isWithoutNotional, currency, currencyOptions] = useSelector(state => {
        const {spreadOptionReducer} = state;
        return [spreadOptionReducer.isWithoutNotional, spreadOptionReducer.currency, spreadOptionReducer.currencyOptions]
    })

    const setIsWithoutNotional = (value) => {
        if (value === "Warrant") {
            value = true
        } else if (value === "Note") {
            value = false
        }
        dispatch(set_is_without_notional_spread_option(value))
    }

    const setCurrency = (value) => {
        dispatch(set_currency_spread_option(value))
    }

    return {
        title: "Notional",
        data: [
            {
                name: "Wrapper",
                value: isWithoutNotional ? "Warrant" : "Note",
                onChange: setIsWithoutNotional,
                type: {
                    name: "selector",
                    options: ["Warrant", "Note"]
                }
            },
            {
                name: "Currency",
                value: currency,
                onChange: setCurrency,
                type: {
                    name: "selector",
                    options: currencyOptions
                }
            }
        ]
    }
}