import {useSelector} from "react-redux";

const useParticipationSnapshot = () => {
    return useSelector((store) => {
        const {ParticipationReducer, MarketDataReducer, commonReducer} = store
        return {
            product: ParticipationReducer,
            marketData: MarketDataReducer,
            common: commonReducer
        }
    })
}

export default useParticipationSnapshot