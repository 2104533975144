import {useSelector} from "react-redux";

export const useProductSpreadOption = () => {
    const [acStrike, noACPeriod, acStepdown, isWithoutNotional,
        timeToMaturity, scheduleFreq, cpnStrike, fixedCPNCNT,
        cpnAMTpa, strike, barrier, tradingDate,
        acStrikes, cpnStrikes, fixedCPNAMT, fixingDates,
        isMemory, refSpots, tickers, acPerformance,
        cpnPerformance, finalPerformance, currency, isCallPayoff,
        spreadOption, spreadOptionOrder] = useSelector((state) => {
        const {spreadOptionReducer} = state
        return [
            spreadOptionReducer.acStrike, spreadOptionReducer.noACPeriod,
            spreadOptionReducer.acStepdown, spreadOptionReducer.isWithoutNotional,
            spreadOptionReducer.timeToMaturity, spreadOptionReducer.scheduleFreq,
            spreadOptionReducer.cpnStrike, spreadOptionReducer.fixedCPNCNT,
            spreadOptionReducer.cpnAMTpa, spreadOptionReducer.strike,
            spreadOptionReducer.barrier,
            spreadOptionReducer.tradingDate, spreadOptionReducer.acStrikes,
            spreadOptionReducer.cpnStrikes, spreadOptionReducer.fixedCPNAMT,
            spreadOptionReducer.fixingDates, spreadOptionReducer.isMemory,
            spreadOptionReducer.refSpots, spreadOptionReducer.tickers,
            spreadOptionReducer.acPerformance,
            spreadOptionReducer.cpnPerformance, spreadOptionReducer.finalPerformance,
            spreadOptionReducer.currency, spreadOptionReducer.isCallPayoff,
            spreadOptionReducer.spreadOption, spreadOptionReducer.spreadOptionOrder
        ]
    })

    return {
        ac_stepdown: acStepdown,
        ac_strike: acStrike,
        ac_strikes: acStrikes,
        barrier: barrier,
        cpn_amt_pa: cpnAMTpa,
        cpn_strike: cpnStrike,
        cpn_strikes: cpnStrikes,
        class_name: "Autocall",
        currency: currency,
        fixed_cpn_amt: fixedCPNAMT,
        fixed_cpn_cnt: fixedCPNCNT,
        fixing_dates: fixingDates,
        is_call_payoff: isCallPayoff,
        is_memory: isMemory,
        is_without_notional: isWithoutNotional, // TODO tmp
        no_ac_period: noACPeriod,
        ref_spots: refSpots,
        schedule_freq: scheduleFreq,
        strike: strike,
        tickers: tickers,
        time2maturity: Math.round(timeToMaturity * 365),
        trading_date: tradingDate,
        typology: "EQS", // TODO Где взять?
        version: "v1.0_20232701_1",
        barrier_types: {
            ac: acPerformance,
            cpn: cpnPerformance,
            put: finalPerformance
        },
        spread_option: spreadOption,
        spread_option_order: spreadOptionOrder,
    }
}