export const FIXING_DATES_PARTICIPATION = "FIXING_DATES_PARTICIPATION"

export const IS_WITHOUT_NOTIONAL_PARTICIPATION = "IS_WITHOUT_NOTIONAL_PARTICIPATION"

export const PAYOFF_PARTICIPATION = "PAYOFF_PARTICIPATION"

export const CURRENCY_PARTICIPATION = "CURRENCY_PARTICIPATION"

export const PRICE_CURRENCY_PARTICIPATION = "PRICE_CURRENCY_PARTICIPATION"

export const STRIKE_PARTICIPATION = "STRIKE_PARTICIPATION"

export const TICKERS_PARTICIPATION = "TICKERS_PARTICIPATION"

export const TRADING_DATE_PARTICIPATION = "TRADING_DATE_PARTICIPATION"

export const CALL_UP_LIMIT_PARTICIPATION = "CALL_UP_LIMIT_PARTICIPATION"

export const REF_SPOTS_PARTICIPATION = "REF_SPOTS_PARTICIPATION"

export const FIXING_DATE_PARTICIPATION = "FIXING_DATE_PARTICIPATION"

export const STRIKE_ABS_PARTICIPATION = "STRIKE_ABS_PARTICIPATION"

export const CALL_UP_LIMIT_ABS_PARTICIPATION = "CALL_UP_LIMIT_ABS_PARTICIPATION"

export const GREEKS_PARTICIPATION = "GREEKS_PARTICIPATION"

export const NOTIONAL_PARTICIPATION = "NOTIONAL_PARTICIPATION"

export const PERIOD_MODE_PARTICIPATION = "PERIOD_MODE_PARTICIPATION"

export const MODEL_PARTICIPATION = "MODEL_PARTICIPATION"

export const N_SIMS_PARTICIPATION = "N_SIMS_PARTICIPATION"

export const LEVERAGE_RATE_PARTICIPATION = "LEVERAGE_RATE_PARTICIPATION"

export const ALL_DATA_PARTICIPATION = "ALL_DATA_PARTICIPATION"
