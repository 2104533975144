export const FIXING_DATES_VANILLA = "FIXING_DATES_VANILLA"

export const IS_WITHOUT_NOTIONAL_VANILLA = "IS_WITHOUT_NOTIONAL_VANILLA"

export const PAYOFF_VANILLA = "PAYOFF_VANILLA"

export const CURRENCY = "CURRENCY"

export const PRICE_CURRENCY = "PRICE_CURRENCY"

export const STRIKE_VANILLA = "STRIKE_VANILLA"

export const TICKERS_VANILLA = "TICKERS_VANILLA"

export const TRADING_DATE_VANILLA = "TRADING_DATE_VANILLA"

export const CALL_UP_LIMIT = "CALL_UP_LIMIT"

export const REF_SPOTS_VANILLA = "REF_SPOTS_VANILLA"

export const FIXING_DATE_VANILLA = "FIXING_DATE_VANILLA"

export const STRIKE_ABS_VANILLA = "STRIKE_ABS_VANILLA"

export const CALL_UP_LIMIT_ABS = "CALL_UP_LIMIT_ABS"

export const GREEKS_VANILLA = "GREEKS_VANILLA"

export const NOTIONAL_VANILLA = "NOTIONAL_VANILLA"

export const PERIOD_MODE_VANILLA = "PERIOD_MODE_VANILLA"

export const MODEL_VANILLA = "MODEL_VANILLA"

export const N_SIMS_VANILLA = "N_SIMS_VANILLA"

export const ALL_DATA_VANILLA = "ALL_DATA_VANILLA"
