import './FixingDetailsDesktop.css'
import SimpleMainButton from "../SimpleMainButton/SimpleMainButton";
import useFixingDetails from "../../hooks/useFixingDetails";
import InputNumber from "../InputNumber/InputNumber";
import InputDate from "../InputDate/InputDate";
import Graph from "../Graph/Graph";
import GraphFixings from "../GraphFixings/GraphFixings";
import Modal from "../Modal/Modal";
import {useState} from "react";
import openSymbol from '../../static/open-symbol.png'

const FixingDetailsDesktop = () => {
    const data = useFixingDetails()
    const [isActive, setIsActive] = useState(false)
    const [fixing, setFixing] = useState({
        date: undefined,
        tickers: [],
        values: []
    })
    console.log("ALL FIXINGS", data.fixings)
    console.warn("{}", data)
    return (
        <div className={"fixing_details_desktop"}>
            <Modal isActive={isActive}>
                <div onClick={() => setIsActive(false)} className="des__wrapper">
                    <div onClick={e => e.stopPropagation()} className="des__data des__data__small">
                        <p onClick={() => setIsActive(false)} className="des__data-close">&times;</p>
                        <div className="des__data-card">
                            <div className="des__data-card-title">Fixing Detail</div>
                            <div className="des__data-line des__data-line__small headhead">
                                <p className="des__data-line-fixings">Ticker</p>
                                <p className="des__data-line-fixings">{fixing.date}</p>
                            </div>
                            {fixing.tickers.map((elem, i) => (
                                    <div key={i} className="des__data-line des__data-line__small">
                                        <p className="des__data-line-fixings">{elem}</p>
                                        <p className="des__data-line-fixings">{fixing.values[i]}</p>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="fixing_detail_desktop-actions">
                <div className="fixing_detail_desktop-actions__item">
                    <SimpleMainButton text={"+ Add Row"} style_={"grey-m"} callback={() => data.addFixingDetails()}/>
                </div>
                <div className="fixing_detail_desktop-actions__item">
                    <SimpleMainButton text={"- Delete Row"} style_={"grey-m"} callback={() => data.removeFixingDetails()}/>
                </div>
            </div>
            <div className="fixing_detail_desktop-details__header-item">
                <div className="fixing_detail_desktop-details__header">
                    <div
                        className="fixing_detail_desktop-details__header-item fixing_detail_desktop-details--selector"></div>
                    <div
                        className="fixing_detail_desktop-details__header-item fixing_detail_desktop-details--pricing">Pricing
                    </div>
                    <div className="fixing_detail_desktop-details__header-item fixing_detail_desktop-details--ac">AC
                        Strike, %
                    </div>
                    <div className="fixing_detail_desktop-details__header-item fixing_detail_desktop-details--cpn">Cpn
                        Strike, %
                    </div>
                    {Array.isArray(data.fixings) && data.fixings.length !== 0 &&
                        <div className="fixing_detail_desktop-details__header-item">
                            Details
                        </div>
                    }
                </div>
                <div className="fixing_detail_desktop-details__body">
                    {data && data.fixingDates.map((elem, i) => (
                        <div className="fixing_detail_desktop-details__body-row" key={i}>
                            <div
                                className="fixing_detail_desktop-details__body-item fixing_detail_desktop-details--selector">
                                <input type="checkbox" checked={data.fixingDetailsState[i]}
                                       onChange={() => data.setFixingDetailsState(i)}
                                       className="fixing_detail_desktop-details--checkbox"/>
                            </div>
                            <div
                                className="fixing_detail_desktop-details__body-item fixing_detail_desktop-details--pricing">
                                <InputDate value={elem} changeCallback={val => data.setFixingDates(val, i)}/>
                            </div>
                            <div
                                className="fixing_detail_desktop-details__body-item fixing_detail_desktop-details--ac">
                                <InputNumber value={Math.round(data.acStrikes[i] * 100)}
                                             changeCallback={val => data.setAcStrikes(val, i)}/>
                            </div>
                            <div
                                className="fixing_detail_desktop-details__body-item fixing_detail_desktop-details--cpn">
                                <InputNumber value={Math.round(data.cpnStrikes[i] * 100)}
                                             changeCallback={val => data.setCpnStrikes(val, i)}/>
                            </div>
                            {data.fixings.length !== 0 &&
                                <>
                                    {Array.isArray(data.fixings) && data.fixings.map(el => {
                                        if (el.Date === elem) {
                                            return (
                                                <div onClick={() => {
                                                    setFixing({
                                                        date: el.Date,
                                                        tickers: Object.keys(el).filter(tmp => tmp !== "Date"),
                                                        values: Object.keys(el).filter(tmp => tmp !== "Date").map(tmp => el[tmp])
                                                    })
                                                    setIsActive(true)
                                                }} className="fixing_detail_desktop-open_img">
                                                    <img src={openSymbol} alt='open'/>
                                                </div>
                                            )
                                        }
                                        return undefined
                                    })}
                                </>
                            }
                        </div>
                    ))
                    }
                </div>
            </div>
            <GraphFixings />
        </div>
    )
}

export default FixingDetailsDesktop