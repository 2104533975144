import './GraphParams.css'
import InputNumber from "../InputNumber/InputNumber";
import {useDispatch, useSelector} from "react-redux";
import useRefSpots from "../../hooks/useRefSpots";

const GraphParams = ({data, setData, setLocalChange, ticker}) => {
    const refSpots = useRefSpots()
    if (localStorage.getItem("oAuth_pricing") !== "full") {
        return null
    }
    console.log("[ ref spots in vol ]", refSpots.refSpots, ticker )
    return (
        <div className={"graph_params"}>
            <div className="graph_params-line">
                <div className="graph_params-line-label">
                    Vol
                </div>
                <div className="graph_params-line-value">
                    <InputNumber value={data.short} changeCallback={(data_) => {
                        setData({...data, short: data_})
                        // setLocalChange(true)
                    }} label={"Short"}/>
                </div>
                <div className="graph_params-line-value">
                    <InputNumber value={data.long} changeCallback={(data_) => {
                        setData({...data, long: data_})
                        // setLocalChange(true)
                    }} label={"Long"}/>
                </div>
            </div>
            <div className="graph_params-line">
                <div className="graph_params-line-label">
                    Skew
                </div>
                <div className="graph_params-line-value">
                    <InputNumber value={data.eps_skew} changeCallback={(data_) => {
                        setData({...data, eps_skew: data_})
                        // setLocalChange(true)
                    }} label={"Short"}/>
                </div>
                <div className="graph_params-line-value">
                    <InputNumber value={data.skew} changeCallback={(data_) => {
                        setData({...data, skew: data_})
                        // setLocalChange(true)
                    }} label={"Long"}/>
                </div>

            </div>
            <div className="graph_params-line">

                <div className="graph_params-line-label">
                    Curve
                </div>
                <div className="graph_params-line-value">
                    <InputNumber value={data.eps_curve} changeCallback={(data_) => {
                        setData({...data, eps_curve: data_})
                        // setLocalChange(true)
                    }} label={"Short"}/>
                </div>
                <div className="graph_params-line-value">
                    <InputNumber value={data.curve} changeCallback={(data_) => {
                        setData({...data, curve: data_})
                        // setLocalChange(true)
                    }} label={"Long"}/>
                </div>
            </div>
            <div className="graph_params-line">
                <div className="graph_params-line-label">
                    Other
                </div>
                <div className="graph_params-line-value">
                    <InputNumber value={data.eps_curve} changeCallback={(data_) => {
                        setData({...data, eps_curve: data_})
                        // setLocalChange(true)
                    }} label={"Speed"}/>
                </div>
                <div className="graph_params-line-value">
                    <InputNumber value={data.ref_spot} changeCallback={value => {
                        setData({...data, ref_spot: value})
                        // setLocalChange(true)
                    }}
                    label={"Ref Spot"}/>
                </div>
            </div>

        </div>
    )
}

export default GraphParams