import './MarketDataLine.css'
import useMarketData_do_ref from "../../hooks/useMarketData_do_ref";
import InputText from "../InputText/InputText";
import InputNumber from "../InputNumber/InputNumber";
import {useEffect, useState} from "react";
import InputGraph from "../InputGraph/InputGraph";
import GraphVol from "../GraphVol/GraphVol";
import ModalWindow from "../ModalWindow/ModalWindow";
import Divs from "../Divs/Divs";
import {useDispatch, useSelector} from "react-redux";
import {set_graph_mode, set_is_modal_window} from "../../redux/Common/actions";

const MarketDataLine = ({ticker}) => {
    const data = useMarketData_do_ref(ticker)
    const dispatch = useDispatch()
    // const [isModalActive, setIsModalActive] = useState(false)
    const [volChange, setVolChange] = useState(false)
    const [divChange, setDivChange] = useState(false)
    const [element, setElement] = useState(<GraphVol ticker={ticker} setVolChange={setVolChange}/>)
    const [isModalActive, option] = useSelector((state) => {
        const {commonReducer} = state
        return [commonReducer.isModalWindow, commonReducer.graphMode]
    })

    // console.log(element, option)

    useEffect(() => {
        if (option === 0) {
            setElement(<GraphVol ticker={ticker} setVolChange={setVolChange}/>)
        } else if (option === 1) {
            setElement(<Divs ticker={ticker} setDivChange={setDivChange}/>)
        }
        console.log("[ OPT ELEM ]", element)
    }, [])

    useEffect(() => {
        if (isModalActive === true) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [isModalActive])

    const choose = (option) => {
        // setIsModalActive(true)
        console.log("[ OPTION ]", option, ticker)
        dispatch(set_graph_mode(option))
        dispatch(set_is_modal_window({
            status: true,
            ticker
        }))
        if (option === 0) {
            console.log("[ OPT 0 ]")
            setElement(<GraphVol ticker={ticker} setVolChange={setVolChange}/>)
        } else if (option === 1) {
            // FIXME довести до ума
            console.log("[ OPT 1 ]")
            setElement(<Divs ticker={ticker} setDivChange={setDivChange}/>)
        }
    }

    return (
        <div className={"market_data_line"}>
            <ModalWindow isActive={isModalActive.ticker === ticker && isModalActive.status} setIsActive={() => dispatch(set_is_modal_window(false))} content={element}/>
            <div className="market_data_line--name">
                <InputText value={ticker}/>
            </div>
            <div className="market_data_line--spot">
                <InputNumber value={data.spot} changeCallback={(value) => data.changeSpot(value)}/>
            </div>
            <div className="market_data_line--vol">
                <InputGraph value={data.vol} changeCallback={(value) => data.changeVols(value)} openFunc={() => {
                    choose(0)
                }} volChange={volChange}/>
            </div>
            <div className="market_data_line--div">
                <InputGraph value={data.div} changeCallback={(value) => data.changeDivs(value)} openFunc={() => {
                    choose(1)
                }} volChange={divChange}/>
            </div>
            <div className="market_data_line--ref_spot">
                <InputNumber value={data.refSpot} changeCallback={(value) => data.changeRefSpot(value)}/>
            </div>
        </div>
    )
}

export default MarketDataLine