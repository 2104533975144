import './StockRoot.css'
import Container from "../Container/Container";
import {useState} from "react";
import GraphWindow from "../GraphWindow/GraphWindow";


const StockRoot = () => {
    const [loadingGraph, setLoadingGraph] = useState(false)

    const [mode, setMode] = useState("1M")

    return (
        <main>
            {/*<Container>*/}
                <div className={`root_stock`}>
                    <div className={"root_stock-main"}>
                        <GraphWindow
                            loadingGraph={loadingGraph}
                            mode={mode}
                            setMode={setMode}
                        />
                    </div>
                </div>
            {/*</Container>*/}
        </main>
    )
}

export default StockRoot