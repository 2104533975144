import PricingView from "../../routes/Main/PricingView/PricingView";
import useRefreshMarketData from "../../hooks/useRefreshMarketData";
import {useEffect} from "react";

const CallOnBasket = ({mode}) => {
    const refreshManager = useRefreshMarketData()

    // useEffect(() => {
    //     refreshManager.refreshMD()
    // }, [])

    return (
        <PricingView mode={mode}/>
    )
}

export default CallOnBasket;