import {useDispatch, useSelector} from "react-redux";
import useChangeState from "../useChangeState";
import {set_currency_digital_call, set_is_without_notional_digital_call} from "../../redux/DigitalCall/actions";

const useNotionalDigitalCall = () => {
    const dispatcher = useDispatch()
    const changeState = useChangeState()
    const options = ["RUB", "USD", "EUR"]

    const [isWithoutNotional, currency, snapshot] = useSelector((state) => {
        const {digitalCallReducer} = state
        return [digitalCallReducer.isWithoutNotional, digitalCallReducer.currency, digitalCallReducer.snapshot]
    })



    const setCurrency = (value) => {
        dispatcher(set_currency_digital_call(value))
    }

    const setIsWithoutNotional = (value) => {
        if (value === "Warrant") {
            value = true
        } else if (value === "Note") {
            value = false
        }
        changeState.setChange(true, isWithoutNotional, value)
        dispatcher(set_is_without_notional_digital_call(value))
    }

    return {
        title: "Notional",
        data: [
            {
                name: "Currency",
                value: currency,
                onChange: setCurrency,
                change: snapshot.currency !== currency,
                type: {
                    name: "selector",
                    options: options
                }
            },
            {
                name: "Wrapper",
                value: isWithoutNotional ? "Warrant" : "Note",
                onChange: setIsWithoutNotional,
                change: snapshot.isWithoutNotional !== isWithoutNotional,
                type: {
                    "name": "selector",
                    "options": ["Note", "Warrant"]
                }
            }]
    }
}

export default useNotionalDigitalCall;