import useViewMode from "../../hooks/useViewMode";
import ProductDetailView from "../../views/ProductDetailView/ProductDetailView";
import ProductDetailViewVanilla from "../../views/ProductDetailView/ProductDetailViewVanilla";
import ProductDetailViewBasket from "../../views/ProductDetailView/ProductDetailViewBasket";
import ProductDetailViewParticipation from "../../views/ProductDetailView/ProductDetailViewParticipation";
import ProductDetailViewDigitalCall from "../../views/ProductDetailView/ProductDetailViewDigitalCall";
import {ProductDetailViewDigitalCallShort} from "../../views/ProductDetailView/ProductDetailViewDigitalCallShort";
import {ProductDataViewSpreadOption} from "../../views/ProductDetailView/ProductDataViewSpreadOption";

const ProductDetailManager = () => {
    const viewManager = useViewMode()
    if (viewManager.viewMode === "Autocall") return <ProductDetailView />
    else if (viewManager.viewMode === "Vanilla") return <ProductDetailViewVanilla/>
    else if (viewManager.viewMode === "Call On Basket") return <ProductDetailViewBasket/>
    else if (viewManager.viewMode === "MarketData") return null
    else if (viewManager.viewMode === "Protected Participation") return <ProductDetailViewParticipation/>
    else if (viewManager.viewMode === "Digital Call on Basket") return <ProductDetailViewDigitalCall/>
    else if (viewManager.viewMode === "Digital Call") return <ProductDetailViewDigitalCallShort/>
    else if (viewManager.viewMode === "Spread Option") return <ProductDataViewSpreadOption/>
    else return null
}
// market_data refresh error:("<class 'pricing.marketdata._MarketData_v2'>.refresh_market_data:", "tickers NOT FOUND in args = () kwargs = {'pricing_date': datetime.date(2024, 1, 10), 'refresh_results': False, 'refresh_history': False, 'ignore_start_date': False, 'ignore_end_date': False, 'outputs': [], 'corr_floor': 0.7, 'corr_history': 365, 'corr_percentile': 0.9, 'corr_len': 20}")
export default ProductDetailManager;