import SettingsSystem from "./SettingsSystem";

const SettingsParticipation = () => {
    return (
        <>
            <div className="settings__block">
                <div className={"settings__block--subtitle"}>System</div>
                <SettingsSystem/>
            </div>
        </>
    )
}

export default SettingsParticipation