import './ProductSelector.css'
import arrow_down from './../../../static/down.svg'
import arrow_down_blue from './../../../static/arrow_down_blue.svg'
import arrow_up from './../../../static/up.svg'
import arrow_up_blue from './../../../static/arrow_up_blue.svg'
import {useState} from "react";
import SelectField from "./SelectField/SelectField";

const  ProductSelector = ({products, productSelected, setProductSelected}) => {
    const [over, setOver] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const toggleChoice = () => {
        setIsActive(!isActive)
    }
    return (
        <div className="product-selector">
            <div className="product-selector-selected" onClick={toggleChoice} onMouseEnter={() => setOver(true)} onMouseLeave={() => setOver(false)}>
                {productSelected}
                <div className="product-selector-img"><img
                    src={isActive ?
                            over ? arrow_up_blue : arrow_up
                        :
                        over ? arrow_down_blue : arrow_down}
                    alt="desc"
                /></div>
            </div>
            <div className={`product-selector-listener ${isActive ? 'active' : ''}`} onClick={toggleChoice}></div>
            <div className={`product-selector-all_product ${isActive ? 'active' : ''}`}>
                <SelectField
                    products={products}
                    productSelected={productSelected}
                    setProductSelector={setProductSelected}
                    setIsActive={setIsActive}
                />

            </div>
        </div>
    )
}

export default ProductSelector