import './PricingHistory.css'
import close from './../../static/cross.svg'
import trash from './../../static/trash.svg'
import close_black from './../../static/cross_black_32.svg'
import {getLSPricingHistory, clearOnlyHistory} from "../../utils/localStorageHandler";
import copy from "clipboard-copy";
import {Tooltip} from "@mui/material";
import dwnImage from "../../static/download_blue_16.svg";
import {CSVLink} from "react-csv";

const clearHistory = (e) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Уверенны, что хотите удалить всю историю?") === true) {
        clearOnlyHistory()
    } else {
        e.stopPropagation()
    }
}

const PricingHistory = ({isActive, setIsActive}) => {
    const history = getLSPricingHistory()

    if (history.length !== 0 && typeof JSON.parse(history[0]).description !== "string") {
        clearOnlyHistory()
    }
    const prepareDataCSV = () => {
        const header = [["№", "Request ID", "PV", "TickerX",
            "Trading", "Expiry", "description"]]
        history.map((elem, i) => {
            const el = JSON.parse(elem)
            // let realScheduleFreq = 0
            // switch (el.ScheduleFreq) {
            //     case "mon":
            //         realScheduleFreq = 30
            //         break
            //     case "qtr":
            //         realScheduleFreq = 91
            //         break
            //     case "s/a":
            //         realScheduleFreq = 182
            //         break
            //     case "ann":
            //         realScheduleFreq = 365
            //         break
            //     default:
            //         realScheduleFreq = 0
            //         break
            // }
            header[i + 1] = [el["N"], el.requestID, el.PV, el.TickerX, el.Trading,
                el.Expiry, el.Time2Maturity, JSON.stringify(el.description)]
            return undefined
        })
        return header
    }
    return (
        <div onClick={() => setIsActive(false)} className={`pricing-history`}>
            <p onClick={() => setIsActive(false)} className={'pricing-history-close'}><img
                src={window.innerWidth > 600 ? close : close_black} alt={'close btn'}/></p>
            <div onClick={e => e.stopPropagation()} className={"pricing-history_main-view"}>
                <div className="market-table-downloads_pricing-history">
                    <img src={dwnImage} alt="dwn icon"/>
                    <div className="market-table-downloads-text">
                        <CSVLink filename={"pricing_history"} data={prepareDataCSV()}>Download</CSVLink>
                    </div>
                </div>
                <p className={'pricing-history_title'}>Pricing History</p>
                <div className={'pricing-history_table'}>
                    <div className={"pricing-history_table-head"}>
                        <div className={"ptable-head ptable-number"}>№</div>
                        <div className={"ptable-head ptable-request-id"}>Request ID</div>
                        <div className={"ptable-head ptable-pv"}>PV</div>
                        {/*<div className={"ptable-head ptable-wrapper"}>Wrapper</div>*/}
                        <div className={"ptable-head ptable-tickers"}>Ticker</div>
                        {/*<div className={"ptable-head ptable-currency"}>Currency</div>*/}
                        <div className={"ptable-head ptable-trading"}>Trading</div>
                        <div className={"ptable-head ptable-expiry"}>Expiry</div>
                        {/*<div className={"ptable-head ptable-t2m"}>Time2 Maturity</div>*/}
                        {/*<div className={"ptable-head ptable-sf"}>Schedule Freq</div>*/}
                        {/*<div className={"ptable-head ptable-ac"}>AC<br/>Strike</div>*/}
                        {/*<div className={"ptable-head ptable-cpn"}>CPN<br/>Strike</div>*/}
                        {/*<div className={"ptable-head ptable-cpnamt"}>Cpn Amt p.a.</div>*/}
                        {/*<div className={"ptable-head ptable-payoff"}>Payoff</div>*/}
                        <div className={"ptable-head ptable-descripton"}>Description</div>
                    </div>
                    {history.map((elem, i) => {
                        const el = JSON.parse(elem)
                        let realScheduleFreq = 0
                        switch (el.ScheduleFreq) {
                            case "mon":
                                realScheduleFreq = 30
                                break
                            case "qtr":
                                realScheduleFreq = 91
                                break
                            case "s/a":
                                realScheduleFreq = 182
                                break
                            case "ann":
                                realScheduleFreq = 365
                                break
                            default:
                                realScheduleFreq = 0
                                break
                        }
                        return <div key={i} className={"pricing-history_table-row"}>
                            <div className={"ptable-row ptable-number"}>{el["N"]}</div>
                            <Tooltip title={"Click to copy"} arrow>
                                <div onClick={() => {
                                    copy(el.requestID)
                                }} className={"ptable-row ptable-request-id"}>{el.requestID}</div>
                            </Tooltip>
                            <div className={"ptable-row ptable-pv"}>{el.PV}</div>
                            {/*<div className={"ptable-row ptable-wrapper"}>{el.Wrapper}</div>*/}
                            <div className={"ptable-row ptable-tickers"}>{el.TickerX}</div>
                            {/*<div className={"ptable-row ptable-currency"}>{el.Currency}</div>*/}
                            <div className={"ptable-row ptable-trading"}>{el.Trading}</div>
                            <div className={"ptable-row ptable-expiry"}>{el.Expiry}</div>
                            {/*<div className={"ptable-row ptable-t2m"}>{el.Time2Maturity}</div>*/}
                            {/*<div className={"ptable-row ptable-sf"}>{realScheduleFreq}</div>*/}
                            {/*<div className={"ptable-row ptable-ac"}>{el.ACStrike}</div>*/}
                            {/*<div className={"ptable-row ptable-cpn"}>{el.CPNStrike}</div>*/}
                            {/*<div className={"ptable-row ptable-cpnamt"}>{el.CPNAMTpa}</div>*/}
                            {/*<div className={"ptable-row ptable-payoff"}>{el.Payoff}</div>*/}
                            <div
                                className={"ptable-row ptable-description"}>{el.description.replaceAll("\n", " ")}</div>
                        </div>
                    })}
                </div>
            </div>
            <Tooltip title={"Delete history"} arrow>
                <div onClick={(e) => {

                    clearHistory(e)
                }} className="clear-localstorage">
                    <img src={trash} alt="trash img"/>
                </div>
            </Tooltip>

        </div>
    )
}

export default PricingHistory