import {useSelector} from "react-redux";

/**
 * @description Хук для получения всех данных из стора для autocall
 * @returns {array} Массив с данными
 */
const useAllData = () => {
    const data = useSelector((store) => {
        const {autocallReducer, MarketDataReducer} = store
        return {
            acStepdown: autocallReducer.acStepdown,
            acStrike: autocallReducer.acStrike,
            acStrikes: autocallReducer.acStrikes, // непонятно как добавлять в csv
            barrier: autocallReducer.barrier,
            barrierTypes: autocallReducer.barrierTypes, // непонятно как добавлять в csv
            cpnAMTpa: autocallReducer.cpnAMTpa,
            cpnStrike: autocallReducer.cpnStrike,
            cpnStrikes: autocallReducer.cpnStrikes, // непонятно как добавлять в csv
            fixedCPNAMT: autocallReducer.fixedCPNAMT,
            fixedCPNCNT: autocallReducer.fixedCPNCNT,
            fixingDates: autocallReducer.fixingDates,
            isMemory: autocallReducer.isMemory,
            isWithoutNotional: autocallReducer.isWithoutNotional,
            refSpots: autocallReducer.refSpots, // непонятно как добавлять в csv
            scheduleFreq: autocallReducer.scheduleFreq,
            strike: autocallReducer.strike,
            tickers: autocallReducer.tickers, // непонятно как добавлять в csv
            timeToMaturity: autocallReducer.timeToMaturity,
            tradingDate: autocallReducer.tradingDate,


            // MarketDataReducer
            corrMtx: MarketDataReducer.corrMtx,
            divs: MarketDataReducer.divs,
            fixings: MarketDataReducer.fixings,
            pricingDate: MarketDataReducer.pricingDate,
            rate: MarketDataReducer.rate,
            spots: MarketDataReducer.spots,
            vols: MarketDataReducer.vols
        }
    })

    const prepareData = () => {
        const result = []
        result.push(["Ac Stepdown", "Ac Strike", "Barrier", "Cpn AMTpa", "Cpn Strike", "Fixed CPN AMT", "Fixed CPN CNT", "Is Memory",
            "Is Without Notional", "Schedule Freq", "Strike", "Time To Maturity", "Trading Date", "Pricing Date", "Rate"])
        result.push([data.acStepdown, data.acStrike, data.barrier, data.cpnAMTpa, data.cpnStrike, data.fixedCPNAMT, data.fixedCPNCNT, data.isMemory,
            data.isWithoutNotional, data.scheduleFreq, data.strike, data.timeToMaturity, data.tradingDate, data.pricingDate, data.rate])
        return result
    }

    return prepareData()

}
export default useAllData;
