import './MarketTable.css'
import dwnImage from "./../../../../static/download_blue_16.svg"
import {CSVLink} from "react-csv";
import InputNumber from "../../../../components/InputNumber/InputNumber";
import useTickerDependencies from "../../../../hooks/useTickerDependencies";
import useCorrMtx from "../../../../hooks/MarketData/useCorrMtx";
import useCorrelationData from "../../../../hooks/MarketData/useCorrelationData";
import useViewMode from "../../../../hooks/useViewMode";
import {useDispatch, useSelector} from "react-redux";
import {set_rate} from "../../../../redux/MarketData/actions";
import useRefreshFields from "../../../../hooks/useRefreshFields";
import useRefreshMode from "../../../../hooks/useRefreshMode";
import useInfoPopup from "../../../../hooks/useInfoPopup";
import useTickerDependenciesCallOnBasket from "../../../../hooks/useTickerDependenciesCallOnBasket";
import useTickerDependenciesDigitalCall from "../../../../hooks/useTickerDependenciesDigitalCall";
import {useTickerDependenciesDigitalCallShort} from "../../../../hooks/useTickerDependenciesDigitalCallShort";
import {useTickerDependenciesSpreadOption} from "../../../../hooks/useTickerDependenciesSpreadOption";

const MarketTable = () => {
    let tickers = useTickerDependencies().tickers
    const corrMtxData = useCorrMtx()
    const correlationData = useCorrelationData()
    const refreshFields = useRefreshFields()
    const refreshMode = useRefreshMode()
    const viewMode = useViewMode()
    const infoPopup = useInfoPopup()

    if (viewMode.viewMode === "Call On Basket") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        tickers = useTickerDependenciesCallOnBasket().tickers
    } else if (viewMode.viewMode === "Digital Call on Basket") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        tickers = useTickerDependenciesDigitalCall().tickers
    } else if (viewMode.viewMode === "Digital Call") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        tickers = useTickerDependenciesDigitalCallShort().tickers
    } else if (viewMode.viewMode === "Spread Option") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        tickers = useTickerDependenciesSpreadOption().tickers

    }


    const prepareDataCSV = () => {
        const data = [
            ["Ticker", ...tickers.map(ticker => ticker)]
        ]
        tickers.forEach((el, i) => {
            if (!(el in corrMtxData.corrMtx)) {
                corrMtxData.corrMtx[el] = {}
            }
            data[i + 1] = []
            data[i + 1][0] = el
            tickers.forEach((elem, j) => {
                if (elem === el) {
                    corrMtxData.corrMtx[el][elem] = 1
                } else if (!(elem in corrMtxData.corrMtx[el])) {
                    corrMtxData.corrMtx[el][elem] = 0
                }
                data[i + 1][j + 1] = corrMtxData.corrMtx[el][elem]
            })
        })
        return data
    }

    const changeRefreshFields = (field, value) => {
        if (refreshMode.refreshMode !== "CUSTOM") {
            infoPopup.setPopupStatus(true, "Switched source of refresh MarketData to CUSTOM", true)
            refreshMode.setRefreshMode("CUSTOM")
        }
        refreshFields.setRefreshFields(field, value)
    }

    return (
        <div className={`market_table`}>
            <div className="market_table-section">
                <div className="market_table-section-title">Correlation Settings</div>
                <div className="market_table-section-content">
                    <div className="market_table-section-content-table">
                        <div className="market_table-section-content-field">
                            <InputNumber label={"Corr Floor"} value={correlationData.corrFloor}
                                         changeCallback={val => correlationData.setCorrFloor(val)}/>
                        </div>
                        <div className="market_table-section-content-field">
                            <InputNumber label={"Corr Percentile"} value={correlationData.corrPercentile}
                                         changeCallback={val => correlationData.setCorrPercentile(val)}/>
                        </div>
                        <div className="market_table-section-content-field">
                            <InputNumber label={"Corr Len"} value={correlationData.corrLen}
                                         changeCallback={val => correlationData.setCorrLen(val)}/>
                        </div>
                        <div className="market_table-section-content-field">
                            <InputNumber label={"Corr History"} value={correlationData.corrHistory}
                                         changeCallback={val => correlationData.setCorrHistory(val)}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="market_table-section">
                {viewMode.viewMode !== 'Vanilla'
                    ?
                    <>
                        <div className="market_table-section-header">
                            <div className="market_table-section-title">
                                <p>Correlations</p>
                                <input type="checkbox"
                                       checked={refreshFields.refreshFields.corr_mtx}
                                       onChange={() => changeRefreshFields("corr_mtx", !refreshFields.refreshFields.corr_mtx)}
                                />
                            </div>
                            <div className="market-table-downloads">
                                <img src={dwnImage} alt="dwn icon"/>
                                <div className="market-table-downloads-text">
                                    <CSVLink filename={"correlation_matrix"} data={prepareDataCSV()}>Download</CSVLink>
                                </div>
                            </div>
                        </div>

                        <div className="market_table-section-content">
                            <div className={`market-table-content`}>
                                <div className={"market_table-corr_mtx"}>
                                    <div className={`market_table-header`}>
                                        <div className={`market_table-ticker market-table-ticker-header`}>Ticker</div>
                                        {tickers.map((elem, i) => (
                                            <div key={i}
                                                 className={`market_table-value market-table-value-header`}>{elem}</div>
                                        ))}
                                    </div>
                                    {tickers.map((elemCol, i) => (
                                        <div key={i} style={{width: '100%'}}>
                                            <div style={{borderTop: `1px solid #E3E3E5`}}></div>
                                            <div className={`market-table-line`}>
                                                <div className={`market_table-ticker`}>{elemCol}</div>
                                                {tickers.map((elemRow, j) => (
                                                    // <Input defaultValue={corrMtxData.corrMtx[elemCol][elemRow]}
                                                    //        changeCallback={val => corrMtxData.changeCorrMtx(elemCol, elemRow, val)}
                                                    //        styleClassName={`matrix-input`}/>
                                                    <div className={"market_table-value"}>
                                                        <InputNumber value={corrMtxData.corrMtx[elemCol][elemRow]}
                                                                     changeCallback={val => corrMtxData.changeCorrMtx(elemCol, elemRow, val)}/>
                                                    </div>
                                                    // <div key={j} className={`market-table-value`}>{}</div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                    : null
                }

            </div>
        </div>
    )
}

export default MarketTable