import './LoaderWindow.css'
import loader from './../../static/load.png'
import {useSelector} from "react-redux";

const LoaderWindow = () => {
    const isLoaderWindow = useSelector(state => {
        const {commonReducer} = state
        return commonReducer.isLoaderWindow
    })

    if (!isLoaderWindow) {
        document.body.style.overflow = "auto"
        return null
    }
    else {
        document.body.style.overflow = "hidden"
    }

    return (
        <div className={"loader-window"}>
            <div className={"loader-window_content"}>
                <div className="loader-window_content-image">
                    <img src={loader} alt=""/>
                </div>
                <h3>Loading ...</h3>
            </div>
        </div>
    )
}

export default LoaderWindow