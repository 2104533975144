import './TickersSelector.css'
import InputTicker from "../InputTicker/InputTicker";
import TickersWindow from "../TickersWindow/TickersWindow";


/**
 * Components for union Field for input ticker and window for manage tickers
 * @returns {JSX.Element}
 * @constructor
 */
const TickersSelector = () => {
    return (
        <div>
            <InputTicker />
            <TickersWindow />
        </div>
    )
}

export default TickersSelector