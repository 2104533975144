import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import Pricing from "./routes/Pricing/Pricing";
import {compose, createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import {rootReducer} from "./redux/rootReducer";
// import FirstUpdate from "./utils/FirstUpdate";
import Logs from "./routes/Logs/Logs";
import Auth from "./routes/Auth/Auth";
import Logout from "./routes/Logout/Logout";
import Main from "./routes/Main/Main";
import Title from "./routes/Title/Title";
import thunkMiddleware from "redux-thunk";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3c76bedff666249fa776aee4e092c3e7@sentry.rumberg.ru/8",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: true,
        blockAllMedia: false,
    }
    ),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.75, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https:\/\/pricing.rumtechdemo.ru", "https:\/\/pricing.rumberg.ru"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.75, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const composeEnhancers = compose;
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware))
)

const router = createBrowserRouter([
    {
        path: "/pricing",
        element: <Pricing/>
    },
    {
        path: "/pricing/:iid",
        element: <Pricing/>
    },
    {
        path: "/auth",
        element: <Auth/>
    },
    {
        path: "/logout",
        element: <Logout/>
    },
    {
        path: "/portfolio",
        element: <Pricing/>
    },
    {
        path: "/",
        element: <Title/>
    },
    {
        path: "/logs",
        element: <Logs/>
    },
    {
        path: "/test_page",
        element: <div>Test page</div>
    },
    {
        path: "/:id",
        element: <Pricing/>
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        {/*<FirstUpdate/>*/}
        <RouterProvider router={router}/>
    </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
