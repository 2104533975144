import {useDispatch, useSelector} from "react-redux";
import {set_all_tickers} from "../redux/Common/actions";
import {getTickerHistory} from "../utils/localStorageHandler";
import useRequest from "./useRequest";

const useAllTickers = () => {
    const dispatch = useDispatch()
    const {get} = useRequest()
    const allTickers = useSelector(store => {
        return store.commonReducer.allTickers
    })

    const getAllTickers = () => {
        get(
            "/charts/render/",
            {
                params: {
                    tickers: JSON.stringify(["*"]),
                    command: "DES"
                }
            },
            (data) => {
                const result = Object.keys(data).map(item => item.replaceAll(" ", "_"))
                dispatch(set_all_tickers(result))
                console.log(" [ all tickers ]", result)
            }
        )
            .then(() => {
                console.log(" [ __ all tickers done __ ]")
            })
    }

    const getAllGraphs = () => {
        get("/logger/test_get_all", () => {}, () => {}).then(res => {
            console.warn("all commands from back", res)
        })
    }

    const searchTicker = (value, additional) => {
        if (additional) {
            const copyTickers = [...allTickers]
            copyTickers.push(...getTickerHistory())
            return copyTickers.filter(ticker => {
                return ticker.toLowerCase().includes(value.toLowerCase())
            })
        }
        return allTickers.filter(ticker => {
            return ticker.toLowerCase().includes(value.toLowerCase())
        })
    }

    return {
        getAllTickers,
        searchTicker,
        getAllGraphs
    }
}

export default useAllTickers