import {set_call_up_limit, set_call_up_limit_abs} from "../../redux/Vanilla/actions";
import {useDispatch, useSelector} from "react-redux";

const useCapVanilla = () => {
    const dispatch = useDispatch()
    const [callUpLimit, callUpLimitAbs, refSpots, tickers] = useSelector((state) => {
        const {VanillaReducer} = state;
        return [VanillaReducer.callUpLimit, VanillaReducer.callUpLimitAbs, VanillaReducer.refSpots, VanillaReducer.tickersVanilla]
    })

    const setCallUpLimit = (value) => {
        console.log('[setCallUpLimit] ', value, callUpLimit)
        if (value === undefined) {
            dispatch(set_call_up_limit(undefined))
            dispatch(set_call_up_limit_abs(undefined))
            return
        }
        const tmp = Number((value / 100).toFixed(2))
        console.log("tmp", tmp)
        const abs = Number((tmp * refSpots[tickers[0]]).toFixed(2))
        dispatch(set_call_up_limit(tmp))
        dispatch(set_call_up_limit_abs(abs))

    }

    const setCallUpLimitAbs = (value) => {
        console.log('[setCallUpLimitAbs] ', value, callUpLimitAbs)
        if (value === undefined) {
            dispatch(set_call_up_limit_abs(undefined))
            dispatch(set_call_up_limit(undefined))
            return
        }
        const tmp = Number(((value / refSpots[tickers[0]])).toFixed(2))
        console.log("tmp in abs", tmp)
        dispatch(set_call_up_limit_abs(value))
        dispatch(set_call_up_limit(tmp))
    }

    return {
        title: "Cap",
        data: [
            {
                name: "Upper Strike, %",
                value: callUpLimit ? callUpLimit * 100 : undefined,
                onChange: setCallUpLimit,
                type: {
                    name: "number",
                    close: true
                }
            },
            {
                name: "Upper Strike, abs",
                value: callUpLimitAbs,
                onChange: setCallUpLimitAbs,
                type: {
                    name: "number",
                    close: true
                }
            }
        ]
    }
}

export default useCapVanilla