export const TICKERS_DIGITAL_CALL_SHORT = 'TICKERS_DIGITAL_CALL_SHORT'

export const REF_SPOTS_DIGITAL_CALL_SHORT = 'REF_SPOTS_DIGITAL_CALL_SHORT'

export const AC_STRIKES_DIGITAL_CALL_SHORT = "AC_STRIKES_DIGITAL_CALL_SHORT"

export const CPN_STRIKES_DIGITAL_CALL_SHORT = "CPN_STRIKES_DIGITAL_CALL_SHORT"

export const FIXING_DATES_DIGITAL_CALL_SHORT = "FIXING_DATES_DIGITAL_CALL_SHORT"

export const FIXED_CPN_AMT_DIGITAL_CALL_SHORT = "FIXED_CPN_AMT_DIGITAL_CALL_SHORT"

export const FIXING_DETAILS_STATE_DIGITAL_CALL_SHORT = "FIXING_DETAILS_STATE_DIGITAL_CALL_SHORT"

export const CURRENCY_DIGITAL_CALL_SHORT = "CURRENCY_DIGITAL_CALL_SHORT"

export const IS_WITHOUT_NOTIONAL_DIGITAL_CALL_SHORT = "IS_WITHOUT_NOTIONAL_DIGITAL_CALL_SHORT"

export const TIME_TO_MATURITY_DIGITAL_CALL_SHORT = "TIME_TO_MATURITY_DIGITAL_CALL_SHORT"

export const PERIOD_MODE_DIGITAL_CALL_SHORT = "PERIOD_MODE_DIGITAL_CALL_SHORT"

export const CPN_STRIKE_DIGITAL_CALL_SHORT = "CPN_STRIKE_DIGITAL_CALL_SHORT"

export const FIXED_CPN_AMT_PA_DIGITAL_CALL_SHORT = "FIXED_CPN_AMT_PA_DIGITAL_CALL_SHORT"

export const CPN_PERFORMANCE_DIGITAL_CALL_SHORT = "CPN_PERFORMANCE_DIGITAL_CALL_SHORT"

export const SCHEDULE_FREQ_DIGITAL_CALL_SHORT = "SCHEDULE_FREQ_DIGITAL_CALL_SHORT"

export const ALL_DATA_DIGITAL_CALL_SHORT = "ALL_DATA_DIGITAL_CALL_SHORT"

export const GREEKS_DIGITAL_CALL_SHORT = "GREEKS_DIGITAL_CALL_SHORT"

export const NOTIONAL_DIGITAL_CALL_SHORT = "NOTIONAL_DIGITAL_CALL_SHORT"

export const TRADING_DATE_DIGITAL_CALL_SHORT = "TRADING_DATE_DIGITAL_CALL_SHORT"

export const DATE_EXPIRY_DIGITAL_CALL_SHORT = "DATE_EXPIRY_DIGITAL_CALL_SHORT"