import './InputNumber.css'
import {useEffect, useState} from "react";

const InputNumber = ({value, changeCallback, label, close, disabled, changeState, isMain}) => {
    const [localValue, setLocalValue] = useState(value)

    useEffect(() => {
        setLocalValue(value)
    }, [value])

    useEffect(() => {
        if (localValue === undefined && close) {
            changeCallback(undefined)
        }
    }, [localValue])

    const save = () => {
        let saveValue = value
        if (localValue === undefined && close) {
            saveValue = undefined
        } else if (localValue !== "") {
            saveValue = Number(localValue)
        }
        console.log("save", saveValue, typeof saveValue)
        setLocalValue(saveValue)
        changeCallback(saveValue)
    }

    const blurAction = () => {
        save()
    }

    const KeyDownAction = (e) => {
        if (e.key === "Enter") {
            save()
            e.target.blur()
        }
    }

    return (
        <div style={{
            position: "relative",
        }}>
            <label className={"input_number"}>
                {label ? label : null}
                <input className={`input_number-input ${label ? "input_number-input--with_label" : null} ${isMain ? "input_number-input--main" : null} ${changeState ? isMain ? "input_number-input--changed" : "input_number-input--changed-minor" : null}`}
                       type="number"
                       disabled={!!disabled}
                       value={localValue !== undefined ? localValue : ""}
                       onChange={(e) => setLocalValue(e.target.value)}
                       onBlur={() => blurAction()}
                       onKeyDown={(e) => KeyDownAction(e)}
                />
            </label>
            {close
                ? <p style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "10px",
                    zIndex: "3",
                    fontSize: "20px",
                    cursor: "pointer",
                }}
                     onClick={() => {
                         setLocalValue(undefined)
                         console.log("close")
                     }}>&times;</p>
                : null
            }
        </div>
    )
}

export default InputNumber