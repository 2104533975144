import SettingsMarketData from "./SettingsMarketData";
import SettingsSystem from "./SettingsSystem";

const SettingsBasket = () => {
    return (
        <>
            <div className="settings__block">
                <div className={"settings__block--subtitle"}>System</div>
                <SettingsSystem/>
            </div>
        </>
    )
}

export default SettingsBasket