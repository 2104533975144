import PricingView from "../../routes/Main/PricingView/PricingView";
import useInitialStates from "../../hooks/useInitialStates";
import {useEffect, useState} from "react";
import useRefreshMarketData from "../../hooks/useRefreshMarketData";
import useSecondRefresh from "../../hooks/useSecondRefresh";

const Participation = ({mode}) => {
    const refreshManager = useRefreshMarketData()
    const initial = useInitialStates()
    const secondUpdate = useSecondRefresh()
    // useEffect(() => {
    //     initial.update()
    // }, [])
    //
    // useEffect(() => {
    //     refreshManager.refreshMD()
    // }, [])
    //
    // useEffect(() => {
    //     if (refreshManager.isRefresh) {
    //         secondUpdate.refreshParticipation()
    //     }
    // }, [refreshManager.isRefresh])

    // if (!initial.init) return null

    return (
        <PricingView mode={mode}/>
    )
}

export default Participation;