import {combineReducers} from "redux";
import {autocallReducer} from './Autocall/autocallReducer'
import {MarketDataReducer} from "./MarketData/MarketDataReducer";
import {commonReducer} from "./Common/commonReducer";
import {VanillaReducer} from "./Vanilla/VanillaReducer";
import {MarketDataProductReducer} from "./MarketDataProduct/MarketDataProductReducer";
import {BasketReducer} from "./CallOnBasket/callOnBasketReducer";
import {ParticipationReducer} from "./Participation/ParticipationReducer";
import {digitalCallReducer} from "./DigitalCall/digitalCallReducer";
import {digitalCallShortReducer} from "./DigitalCallShort/digitalCallShortReducer";
import {spreadOptionReducer} from "./SpreadOption/spreadOptionReducer";

export const rootReducer = combineReducers({
    MarketDataReducer,
    autocallReducer,
    commonReducer,
    VanillaReducer,
    MarketDataProductReducer,
    BasketReducer,
    ParticipationReducer,
    digitalCallReducer,
    digitalCallShortReducer,
    spreadOptionReducer
})