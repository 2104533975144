import {useDispatch, useSelector} from "react-redux";
import {
    set_corr_mtx,
    set_divs,
    set_service_divs,
    set_service_vols,
    set_spots,
    set_vols
} from "../../redux/MarketData/actions";
import {
    set_ref_spots_market_data_product,
    set_tickers_market_data_product
} from "../../redux/MarketDataProduct/actions";
import {
    insertNewTickerToCorrMtx,
    removeTickerFromCorrMtx, removeTickerFromDivs,
    removeTickerFromRefSpots, removeTickerFromServiceDivs, removeTickerFromServiceVols,
    removeTickerFromSpots,
    removeTickerFromTickers, removeTickerFromVols
} from "../../utils/utilsFunctions";
import {useState} from "react";

const useTickerDependenciesMarketData = () => {
    const dispatch = useDispatch()
    const [isSaved,setIsSaved] = useState(false)
    const [tickers, refSpots] = useSelector(store => {
        const {MarketDataProductReducer} = store
        return [MarketDataProductReducer.tickersMD, MarketDataProductReducer.refSpotsMD]
    })
    const [corrMtx, vols, divs, spots, serviceVols, serviceDivs] = useSelector((store) => {
        const {MarketDataReducer} = store
        return [MarketDataReducer.corrMtx, MarketDataReducer.vols, MarketDataReducer.divs, MarketDataReducer.spots,
            MarketDataReducer.serviceVols, MarketDataReducer.serviceDivs]
    })

    const saveTicker = (ticker) => {
        dispatch(set_tickers_market_data_product([...tickers, ticker.toUpperCase()]))
        dispatch(set_spots({...spots, [ticker.toUpperCase()]: 100}))
        dispatch(set_vols({...vols, [ticker.toUpperCase()]: {
                "amax": 0.9,
                "amin": 0.9,
                "curve": 50,
                "eps_curve": 50,
                "eps_skew": 50,
                "long": 33.9,
                "model": "parametric",
                "short": 17.5,
                "skew": -6,
                "speed": 50,
                "vol_cap": 10,
                "vol_floor": 0.1
            }}))
        // dispatch(set_corr_mtx({...corrMtx, [ticker.toUpperCase()]: {}}))
        dispatch(set_divs({...divs, [ticker.toUpperCase()]: []}))
        dispatch(set_service_vols({...serviceVols, [ticker.toUpperCase()]: '📈'}))
        dispatch(set_service_divs({...serviceDivs, [ticker.toUpperCase()]: '📈'}))
        dispatch(set_corr_mtx(insertNewTickerToCorrMtx(corrMtx, ticker.toUpperCase())))
        setIsSaved(true)
    }

    const removeTicker = (ticker) => {
        dispatch(set_tickers_market_data_product(removeTickerFromTickers(tickers, ticker)))
        dispatch(set_ref_spots_market_data_product(removeTickerFromRefSpots(refSpots, ticker)))
        dispatch(set_spots(removeTickerFromSpots(spots, ticker)))
        dispatch(set_corr_mtx(removeTickerFromCorrMtx(corrMtx, ticker)))
        dispatch(set_vols(removeTickerFromVols(vols, ticker)))
        dispatch(set_divs(removeTickerFromDivs(divs, ticker)))
        dispatch(set_service_vols(removeTickerFromServiceVols(serviceVols, ticker)))
        dispatch(set_service_divs(removeTickerFromServiceDivs(serviceDivs, ticker)))
    }

    const removeAllTickers = () => {
        dispatch(set_tickers_market_data_product([]))
        dispatch(set_ref_spots_market_data_product({}))
        dispatch(set_spots({}))
        dispatch(set_corr_mtx({}))
        dispatch(set_vols({}))
        dispatch(set_divs([]))
        dispatch(set_service_vols({}))
        dispatch(set_service_divs({}))
    }

    return {
        tickers,
        saveTicker,
        removeTicker,
        removeAllTickers,
        isSaved,
        setIsSaved
    }

}

export default useTickerDependenciesMarketData