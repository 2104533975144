import Plot from "react-plotly.js";
import {useEffect, useState} from "react";
import useRequest from "../../hooks/useRequest";
import {GraphBuilder} from "../../structures/GraphBuilder";
import Loader from "../Loader/Loader";

export const MultipleGraph = ({tmp}) => {

    const {get} = useRequest()
    const [data, setData] = useState()
    const [intervalId, setIntervalId] = useState(0)
    const [isActive, setIsActive] = useState(false)
    const [haveError, setHaveError] = useState(false)

    const action = async (params) => {
        const rData = await get(params.url, {params: params.data}, () => {}, () => {
            setHaveError(true)
        })
        console.warn(rData)
        const d = new GraphBuilder()
        d.setData(rData)
        return d.result
    }

    useEffect(() => {
        if (haveError) {
            clearInterval(intervalId)
        }
    }, [haveError])

    useEffect(() => {
        setIsActive(true)
        const performActions = async () => {
            const res = await action(tmp.args)
            setData(res)
        };
        const id = setInterval(() => {
            performActions()
        }, 15 * 1000)

        setIntervalId(id)

        performActions().finally(() => setIsActive(false))
        return () => clearInterval(intervalId)
    }, [tmp])

    const layout = {
        // grid: { rows: 2, columns: 2, pattern: 'independent' },
        margin: {
            l: 50,
            r: 50,
            t: 50,
            b: 50,
            pad: 4
        },
        hovermode: 'x unified',
        plot_bgcolor: "#ffffff",
        // autosize: true,
        paper_bgcolor: '#ffffff',
        font: {
            color: "#000000"
        },
        scene: {
            xaxis: {
                gridcolor: "#000000"
            },
            yaxis: {
                gridcolor: "#000000"
            },
            zaxis: {
                gridcolor: "#000000"
            }
        },
        xaxis: {
            // dtick: 5,
            gridcolor: "rgba(108,116,131,0.8)"
        },
        yaxis: {
            gridcolor: "rgba(108,116,131,0.8)"
        },
        showlegend: false,
        legend: {
            orientation: "h",
            x: 0,
            y: 1.01,
            yanchor: "bottom",
        },//!isMobile,
        style: {
            width: "100%",
            height: "100%"
        },
        // width: dimensions.width,
        // height: dimensions.height,
        autosize: true,
        title: {
            text: tmp.name
        }
    }

    if (isActive) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}>
                <Loader/>
                <p>{tmp.name}</p>
            </div>
            )

    }

    if (haveError) {
        return <div style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#b4b2b2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <p>Cannot receive data for:<br/>{tmp.name}</p>
        </div>
    }

    return <Plot data={data} layout={layout} config={{
        responsive: true,
        displayModeBar: false,
        staticPlot: false
    }} useResizeHandler={true}/>;
}