import './Settings.css'
import SettingsManager from "./SettingsManager/SettingsManager";

const Settings = () => {
    return (
        <div className={"settings"}>
            <div className={"settings--title"}>
                Settings
            </div>
            <SettingsManager/>
        </div>
    )
}

export default Settings