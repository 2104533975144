import {setLocalStorageHistory} from "../utils/localStorageHandler";
import useViewMode from "./useViewMode";
import useMarketDataRefresh from "./useMarketDataRefresh";
import useMarketDataRefreshParams from "./useMarketDataRefreshParams";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {set_is_local_window} from "../redux/Common/actions";
import useRefreshFields from "./useRefreshFields";
import useRefreshMode from "./useRefreshMode";
import useInfoPopup from "./useInfoPopup";
import useRequest from "./useRequest";
import {set_common_event, set_common_event_async} from "../redux/MarketData/actions";
import useEvents from "./useEvents";

const useRefreshMarketData = () => {
    const dispatch = useDispatch()
    const updateData = useMarketDataRefresh()
    const refreshParams = useMarketDataRefreshParams()
    const viewMode = useViewMode()
    const {get, post} = useRequest()
    const [isRefresh, setIsRefresh] = useState(false)
    const refreshFields = useRefreshFields()
    const refreshMode = useRefreshMode()
    const infoPopup = useInfoPopup()
    const {setEvents} = useEvents()

    const refreshMD = async (newFixingDates, refreshMode_) => {
        refreshMode.refreshMode = refreshMode_
        let requestData = {}
        if (viewMode.viewMode === 'Autocall') {
            if (newFixingDates !== undefined) {
                requestData = JSON.parse(updateData.data)
                requestData.product.fixing_dates = newFixingDates.fixingDates
                requestData.product.ac_strikes = newFixingDates.acStrikes
                requestData.product.cpn_strikes = newFixingDates.cpnStrikes
                requestData = JSON.stringify(requestData)
            } else {
                requestData = updateData.data
            }
        } else if (viewMode.viewMode === "Digital Call on Basket") {
            if (newFixingDates !== undefined) {
                requestData = JSON.parse(updateData.dataDigitalCall)
                requestData.product.fixing_dates = newFixingDates.fixingDates
                requestData.product.ac_strikes = newFixingDates.acStrikes
                requestData.product.cpn_strikes = newFixingDates.cpnStrikes
                requestData = JSON.stringify(requestData)
            } else {
                requestData = updateData.dataDigitalCall
            }
        } else if (viewMode.viewMode === "Digital Call") {
            if (newFixingDates !== undefined) {
                requestData = JSON.parse(updateData.dataDigitalCallShort)
                requestData.product.fixing_dates = newFixingDates.fixingDates
                requestData.product.ac_strikes = newFixingDates.acStrikes
                requestData.product.cpn_strikes = newFixingDates.cpnStrikes
                requestData = JSON.stringify(requestData)
            } else {
                requestData = updateData.dataDigitalCallShort
            }
        } else if (viewMode.viewMode === "Spread Option") {
            if (newFixingDates !== undefined) {
                requestData = JSON.parse(updateData.dataSpreadOption)
                requestData.product.fixing_dates = newFixingDates.fixingDates
                requestData.product.ac_strikes = newFixingDates.acStrikes
                requestData.product.cpn_strikes = newFixingDates.cpnStrikes
                requestData = JSON.stringify(requestData)
            } else {
                requestData = updateData.dataSpreadOption
            }
        } else if (viewMode.viewMode === 'Vanilla') {
            requestData = updateData.dataVanilla
        } else if (viewMode.viewMode === 'MarketData') {
            requestData = updateData.dataMarketData
        } else if (viewMode.viewMode === 'Call On Basket') {
            requestData = updateData.dataBasket
        } else if (viewMode.viewMode === "Protected Participation") {
            requestData = updateData.dataParticipation
        }
        const refreshFieldsList = []
        // проверка хотя бы на один true
        if (refreshMode.refreshMode === "CUSTOM") {
            if (Object.keys(refreshFields.refreshFields).some(item => refreshFields.refreshFields[item])) {
                for (let key in refreshFields.refreshFields) {
                    if (refreshFields.refreshFields[key]) {
                        refreshFieldsList.push(key)
                    }
                }
            } else {
                infoPopup.setPopupStatus(true, "Please, select at least one field for refresh", false)
                return
            }
        }

        console.log("[ refresh params ]", refreshParams)
        console.log("[ request refresh ]", JSON.parse(requestData))
        console.log("[ pricing date ]", refreshParams.pricingDate)
        dispatch(set_is_local_window(true))


        const iid = await post(
            `/rdict/post`,
            JSON.parse(requestData),
            undefined,
            () => {},
            () => {}
        )
        console.log("[ push result ]", iid)
        setLocalStorageHistory(`[success push]\t / ${new Date().toUTCString()} / ${iid} `)
        let responseMD = await get(
            `/market_data/refresh/`,
            {
                params: {
                    iid: iid,
                    pricing_date: refreshParams.pricingDate,
                    refresh_results: refreshParams.refreshResult, // TODO what is this?
                    params: JSON.stringify({
                        corr_floor: refreshParams.corrFloor,
                        corr_history: refreshParams.corrHistory,
                        corr_percentile: refreshParams.corrPercentile,
                        corr_len: refreshParams.corrLen,
                        marketdataset: refreshMode.refreshMode === "CUSTOM" ? undefined : refreshMode.refreshMode,
                        outputs: (refreshMode.refreshMode === "DEFAULT" || refreshMode.refreshMode === "HEDGING") ? [] : refreshFieldsList
                    })
                }
            },
            () => {},
            () => {}
        )

        let responseRS = responseMD
        if (refreshParams.pricingDate !== refreshParams.tradingDate) {
            responseRS = await get(
                `/market_data/refresh/`,
                {
                    params: {
                        iid: iid,
                        pricing_date: refreshParams.tradingDate,
                        refresh_results: refreshParams.refreshResult, // TODO what is this?
                        params: JSON.stringify({
                            corr_floor: refreshParams.corrFloor,
                            corr_history: refreshParams.corrHistory,
                            corr_percentile: refreshParams.corrPercentile,
                            corr_len: refreshParams.corrLen,
                            marketdataset: refreshMode.refreshMode === "CUSTOM" ? undefined : refreshMode.refreshMode,
                            outputs: (refreshMode.refreshMode === "DEFAULT" || refreshMode.refreshMode === "HEDGING") ? [] : refreshFieldsList
                        })
                    }
                },
                () => {},
                () => {}
            )
        }
        if (responseRS.includes("NaN")) {
            window.alert("NaN in refSpotData. All NaN values was convert to 100")
        }
        responseRS = JSON.parse(responseRS.replaceAll("NaN", 100))
        if (responseMD.includes("NaN")) {
            window.alert("NaN in spots. All NaN values was convert to 100")
        }
        responseMD = JSON.parse(responseMD.replaceAll("NaN", 100))
        console.warn("[ refreshMD ]", responseMD)
        if (viewMode.viewMode === 'MarketData') {
            updateData.saveMarketData(responseMD, {tickers: Object.keys(responseMD.spots)})
            updateData.saveMarketDataId(responseMD)
            updateData.saveRefSpots(responseRS)
        } else {
            updateData.saveMarketData(responseMD.market_data, responseMD.product)
            updateData.saveMarketDataId(responseMD.market_data)
            updateData.saveRefSpots(responseRS.market_data)
            updateData.saveVanillaDeps(responseRS.product, responseRS.market_data)
        }

        // Getting events by fixings
        console.log("[ RESPONSE MD ]", responseMD, JSON.stringify(responseMD).includes("null"), JSON.stringify(responseMD).includes("NaN"))
        if (responseMD.market_data.fixings && Object.keys(responseMD.market_data.fixings).length > 0) {
            const refreshMDIID = await post(
                `/rdict/post`,
                JSON.stringify(responseMD),
                undefined,
                () => {},
                () => {}
            )
            console.warn("[ Refreshed MARKET DATA IID ]", refreshMDIID)
            const events = await get(
                `/update_accrued/${refreshMDIID}`,
                {
                    params: {
                        refresh_results: refreshParams.refreshResult
                    }
                },
                () => {},
                () => {}
            )

            setEvents(events)
            console.warn("[ Refreshed MARKET DATA EVENTS ]", events, typeof events)
        } else {
            console.warn("[ Refreshed MARKET DATA IID ]", "Not found fixings")
        }


        dispatch(set_is_local_window(false))
        setLocalStorageHistory(`[success refresh]\t / ${new Date().toUTCString()} / ${iid}`)
        setIsRefresh(true)




        // post(`/rdict/post`,
        //     JSON.parse(requestData),
        //     undefined,
        //     (data) => {
        //         result = data
        //         console.log("[ push result ]", data)
        //         setLocalStorageHistory(`[success push]\t / ${new Date().toUTCString()} / ${data} `)
        //         get(
        //             `/market_data/refresh/`,
        //             {
        //                 params: {
        //                     iid: data,
        //                     pricing_date: refreshParams.pricingDate,
        //                     refresh_results: refreshParams.refreshResult, // TODO what is this?
        //                     params: JSON.stringify({
        //                         corr_floor: refreshParams.corrFloor,
        //                         corr_history: refreshParams.corrHistory,
        //                         corr_percentile: refreshParams.corrPercentile,
        //                         corr_len: refreshParams.corrLen,
        //                         marketdataset: refreshMode.refreshMode === "CUSTOM" ? undefined : refreshMode.refreshMode,
        //                         outputs: (refreshMode.refreshMode === "DEFAULT" || refreshMode.refreshMode === "HEDGING") ? [] : refreshFieldsList
        //                     })
        //                 }
        //             },
        //             (marketData) => {
        //                 if (refreshParams.pricingDate !== refreshParams.tradingDate) {
        //                     get(
        //                         `/market_data/refresh/`,
        //                         {
        //                             params: {
        //                                 iid: data,
        //                                 pricing_date: refreshParams.tradingDate,
        //                                 refresh_results: refreshParams.refreshResult, // TODO what is this?
        //                                 params: JSON.stringify({
        //                                     corr_floor: refreshParams.corrFloor,
        //                                     corr_history: refreshParams.corrHistory,
        //                                     corr_percentile: refreshParams.corrPercentile,
        //                                     corr_len: refreshParams.corrLen,
        //                                     marketdataset: refreshMode.refreshMode === "CUSTOM" ? undefined : refreshMode.refreshMode,
        //                                     outputs: (refreshMode.refreshMode === "DEFAULT" || refreshMode.refreshMode === "HEDGING") ? [] : refreshFieldsList
        //                                 })
        //                             }
        //                         },
        //                         (refSpotData) => {
        //                             if (marketData.includes("NaN")) {
        //                                 console.warn("[ NaN in marketData ]")
        //                                 window.alert("NaN in spots. All NaN values was convert to 100")
        //                             }
        //                             const responseMD = JSON.parse(marketData.replaceAll("NaN", 100))
        //                             console.warn("[ refreshMD ]", responseMD)
        //                             if (viewMode.viewMode === 'MarketData') {
        //                                 updateData.saveMarketData(responseMD, {tickers: Object.keys(responseMD.spots)})
        //                                 updateData.saveMarketDataId(responseMD)
        //                             } else {
        //                                 updateData.saveMarketData(responseMD.market_data, responseMD.product)
        //                                 updateData.saveMarketDataId(responseMD.market_data)
        //                             }
        //                             if (refSpotData.includes("NaN")) {
        //                                 console.warn("[ NaN in refSpots ]")
        //                                 window.alert("NaN in refSpotData. All NaN values was convert to 100")
        //                             }
        //                             refSpotData.replaceAll("NaN", 100)
        //                             const responseRS = JSON.parse(refSpotData.replaceAll("NaN", 100))
        //                             console.warn(responseRS)
        //                             if (viewMode.viewMode === 'MarketData') {
        //                                 updateData.saveRefSpots(responseRS)
        //                             } else {
        //                                 updateData.saveRefSpots(responseRS.market_data)
        //                             }
        //                             dispatch(set_is_local_window(false))
        //                             setLocalStorageHistory(`[success refresh]\t / ${new Date().toUTCString()} / ${data}`)
        //                             setIsRefresh(true)
        //                         },
        //                         () => {
        //                             dispatch(set_is_local_window(false))
        //                             setLocalStorageHistory(`[fail refresh]\t / ${new Date().toUTCString()} / ${data} `)
        //                         }
        //                     )
        //                 } else {
        //                     if (marketData.includes("NaN")) {
        //                         console.warn("[ NaN in marketData ]")
        //                         window.alert("NaN in spots. All NaN values was convert to 100")
        //                     }
        //                     const responseMD = JSON.parse(marketData.replaceAll("NaN", 100))
        //                     console.warn(responseMD)
        //                     if (viewMode.viewMode === 'MarketData') {
        //                         updateData.saveMarketData(responseMD, {tickers: Object.keys(responseMD.spots)})
        //                         updateData.saveMarketDataId(responseMD)
        //                         updateData.saveRefSpots(responseMD)
        //                     } else {
        //                         updateData.saveMarketData(responseMD.market_data, responseMD.product)
        //                         updateData.saveMarketDataId(responseMD.market_data)
        //                         updateData.saveRefSpots(responseMD.market_data)
        //                     }
        //                     dispatch(set_is_local_window(false))
        //                     setLocalStorageHistory(`[success refresh]\t / ${new Date().toUTCString()} / ${data}`)
        //                     setIsRefresh(true)
        //                 }
        //             },
        //             () => {
        //                 dispatch(set_is_local_window(false))
        //                 setLocalStorageHistory(`[fail refresh]\t / ${new Date().toUTCString()} / ${data.data} `)
        //             }
        //         )
        //     })
        //     .then(() => {
        //         console.log(" __ finish request __ ")
        //     })

        // axios.post(`/rdict/post`, JSON.parse(requestData))
        //     .then(data => {
        //         result = data.data
        //         console.log("[ push result ]", result)
        //         console.log("[ push parmas ]", refreshParams.corrFloor, refreshParams.corrPercentile, refreshParams.corrLen)
        //         setLocalStorageHistory(`[success push]\t / ${new Date().toUTCString()} / ${data.data} `)
        //         console.log(" [ PARAMS REFRESH ] ", {params: {
        //                 iid: data.data,
        //                 pricing_date: refreshParams.pricingDate,
        //                 refresh_results: refreshParams.refreshResult, // TODO what is this?
        //                 params: JSON.stringify({
        //                     corr_floor: refreshParams.corrFloor,
        //                     corr_history: refreshParams.corrHistory,
        //                     corr_percentile: refreshParams.corrPercentile,
        //                     corr_len: refreshParams.corrLen,
        //                     marketdataset: refreshMode.refreshMode === "CUSTOM" ? undefined : refreshMode.refreshMode,
        //                     outputs: (refreshMode.refreshMode === "DEFAULT" || refreshMode.refreshMode === "HEDGING") ? [] : refreshFieldsList
        //                 })
        //             }})
        //         axios(`/market_data/refresh/`, {
        //             params: {
        //                 iid: data.data,
        //                 pricing_date: refreshParams.pricingDate,
        //                 refresh_results: refreshParams.refreshResult, // TODO what is this?
        //                 params: JSON.stringify({
        //                     corr_floor: refreshParams.corrFloor,
        //                     corr_history: refreshParams.corrHistory,
        //                     corr_percentile: refreshParams.corrPercentile,
        //                     corr_len: refreshParams.corrLen,
        //                     marketdataset: refreshMode.refreshMode === "CUSTOM" ? undefined : refreshMode.refreshMode,
        //                     outputs: (refreshMode.refreshMode === "DEFAULT" || refreshMode.refreshMode === "HEDGING") ? [] : refreshFieldsList
        //                 })
        //             }
        //         })
        //             .then(marketData => {
        //                 console.log("[ trading date ]", refreshParams.tradingDate)
        //                 console.log("[ UPDATED_MARKET_DATA ]", marketData.data, typeof marketData.data)
        //                 if (refreshParams.pricingDate !== refreshParams.tradingDate) {
        //                     axios(`/market_data/refresh/`, {
        //                         params: {
        //                             iid: data.data, //'32956850ad475f4c60cce47dc1748834c973c8b4c1a535a603db935463a6c666'//data.data
        //                             pricing_date: refreshParams.tradingDate,
        //                             refresh_results: refreshParams.refreshResult, // TODO what is this?
        //                             params: JSON.stringify({
        //                                 corr_floor: refreshParams.corrFloor,
        //                                 corr_history: refreshParams.corrHistory,
        //                                 corr_percentile: refreshParams.corrPercentile,
        //                                 corr_len: refreshParams.corrLen,
        //                                 marketdataset: refreshMode.refreshMode === "CUSTOM" ? undefined : refreshMode.refreshMode,
        //                                 outputs: (refreshMode.refreshMode === "DEFAULT" || refreshMode.refreshMode === "HEDGING") ? [] : refreshFieldsList
        //                             })
        //                         }
        //                     })
        //                         .then(refSpotData => {
        //                             const responseMD = JSON.parse(marketData.data.replaceAll("NaN", 100))
        //                             console.warn("[ refreshMD ]", responseMD)
        //                             if (viewMode.viewMode === 'MarketData') {
        //                                 updateData.saveMarketData(responseMD, {tickers: Object.keys(responseMD.spots)})
        //                                 updateData.saveMarketDataId(responseMD)
        //                             } else {
        //                                 updateData.saveMarketData(responseMD.market_data, responseMD.product)
        //                                 updateData.saveMarketDataId(responseMD.market_data)
        //                             }
        //                             console.log("[ CHECK S REFRESH ]", refSpotData.data)
        //                             refSpotData.data.replaceAll("NaN", 100)
        //                             const responseRS = JSON.parse(refSpotData.data.replaceAll("NaN", 100))
        //                             console.warn(responseRS)
        //                             if (viewMode.viewMode === 'MarketData') {
        //                                 updateData.saveRefSpots(responseRS)
        //                             } else {
        //                                 updateData.saveRefSpots(responseRS.market_data)
        //                             }
        //                             dispatch(set_is_local_window(false))
        //                             setLocalStorageHistory(`[success refresh]\t / ${new Date().toUTCString()} / ${data.data}`)
        //                             setIsRefresh(true)
        //                         })
        //                         .catch(e => {
        //                             console.log("error", e)
        //                             dispatch(set_is_local_window(false))
        //                             setLocalStorageHistory(`[fail refresh]\t / ${new Date().toUTCString()} / ${data.data} `)
        //                         })
        //                 } else {
        //                     console.log("[ LOG equals dates ]")
        //                     console.log("[ LLLL ]", marketData.data.replaceAll("NaN", 100))
        //                     const responseMD = JSON.parse(marketData.data.replaceAll("NaN", 100))
        //                     console.warn(responseMD)
        //                     if (viewMode.viewMode === 'MarketData') {
        //                         updateData.saveMarketData(responseMD, {tickers: Object.keys(responseMD.spots)})
        //                         updateData.saveMarketDataId(responseMD)
        //                         updateData.saveRefSpots(responseMD)
        //                     } else {
        //                         updateData.saveMarketData(responseMD.market_data, responseMD.product)
        //                         updateData.saveMarketDataId(responseMD.market_data)
        //                         updateData.saveRefSpots(responseMD.market_data)
        //                     }
        //                     dispatch(set_is_local_window(false))
        //                     setLocalStorageHistory(`[success refresh]\t / ${new Date().toUTCString()} / ${data.data}`)
        //                     setIsRefresh(true)
        //                 }
        //
        //             })
        //             .catch(e => {
        //                 console.error("error while second refresh", e)
        //                 dispatch(set_is_local_window(false))
        //                 setLocalStorageHistory(`[fail refresh]\t / ${new Date().toUTCString()} / ${data.data} `)
        //             })
        //     })
        //     .catch(e => {
        //         dispatch(set_is_local_window(false))
        //         setLocalStorageHistory(`[fail push]\t / ${new Date().toUTCString()} / ${e.message} `)
        //     })
        console.log(" -- return value from refresh MD -- ")
        return iid
    }
    return {
        refreshMD,
        isRefresh,
        setIsRefresh
    }
}

export default useRefreshMarketData;