const useDetailsBasket = () => {
    return {
        "title": "Details",
        "data": [
            {
                name: "Exercise",
                value: "Call",
                type: {
                    name: "selector",
                    options: ["Call", "American"],
                    disabled: true
                }
            },
            {
                name: "Strike, %",
                value: undefined,
                type: {
                    name: "number",
                    disabled: true
                }
            }
        ]
    }
}

export default useDetailsBasket;