
export const getLocalStorageHistory = () => {
    const obj = []
    if (!localStorage.len) {
        localStorage.setItem("len", "0")
    } else {
        for (let i = 0 ; i < Number(localStorage.getItem("len")); i++) {
            if (localStorage.getItem(`request${i}`)) {
                obj[i] = localStorage[`request${i}`]
            }
        }
    }
    return obj
}

export const setLocalStorageHistory = (val) => {
    const len = Number(localStorage.getItem("len"))
    localStorage.setItem(`request${len}`, val)
    localStorage.setItem("len", `${len + 1}`)
}

export const clearStorage = () => {
    localStorage.clear()
}

export const getLSPricingHistory = () => {
    const obj = []
    if (!localStorage.pricingHistoryLen) {
        localStorage.setItem("pricingHistoryLen", "0")
    } else {
        for (let i = 0 ; i < Number(localStorage.getItem("pricingHistoryLen")); i++) {
            if (localStorage.getItem(`rid${i}`)) {
                obj[i] = JSON.parse(localStorage[`rid${i}`])
            }
        }
    }
    return obj.reverse()
}

export const clearOnlyHistory = () => {
    if (localStorage.pricingHistoryLen) {
        for (let i = 0 ; i < Number(localStorage.getItem("pricingHistoryLen")); i++) {
            if (localStorage.getItem(`rid${i}`)) {
                localStorage.removeItem(`rid${i}`)
            }
        }
        localStorage.setItem("pricingHistoryLen", "0")
    }
}

export const setLSPricingHistory = (val) => {
    const pricingHistoryLen = Number(localStorage.getItem("pricingHistoryLen"))
    localStorage.setItem(`rid${pricingHistoryLen}`, JSON.stringify(val))
    localStorage.setItem("pricingHistoryLen", `${pricingHistoryLen + 1}`)
}

export const clearLSPricingHistory = () => {
    localStorage.clear()
}

export const setTickerHistory = (val) => {
    const tickerHistoryLen = Number(localStorage.getItem("tickerHistoryLen"))
    if (tickerHistoryLen > 0) {
        for (let i = 0 ; i < tickerHistoryLen; i++) {
            if (localStorage.getItem(`ticker${i}`) === val) {
                return
            }
        }
    }
    localStorage.setItem(`ticker${tickerHistoryLen}`, val)
    localStorage.setItem("tickerHistoryLen", `${tickerHistoryLen + 1}`)
}

export const getTickerHistory = () => {
    const obj = []
    if (!localStorage.tickerHistoryLen) {
        localStorage.setItem("tickerHistoryLen", "0")
    } else {
        for (let i = 0 ; i < Number(localStorage.getItem("tickerHistoryLen")); i++) {
            if (localStorage.getItem(`ticker${i}`)) {
                obj[i] = localStorage[`ticker${i}`]
            }
        }
    }
    return obj.reverse()
}

export const setChartHistory = val => {
    const pricingHistoryLen = Number(localStorage.getItem("chartHistoryLen"))
    localStorage.setItem(`chartid${pricingHistoryLen}`, JSON.stringify(val))
    localStorage.setItem("chartHistoryLen", `${pricingHistoryLen + 1}`)
}

export const getChartHistory = () => {
    const obj = []
    if (!localStorage.chartHistoryLen) {
        localStorage.setItem("chartHistoryLen", "0")
    } else {
        const tmp = {}
        let j = 0
        for (let i = Number(localStorage.getItem("chartHistoryLen")) ; i > 0; i--) {
            if (localStorage.getItem(`chartid${i}`)) {
                if (!(localStorage.getItem(`chartid${i}`) in tmp)) {
                    tmp[localStorage.getItem(`chartid${i}`)] = 0
                    obj[j] = JSON.parse(localStorage[`chartid${i}`])
                    j++
                }
            }
        }
    }
    return obj
}

export const clearOnlyChartHistory = () => {
    if (localStorage.chartHistoryLen) {
        for (let i = 0 ; i < Number(localStorage.getItem("chartHistoryLen")); i++) {
            if (localStorage.getItem(`chartid${i}`)) {
                localStorage.removeItem(`chartid${i}`)
            }
        }
        localStorage.setItem("chartHistoryLen", "0")
    }
}

export const getLastFourChartHistory = () => {
    const obj = []
    if (!localStorage.chartHistoryLen) {
        localStorage.setItem("chartHistoryLen", "0")
    } else {
        let j = 0
        const tmp = {}
        for (let i = Number(localStorage.getItem("chartHistoryLen")) - 2; i > 0; i--) {
            if (localStorage.getItem(`chartid${i}`)) {
                if (!(localStorage.getItem(`chartid${i}`) in tmp)) {
                    tmp[localStorage.getItem(`chartid${i}`)] = 0
                    obj[j] = JSON.parse(localStorage[`chartid${i}`])
                    j++
                    if (j === 6) {
                        return obj
                    }
                }
            }
        }
    }
    return obj
}

export const setFavorite = command => {
    const favorites = localStorage.getItem("favoriteCharts")
    let parsedFavorites = {}
    if (favorites) {
        parsedFavorites = JSON.parse(favorites)
    }
    parsedFavorites[command] = 0
    localStorage.setItem("favoriteCharts", JSON.stringify(parsedFavorites))
}

export const removeFavorite = command => {
    const favorites = localStorage.getItem("favoriteCharts")
    if (favorites) {
        const parsedFavorites = JSON.parse(favorites)
        delete parsedFavorites[command]
        localStorage.setItem("favoriteCharts", JSON.stringify(parsedFavorites))
    }
}

export const getFavorites = () => {
    const favorites = localStorage.getItem("favoriteCharts")
    let parsedFavorites = {}
    if (favorites) {
        parsedFavorites = JSON.parse(favorites)
    }
    return parsedFavorites
}