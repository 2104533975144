import {useDispatch, useSelector} from "react-redux";
import useChangeState from "../useChangeState";
import useFixingDetails from "../useFixingDetails";
import {
    set_fixing_dates_digital_call_short, set_period_mode_digital_call_short, set_schedule_freq_digital_call_short,
    set_time_to_maturity_digital_call_short
} from "../../redux/DigitalCallShort/actions";
import {
    set_date_expiry_digital_call,
    set_period_mode_digital_call,
    set_time_to_maturity_digital_call
} from "../../redux/DigitalCall/actions";

export const useProductFixings = () => {
    const dispatcher = useDispatch()
    const changeState = useChangeState()
    const fixings = useFixingDetails()

    const [timeToMaturity, scheduleFreq, dateExpiry,
        tradingDate, periodMode, snapshot, fixingDates] = useSelector((state) => {
        const {digitalCallShortReducer} = state
        return [digitalCallShortReducer.timeToMaturity, digitalCallShortReducer.scheduleFreq, digitalCallShortReducer.dateExpiry,
            digitalCallShortReducer.tradingDate, digitalCallShortReducer.periodMode, digitalCallShortReducer.snapshot, digitalCallShortReducer.fixingDates]
    })

    const setDateExpiry = (value) => {
        const date = new Date(tradingDate)
        const diff = new Date(value) - date
        const msPerDay = 1000 * 60 * 60 * 24
        const days = Math.round(diff / msPerDay)
        dispatcher(set_fixing_dates_digital_call_short([value]))
        fixings.refreshFixingsByFixings((days / 365), days)
        dispatcher(set_time_to_maturity_digital_call_short((days / 365).toFixed(3)))
    }

    const setTimeToMaturity = (value) => {
        if (periodMode === "days") {
            dispatcher(set_schedule_freq_digital_call_short(value))
            value = value / 365
        } else {
            dispatcher(set_schedule_freq_digital_call_short(value * 365))
        }
        changeState.setChange(true, timeToMaturity, value)
        const date = new Date(tradingDate)
        date.setDate(date.getDate() + Math.round(value * 365))
        dispatcher(set_time_to_maturity_digital_call_short(value))
        dispatcher(set_fixing_dates_digital_call_short([date.toISOString().slice(0, 10)]))
        fixings.refreshFixingsByFixings(value, value * 365)
    }

    const setPeriodMode = (value) => {
        dispatcher(set_period_mode_digital_call_short(value))
    }

    return {
        title: "Fixings",
        data: [
            {
                name: `Term, ${periodMode}`,
                value: periodMode === "years" ? timeToMaturity : Math.round(timeToMaturity * 365),
                onChange: setTimeToMaturity,
                type: {
                    name: "number"
                }
            },
            {
                name: "Term, prd",
                value: periodMode,
                onChange: setPeriodMode,
                type: {
                    name: "selector",
                    options: ["days", "years"]
                }
            },
            {
                name: `Fixing Date`,
                value: fixingDates[0],
                onChange: setDateExpiry,
                type: {
                    name: "date"
                }
            }
        ]
    }
}