import './InputManager.css'
import InputNumber from "../InputNumber/InputNumber";
import Select from "../Select/Select";
import InputDate from "../InputDate/InputDate";

const InputManager = ({dataset}) => {
    if (dataset.type.name === "number") {
        return <InputNumber value={dataset.value} label={dataset.name} changeCallback={dataset.onChange} close={dataset.type.close} disabled={dataset.type.disabled} changeState={dataset.change ?? false} isMain={dataset.isMain ?? false}/>
    } else if (dataset.type.name === "selector") {
        return <Select values={dataset.type.options} label={dataset.name} selected={dataset.value} setSelected={dataset.onChange} disabled={dataset.type.disabled} changeState={dataset.change ?? false} isMain={dataset.isMain ?? false}/>
    } else if (dataset.type.name === "date") {
        return <InputDate value={dataset.value} title={dataset.name} changeCallback={dataset.onChange} withIcon={dataset.type.picker} />
    } else {
        return <div>InputManager</div>
    }
}

export default InputManager