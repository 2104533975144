import useProductDetailParticipation from "../../hooks/ParticipationProductData/useProductDetailParticipation";
import ProductDetailLine from "../ProductDetailLine/ProductDetailLine";

const ProductDetailViewParticipation = () => {
    const data = useProductDetailParticipation()
    return (
        <div className="product_detail_view">
            {data.map((item, index) => {
                return <ProductDetailLine key={index} dataset={item}/>
            })}
        </div>
    )
}

export default ProductDetailViewParticipation