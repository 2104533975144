import {
    AC_STRIKES_DIGITAL_CALL_SHORT,
    ALL_DATA_DIGITAL_CALL_SHORT,
    CPN_PERFORMANCE_DIGITAL_CALL_SHORT,
    CPN_STRIKE_DIGITAL_CALL_SHORT,
    CPN_STRIKES_DIGITAL_CALL_SHORT,
    CURRENCY_DIGITAL_CALL_SHORT, DATE_EXPIRY_DIGITAL_CALL_SHORT,
    FIXED_CPN_AMT_DIGITAL_CALL_SHORT,
    FIXED_CPN_AMT_PA_DIGITAL_CALL_SHORT,
    FIXING_DATES_DIGITAL_CALL_SHORT,
    FIXING_DETAILS_STATE_DIGITAL_CALL_SHORT, GREEKS_DIGITAL_CALL_SHORT,
    IS_WITHOUT_NOTIONAL_DIGITAL_CALL_SHORT, NOTIONAL_DIGITAL_CALL_SHORT,
    PERIOD_MODE_DIGITAL_CALL_SHORT,
    REF_SPOTS_DIGITAL_CALL_SHORT,
    SCHEDULE_FREQ_DIGITAL_CALL_SHORT,
    TICKERS_DIGITAL_CALL_SHORT,
    TIME_TO_MATURITY_DIGITAL_CALL_SHORT, TRADING_DATE_DIGITAL_CALL_SHORT
} from "./types";
import {makeLog} from "../../utils/logger";

const currentDate = new Date()
currentDate.setDate(currentDate.getDate() + 365)

const initialState = {
    tradingDate: new Date().toISOString().slice(0, 10), // OK
    timeToMaturity: 1, // OK
    tickers: ["OZON"], // OK
    strike: 1, // HARDCODE
    scheduleFreq: 365, // FIXME перевести в число
    refSpots: {
        "OZON": 100
    }, // OK
    productTypeId: 8, // FIXME тут не ясно откуда взять жтот id и его нет в запросе
    priceCurrency: "RUB", // FIXME не нашел где используем в запросе
    noACPeriod: 0, // OK
    isWithoutNotional: true, // OK
    isMemory: true, // OK
    refreshResult: false,
    fixingDates: ["2024-02-14"], // OK
    fixedCPNCNT: 0, // OK
    fixedCPNAMT: 0.0498, // OK
    currency: "RUB", // FIXME не нашел где используем в запросе
    cpnStrikes: [0.7], // OK
    cpnStrike: 1.0, // OK
    cpnAMTpa: 0.2, // OK
    barrier: 0, // OK
    acStrike: 10.0, // HARDCODE
    acStrikes: [1], // OK
    fixingDetailsState: [false],
    acStepdown: 0, // OK

    payoff: "EKI", // HARDCODE

    history: {},
    pricingHistory: {},

    acPerformance: "WoF", // HARDCODE
    cpnPerformance: "WoF",
    finalPerformance: "WoF", // HARDCODE

    dateExpiry: currentDate.toISOString().slice(0, 10),
    periodMode: "years",

    isCallPayoff: false, // HARDCODE
    notional: 100000000,
    greeks: {
        delta: {},
        deltaCash: {},
        vega: {},
        vegaCash: {},
        gammaCash: undefined,
        rho: undefined,
        rhoCash: undefined,
        theta: undefined
    },

    model: "LocalVol",
    modelOptions: ["LocalVol", "BlackScholes"],

    n_sims: 131072,

    events: {},

    snapshot: {
        ac_strike: 1.0,
        timeToMaturity: 1,
        scheduleFreq: 180,
        cpnStrike: 1.0,
        cpnAMTpa: 0.2,
        barrier: 0.9,
        noACPeriod: 0,
        acStepdown: 0,
        currency: "RUB",
        isWithoutNotional: true,
        periodMode: "years",
        fixedCPNAMT: 0.0498,
        fixedCPNCNT: 0,
        payoff: "EKI",
        strike: 1,
        acPerformance: "WoF",
        cpnPerformance: "WoF",
        finalPerformance: "WoF",
        isCallPayoff: false
    }
}


export const digitalCallShortReducer = (state = initialState, action) => {
    switch (action.type) {
        case TICKERS_DIGITAL_CALL_SHORT:
            makeLog("TICKERS_DIGITAL_CALL_SHORT", state.tickers, action.value)
            return {
                ...state,
                tickers: action.value
            }
        case REF_SPOTS_DIGITAL_CALL_SHORT:
            makeLog("REF_SPOTS_DIGITAL_CALL_SHORT", state.refSpots, action.value)
            return {
                ...state,
                refSpots: action.value
            }
        case AC_STRIKES_DIGITAL_CALL_SHORT:
            makeLog("AC_STRIKES_DIGITAL_CALL_SHORT", state.acStrikes, action.value)
            return {
                ...state,
                acStrikes: action.value
            }
        case CPN_STRIKES_DIGITAL_CALL_SHORT:
            makeLog("CPN_STRIKES_DIGITAL_CALL_SHORT", state.cpnStrikes, action.value)
            return {
                ...state,
                cpnStrikes: action.value
            }
        case FIXING_DATES_DIGITAL_CALL_SHORT:
            makeLog("FIXING_DATES_DIGITAL_CALL_SHORT", state.fixingDates, action.value)
            return {
                ...state,
                fixingDates: action.value
            }
        case FIXED_CPN_AMT_DIGITAL_CALL_SHORT:
            makeLog("FIXED_CPN_AMT_DIGITAL_CALL_SHORT", state.fixedCPNAMT, action.value)
            return {
                ...state,
                fixedCPNAMT: action.value
            }
        case FIXING_DETAILS_STATE_DIGITAL_CALL_SHORT:
            makeLog("FIXING_DETAILS_STATE_DIGITAL_CALL_SHORT", state.fixingDetailsState, action.value)
            return {
                ...state,
                fixingDetailsState: action.value
            }
        case CURRENCY_DIGITAL_CALL_SHORT:
            makeLog("CURRENCY_DIGITAL_CALL_SHORT", state.currency, action.value)
            return {
                ...state,
                currency: action.value
            }
        case IS_WITHOUT_NOTIONAL_DIGITAL_CALL_SHORT:
            makeLog("IS_WITHOUT_NOTIONAL_DIGITAL_CALL_SHORT", state.isWithoutNotional, action.value)
            return {
                ...state,
                isWithoutNotional: action.value
            }
        case TIME_TO_MATURITY_DIGITAL_CALL_SHORT:
            makeLog("TIME_TO_MATURITY_DIGITAL_CALL_SHORT", state.timeToMaturity, action.value)
            return {
                ...state,
                timeToMaturity: action.value
            }
        case PERIOD_MODE_DIGITAL_CALL_SHORT:
            makeLog("PERIOD_MODE_DIGITAL_CALL_SHORT", state.periodMode, action.value)
            return {
                ...state,
                periodMode: action.value
            }
        case CPN_STRIKE_DIGITAL_CALL_SHORT:
            makeLog("CPN_STRIKE_DIGITAL_CALL_SHORT", state.cpnStrike, action.value)
            return {
                ...state,
                cpnStrike: action.value
            }
        case FIXED_CPN_AMT_PA_DIGITAL_CALL_SHORT:
            makeLog("FIXED_CPN_AMT_PA_DIGITAL_CALL_SHORT", state.cpnAMTpa, action.value)
            return {
                ...state,
                fixedCPNAMT: action.value
            }
        case CPN_PERFORMANCE_DIGITAL_CALL_SHORT:
            makeLog("CPN_PERFORMANCE_DIGITAL_CALL_SHORT", state.cpnPerformance, action.value)
            return {
                ...state,
                cpnPerformance: action.value
            }
        case SCHEDULE_FREQ_DIGITAL_CALL_SHORT:
            makeLog("SCHEDULE_FREQ_DIGITAL_CALL_SHORT", state.scheduleFreq, action.value)
            return {
                ...state,
                scheduleFreq: action.value
            }
        case GREEKS_DIGITAL_CALL_SHORT:
            makeLog("GREEKS_DIGITAL_CALL_SHORT", state.greeks, action.value)
            return {
                ...state,
                greeks: action.value
            }
        case ALL_DATA_DIGITAL_CALL_SHORT:
            const currentState = {...state}

            Object.keys(currentState).forEach(key => {
                currentState[key] = key in action.value ? action.value[key] : undefined
            })
            return currentState
        case NOTIONAL_DIGITAL_CALL_SHORT:
            makeLog("NOTIONAL_DIGITAL_CALL_SHORT", state.notional, action.value)
            return {
                ...state,
                notional: action.value
            }
        case TRADING_DATE_DIGITAL_CALL_SHORT:
            makeLog("TRADING_DATE_DIGITAL_CALL_SHORT", state.tradingDate, action.value)
            return {
                ...state,
                tradingDate: action.value
            }
        case DATE_EXPIRY_DIGITAL_CALL_SHORT:
            makeLog("DATE_EXPIRY_DIGITAL_CALL_SHORT", state.dateExpiry, action.value)
            return {
                ...state,
                dateExpiry: action.value
            }
        default:
            return state
    }
}