import {useDispatch, useSelector} from "react-redux";
import {set_market_data_id, set_rate} from "../../redux/MarketData/actions";
import useAllMarketData from "../useAllMarketData";
import useRequest from "../useRequest";

const useRate = () => {
    const dispatch = useDispatch()
    const allData = useAllMarketData()
    const {post} = useRequest()
    const rate = useSelector(state => state.MarketDataReducer.rate)
    const result = []

    const setRate = (item, value) => {
        const tmp = JSON.parse(JSON.stringify(allData))
        tmp.rate[item] = value / 100
        post(
            "/rdict/post",
            tmp,
            undefined,
            (data) => {dispatch(set_market_data_id(data))}
        )
            .then(() => console.log(" __ POST RATE __ "))
        dispatch(set_rate({...rate, [item]: (value / 100)}))
    }

    for (const item of Object.keys(rate)) {
        result.push({
            name: item,
            value: Number((rate[item] * 100).toFixed(2)),
            onChange: value => setRate(item, value),
            type: {
                name: "number"
            }
        })
    }
    return result
}

export default useRate;