
import ProductDetailLine from "../ProductDetailLine/ProductDetailLine";
import useProductDigitalCall from "../../hooks/DigitalCallProductData/useProductDigitalCall";

const ProductDetailViewDigitalCall = () => {
    const data = useProductDigitalCall()
    return (
        <div className="product_detail_view">
            {data.map((item, index) => {
                return <ProductDetailLine key={index} dataset={item}/>
            })}
        </div>
    )
}

export default ProductDetailViewDigitalCall;