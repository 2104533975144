import axios from "axios";
import useRefreshMarketData from "./useRefreshMarketData";
import useProductDataRefresh from "./useProductDataRefresh";
import useMarketDataRefresh from "./useMarketDataRefresh";
import useSecondRefresh from "./useSecondRefresh";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {set_refresh_by_id, set_view_mode} from "../redux/Common/actions";
import {set_tickers_market_data_product} from "../redux/MarketDataProduct/actions";
import useInfoPopup from "./useInfoPopup";
import useViewMode from "./useViewMode";
import useEvents from "./useEvents";

const useRefreshByRequestId = () => {
    const dispatch = useDispatch()
    const infoPopup = useInfoPopup()
    const saveMarketData = useMarketDataRefresh()
    const secondRefresh = useSecondRefresh()
    const {setEvents} = useEvents()
    const updateMarketData = useRefreshMarketData()
    const [refreshState, setRefreshState] = useState(false)
    const saveProduct = useProductDataRefresh()
    const viewMode = useViewMode()
    const refresh = (requestId, isById) => {
        if (isById) {
            dispatch(set_refresh_by_id(true))
        }
        axios.get(`/rdict/get/${requestId}`)
            .then(resp => {
                const data = resp.data
                if (data.product === undefined) {
                    dispatch(set_view_mode(data.class_name))
                    if (data.class_name === "MarketData") {
                        saveMarketData.saveMarketData(data, {tickers: Object.keys(data.spots)})
                        dispatch(set_tickers_market_data_product(Object.keys(data.spots)))
                        setRefreshState(true)
                    }
                } else {
                    dispatch(set_view_mode(data.product.class_name))
                    if (data.product.class_name === "Autocall") {
                        console.log(" AUUUTTOOOCCAAAALLLL ")
                        saveProduct.setAllParamsAutocall(data.product)
                        saveMarketData.saveMarketData(data.market_data, data.product)
                        setRefreshState(true)
                    } else if (data.product.class_name === "Vanilla") {
                        saveProduct.setAllParamsVanilla(data.product)
                        saveMarketData.saveMarketData(data.market_data, data.product)
                        setRefreshState(true)
                    }
                }

                if (data.events !== undefined) {
                    setEvents(data.events)
                }
            })
            .then(() => {
                infoPopup.setPopupStatus(true, "Success update from Request ID", true)
                dispatch(set_refresh_by_id(false))
            })
            .catch(e => {
                console.error(e)
            })
    }

    useEffect(() => {
        if (refreshState) {
            // updateMarketData.refreshMD()
            setRefreshState(false)
        }
    }, [refreshState])

    // useEffect(() => {
    //     if (updateMarketData.isRefresh && viewMode.viewMode === "Vanilla") {
    //         secondRefresh.refreshVanilla()
    //         updateMarketData.setIsRefresh(false)
    //     }
    // }, [updateMarketData.isRefresh])

    return {
        refresh
    }
}

export default useRefreshByRequestId;