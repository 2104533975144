import {useDispatch, useSelector} from "react-redux";
import {set_ref_spots_digital_call, set_tickers_digital_call} from "../redux/DigitalCall/actions";
import {
    insertNewTickerToCorrMtx,
    insertNewTickerToDivs,
    insertNewTickerToRefSpots,
    insertNewTickerToServiceDivs,
    insertNewTickerToServiceVols,
    insertNewTickerToSpots,
    insertNewTickerToTickers,
    insertNewTickerToVols, removeTickerFromCorrMtx, removeTickerFromDivs,
    removeTickerFromRefSpots, removeTickerFromServiceDivs, removeTickerFromServiceVols,
    removeTickerFromSpots,
    removeTickerFromTickers, removeTickerFromVols
} from "../utils/utilsFunctions";
import {
    set_corr_mtx,
    set_divs,
    set_service_divs,
    set_service_vols,
    set_spots,
    set_vols
} from "../redux/MarketData/actions";
import {useState} from "react";

const useTickerDependenciesDigitalCall = () => {
    const dispatch = useDispatch()
    const [isSaved, setIsSaved] = useState(false)
    const [tickers, ref_spots] = useSelector((store) => {
        const {digitalCallReducer} = store
        return [digitalCallReducer.tickers, digitalCallReducer.refSpots]
    })

    const [corrMtx, vols, divs, spots, serviceVols, serviceDivs] = useSelector((store) => {
        const {MarketDataReducer} = store
        return [MarketDataReducer.corrMtx, MarketDataReducer.vols, MarketDataReducer.divs, MarketDataReducer.spots,
            MarketDataReducer.serviceVols, MarketDataReducer.serviceDivs]
    })

    const saveTicker = (ticker) => {
        dispatch(set_tickers_digital_call(insertNewTickerToTickers(tickers, ticker.toUpperCase())))
        dispatch(set_ref_spots_digital_call(insertNewTickerToRefSpots(ref_spots, ticker.toUpperCase())))
        dispatch(set_spots(insertNewTickerToSpots(spots, ticker.toUpperCase())))
        dispatch(set_corr_mtx(insertNewTickerToCorrMtx(corrMtx, ticker.toUpperCase())))
        dispatch(set_vols(insertNewTickerToVols(vols, ticker.toUpperCase())))
        dispatch(set_divs(insertNewTickerToDivs(divs, ticker.toUpperCase())))
        dispatch(set_service_vols(insertNewTickerToServiceVols(serviceVols, ticker.toUpperCase)))
        dispatch(set_service_divs(insertNewTickerToServiceDivs(serviceDivs, ticker.toUpperCase())))
        setIsSaved(true)
    }

    const removeTicker = (ticker) => {
        dispatch(set_tickers_digital_call(removeTickerFromTickers(tickers, ticker.toUpperCase())))
        dispatch(set_ref_spots_digital_call(removeTickerFromRefSpots(ref_spots, ticker.toUpperCase())))
        dispatch(set_spots(removeTickerFromSpots(spots, ticker)))
        dispatch(set_corr_mtx(removeTickerFromCorrMtx(corrMtx, ticker)))
        dispatch(set_vols(removeTickerFromVols(vols, ticker)))
        dispatch(set_divs(removeTickerFromDivs(divs, ticker)))
        dispatch(set_service_vols(removeTickerFromServiceVols(serviceVols, ticker)))
        dispatch(set_service_divs(removeTickerFromServiceDivs(serviceDivs, ticker)))
    }

    const removeAllTickers = () => {
        dispatch(set_tickers_digital_call([]))
        dispatch(set_ref_spots_digital_call({}))
        dispatch(set_spots({}))
        dispatch(set_corr_mtx({}))
        dispatch(set_vols({}))
        dispatch(set_divs([]))
        dispatch(set_service_vols({}))
        dispatch(set_service_divs({}))
    }

    return {
        tickers,
        saveTicker,
        removeTicker,
        removeAllTickers,
        isSaved,
        setIsSaved
    }
}

export default useTickerDependenciesDigitalCall