import {
    ALL_DATA_SPREAD_OPTION,
    CURRENCY_SPREAD_OPTION, FIXING_DATES_SPREAD_OPTION, GREEKS_SPREAD_OPTION,
    IS_WITHOUT_NOTIONAL_SPREAD_OPTION, NOTIONAL_SPREAD_OPTION, PERIOD_MODE_SPREAD_OPTION,
    REF_SPOTS_SPREAD_OPTION, SPREAD_OPTION_ORDER_SPREAD_OPTION,
    TICKERS_SPREAD_OPTION, TIME_TO_MATURITY_SPREAD_OPTION, TRADING_DATE_SPREAD_OPTION
} from "./types";
import {makeLog} from "../../utils/logger";

const currentDate = new Date()
currentDate.setDate(currentDate.getDate() + 365)


const initialState = {
    tradingDate: new Date().toISOString().slice(0, 10), // OK
    timeToMaturity: 1, // OK
    tickers: ["SBER", "IMOEX"], // OK
    strike: 1, // HARDCODE
    scheduleFreq: 365, // FIXME перевести в число
    refSpots: {
        "SBER": 100,
        "IMOEX": 100
    }, // OK
    productTypeId: 8, // FIXME тут не ясно откуда взять жтот id и его нет в запросе
    priceCurrency: "RUB", // FIXME не нашел где используем в запросе
    noACPeriod: 0, // OK
    isWithoutNotional: true, // OK
    isMemory: true, // OK
    refreshResult: false,
    fixingDates: [currentDate.toISOString().slice(0, 10)], // OK
    fixedCPNCNT: 0, // OK
    fixedCPNAMT: 0, // OK
    currency: "RUB", // FIXME не нашел где используем в запросе
    currencyOptions: ["RUB", "USD", "EUR", "CNY"],
    cpnStrikes: [5.0], // OK
    cpnStrike: 5.0, // OK
    cpnAMTpa: 0.2, // OK
    barrier: 0, // OK
    acStrike: 10.0, // HARDCODE
    acStrikes: [10.0], // OK
    fixingDetailsState: [false],
    acStepdown: 0, // OK

    payoff: "EKI", // HARDCODE

    history: {},
    pricingHistory: {},

    acPerformance: "WoF", // HARDCODE
    cpnPerformance: "WoF",
    finalPerformance: "WoF", // HARDCODE

    dateExpiry: currentDate.toISOString().slice(0, 10),
    periodMode: "years",

    isCallPayoff: false, // HARDCODE
    notional: 100000000,
    greeks: {
        delta: {},
        deltaCash: {},
        vega: {},
        vegaCash: {},
        gammaCash: undefined,
        rho: undefined,
        rhoCash: undefined,
        theta: undefined
    },

    model: "LocalVol",
    modelOptions: ["LocalVol", "BlackScholes"],

    n_sims: 131072,

    events: {},

    snapshot: {
        ac_strike: 1.0,
        timeToMaturity: 1,
        scheduleFreq: 180,
        cpnStrike: 1.0,
        cpnAMTpa: 0.2,
        barrier: 0.9,
        noACPeriod: 0,
        acStepdown: 0,
        currency: "RUB",
        isWithoutNotional: true,
        periodMode: "years",
        fixedCPNAMT: 0.0498,
        fixedCPNCNT: 0,
        payoff: "EKI",
        strike: 1,
        acPerformance: "WoF",
        cpnPerformance: "WoF",
        finalPerformance: "WoF",
        isCallPayoff: false
    },
    spreadOption: true,
    spreadOptionOrder: ["SBER", "IMOEX"]
}

export const spreadOptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case TICKERS_SPREAD_OPTION:
            makeLog("TICKERS_SPREAD_OPTION", state.tickers, action.value)
            return {
                ...state,
                tickers: action.value
            }
        case REF_SPOTS_SPREAD_OPTION:
            makeLog("REF_SPOTS_SPREAD_OPTION", state.refSpots, action.value)
            return {
                ...state,
                refSpots: action.value
            }
        case IS_WITHOUT_NOTIONAL_SPREAD_OPTION:
            makeLog("IS_WITHOUT_NOTIONAL_SPREAD_OPTION", state.isWithoutNotional, action.value)
            return {
                ...state,
                isWithoutNotional: action.value
            }
        case CURRENCY_SPREAD_OPTION:
            makeLog("CURRENCY_SPREAD_OPTION", state.currency, action.value)
            return {
                ...state,
                currency: action.value
            }
        case FIXING_DATES_SPREAD_OPTION:
            makeLog("FIXING_DATES_SPREAD_OPTION", state.fixingDates, action.value)
            return {
                ...state,
                fixingDates: action.value
            }
        case TIME_TO_MATURITY_SPREAD_OPTION:
            makeLog("TIME_TO_MATURITY_SPREAD_OPTION", state.timeToMaturity, action.value)
            return {
                ...state,
                timeToMaturity: action.value
            }
        case PERIOD_MODE_SPREAD_OPTION:
            makeLog("PERIOD_MODE_SPREAD_OPTION", state.periodMode, action.value)
            return {
                ...state,
                periodMode: action.value
            }
        case SPREAD_OPTION_ORDER_SPREAD_OPTION:
            makeLog("SPREAD_OPTION_ORDER_SPREAD_OPTION", state.spreadOptionOrder, action.value)
            return {
                ...state,
                spreadOptionOrder: action.value
            }
        case TRADING_DATE_SPREAD_OPTION:
            makeLog("TRADING_DATE_SPREAD_OPTION", state.tradingDate, action.value)
            return {
                ...state,
                tradingDate: action.value
            }
        case GREEKS_SPREAD_OPTION:
            makeLog("GREEKS_SPREAD_OPTION", state.greeks, action.value)
            return {
                ...state,
                greeks: action.value
            }
        case ALL_DATA_SPREAD_OPTION:
            const currentState = {...state}

            Object.keys(currentState).forEach(key => {
                currentState[key] = key in action.value ? action.value[key] : undefined
            })
            return currentState
        case NOTIONAL_SPREAD_OPTION:
            makeLog("NOTIONAL_SPREAD_OPTION", state.notional, action.value)
            return {
                ...state,
                notional: action.value
            }
        default:
            return state
    }
}