import {useDispatch, useSelector} from "react-redux";
import {set_is_call_payoff} from "../../redux/Autocall/actions";

const useOtherDetail = () => {
    const dispatch = useDispatch();
    const [isCallPayoff, snapshot] = useSelector(store => {
        const { autocallReducer } = store;
        return [autocallReducer.isCallPayoff, autocallReducer.snapshot]
    })

    const setIsCallPayoff = (value) => {
        dispatch(set_is_call_payoff(value === "Yes"));
    }

    return {
        title: "Other",
        data: [
            {
                name: "Call Payoff",
                value: isCallPayoff ? "Yes" : "No",
                onChange: setIsCallPayoff,
                change: snapshot ? snapshot.isCallPayoff !== isCallPayoff : false,
                isMain: true,
                type: {
                    name: "selector",
                    options: ["Yes", "No"]
                }
            },
            {
                name: "Call Strike, %",
                value: isCallPayoff ? 100 : "",
                onChange: () => {},
                type: {
                    name: "number",
                    disabled: true
                }
            }
        ]
    }
}

export default useOtherDetail