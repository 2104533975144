import {
    AC_PERFORMANCE,
    AC_STEPDOWN,
    AC_STRIKE,
    AC_STRIKES, ALL_DATA_AUTOCALL,
    BARRIER,
    CPN_AMT_PA, CPN_PERFORMANCE,
    CPN_STRIKE,
    CPN_STRIKES, CURRENCY_AUTOCALL, DATE_EXPIRY_AUTOCALL, EVENT_AUTOCALL, FINAL_PERFORMANCE, FIXED_CPN_AMT,
    FIXED_CPN_CNT,
    FIXING_DATES,
    FIXING_DETAILS_STATE, GREEKS_AUTOCALL,
    HISTORY, IS_CALL_PAYOFF,
    IS_WITHOUT_NOTIONAL, MODEL_AUTOCALL, N_SIMS_AUTOCALL,
    NO_AC_PERIOD, NOTIONAL_AUTOCALL,
    PAYOFF, PERIOD_MODE, PRICING_HISTORY,
    REF_SPOTS, REFRESH_RESULTS,
    SCHEDULE_FREQ, SNAPSHOT_AUTOCALL,
    STRIKE, TICKERS,
    TIME_TO_MATURITY,
    TRADING_DATE
} from "./types";

// PRISING_REQUEST
export const set_is_without_notional = (value) => {
    return {
        type: IS_WITHOUT_NOTIONAL,
        value: value
    }
}

export const set_ac_stepdown = (value) => {
    return {
        type: AC_STEPDOWN,
        value
    }
}

export const set_time_to_maturity = (value) => {
    return {
        type: TIME_TO_MATURITY,
        value
    }
}

export const set_schedule_freq = (value) => {
    return {
        type: SCHEDULE_FREQ,
        value
    }
}

export const set_ac_strike = (value) => {
    return {
        type: AC_STRIKE,
        value
    }
}

export const set_no_ac_periods = (value) => {
    return {
        type: NO_AC_PERIOD,
        value
    }
}

export const set_cpn_strike = (value) => {
    return {
        type: CPN_STRIKE,
        value
    }
}

export const set_fixed_cpn_cnt = (value) => {
    return {
        type: FIXED_CPN_CNT,
        value
    }
}

export const set_fixed_cpn_amt = (value) => {
    return {
        type: FIXED_CPN_AMT,
        value
    }
}

export const set_cpn_amt_pa = (value) => {
    return {
        type: CPN_AMT_PA,
        value
    }
}

export const set_payoff = (value) => {
    return {
        type: PAYOFF,
        value
    }
}

export const set_strike = (value) => {
    return {
        type: STRIKE,
        value
    }
}

export const set_barrier = (value) => {
    return {
        type: BARRIER,
        value
    }
}

export const set_fixing_details_state = (value) => {
    return {
        type: FIXING_DETAILS_STATE,
        value
    }
}


export const set_trading_date = (value) => {
    return {
        type: TRADING_DATE,
        value
    }
}

export const set_fixing_dates = (value) => {
    return {
        type: FIXING_DATES,
        value
    }
}

export const set_ac_strikes = (value) => {
    return {
        type: AC_STRIKES,
        value
    }
}

export const set_cpn_strikes = (value) => {
    return {
        type: CPN_STRIKES,
        value
    }
}

export const set_ref_spots = (value) => {
    return {
        type: REF_SPOTS,
        value
    }
}

export const set_history = (value) => {
    return {
        type: HISTORY,
        value
    }
}


export const set_tickers = (value) => {
    console.log("{ change ticker }", value)
    return {
        type: TICKERS,
        value
    }
}

export const set_refresh_results = (value) => {
    return {
        type: REFRESH_RESULTS,
        value
    }
}

export const set_pricing_history = (value) => {
    return {
        type: PRICING_HISTORY,
        value
    }
}


export const set_ac_performance = (value) => {
    return {
        type: AC_PERFORMANCE,
        value
    }
}

export const set_cpn_performance = (value) => {
    return {
        type: CPN_PERFORMANCE,
        value
    }
}

export const set_final_performance = (value) => {
    return {
        type: FINAL_PERFORMANCE,
        value
    }
}

export const set_currency_autocall = (value) => {
    return {
        type: CURRENCY_AUTOCALL,
        value
    }
}

export const set_date_expiry_autocall = (value) => {
    return {
        type: DATE_EXPIRY_AUTOCALL,
        value
    }
}

export const set_period_mode = (value) => {
    return {
        type: PERIOD_MODE,
        value
    }
}

export const set_is_call_payoff = (value) => {
    return {
        type: IS_CALL_PAYOFF,
        value
    }
}

export const set_notional_autocall = (value) => {
    return {
        type: NOTIONAL_AUTOCALL,
        value
    }
}

export const set_greeks_autocall = (value) => {
    return {
        type: GREEKS_AUTOCALL,
        value
    }
}


export const set_model_autocall = value => {
    return {
        type: MODEL_AUTOCALL,
        value
    }
}

export const set_n_sims_autocall = value => {
    return {
        type: N_SIMS_AUTOCALL,
        value
    }
}

export const set_all_data_autocall = value => {
    return {
        type: ALL_DATA_AUTOCALL,
        value: value.product
    }
}

export const set_event_autocall = value => {
    return {
        type: EVENT_AUTOCALL,
        value: value
    }
}

export const set_snapshot_autocall = value => {
    return {
        type: SNAPSHOT_AUTOCALL,
        value: value
    }
}
