import {useSelector} from "react-redux";

/**
 * Hook for return current values for inserting to pricing function
 * @returns {{fixing_dates: string, typology: (*|number|boolean|string), call_up_limit: (*|number|boolean|string), strike: (*|number|boolean|string), trading_date: (*|number|boolean|string), payoff: (*|number|boolean|string), is_without_notional: (*|number|boolean|string), currency: (*|number|boolean|string), class_name: (*|number|boolean|string), tickers: (*|number|boolean|string), version: (*|number|boolean|string), price_currency: (*|number|boolean|string)}}
 */
const useProductVanilla = () => {
    const [className, fixingDate, isWithoutNotional, payoff, priceCurrency, currency, strikeAbs, callUpLimitAbs, tickers, typology, tradingDate, version, refSpots] = useSelector((state) => {
        const {VanillaReducer} = state;
        return [VanillaReducer.className, VanillaReducer.fixingDate, VanillaReducer.isWithoutNotional, VanillaReducer.payoff, VanillaReducer.priceCurrency, VanillaReducer.currency, VanillaReducer.strikeAbs, VanillaReducer.callUpLimitAbs, VanillaReducer.tickersVanilla, VanillaReducer.typology, VanillaReducer.tradingDate, VanillaReducer.version, VanillaReducer.refSpots]
    })

    return {
        class_name: className,
        fixing_dates: fixingDate,
        is_without_notional: isWithoutNotional,
        payoff: payoff,
        price_currency: priceCurrency,
        currency: currency,
        strike: strikeAbs,
        call_up_limit: callUpLimitAbs ? callUpLimitAbs : undefined,
        tickers: tickers,
        typology: typology,
        trading_date: tradingDate,
        version: version,
        ref_spots: refSpots
    }
}

export default useProductVanilla