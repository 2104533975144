import Container from "../../components/Container/Container";
import "./Header.css"
import SearchLine from "./SearchLine/SearchLine";
import Nav from "./Nav/Nav";
import MobileNav from "./MobileNav/MobileNav";
import Logo from "../../components/Logo/Logo";
import SnapShotBtn from "./SnapShotBtn/SnapShotBtn";

const Header = ({selectHeader}) => {
    const isMobile = window.innerWidth <= 768

    return (
        <div className="header">
            <Container>
                <div className="header_wrapper">
                    <div className={`header_wrapper-content`}>
                        <div className={`header_wrapper-content-main`}>
                            <Logo />
                            <SearchLine/>
                            <SnapShotBtn/>
                        </div>
                        {isMobile
                            ? <MobileNav selection={selectHeader}/>
                            : <Nav selection={selectHeader}/>
                        }
                    </div>
                </div>
            </Container>
        </div>
    )
}
export default Header