import Header from "../Header/Header";
import Container from "../../components/Container/Container";
import "./Title.css"
import logo from "../../static/logo_full.jpg"
import logo_short from "../../static/logo.jpg"
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useMainView from "../../hooks/useMainView";

const Title = () => {
    const navigate = useNavigate()
    const mainView = useMainView()
    const displayWidth = window.innerWidth
    useEffect(() => {
        document.title = "Rumberg | Main"
        mainView.resetCommand()
        if (localStorage.getItem("oAuth_pricing") === null) {
            navigate("/auth")
        }
    }, [])

    const onStaticDataClick = () => {
        localStorage.setItem("rumberg_static_data", "* DES")
        navigate("/pricing")
    }

    const onChartClick = () => {
        localStorage.setItem("rumberg_chart_data", "IMOEX USD000000TOD SBER RGBI GN")
        navigate("/pricing")
    }

    const onMarketDataClick = () => {
        localStorage.setItem("rumberg_market_data", "IMOEX DVD")
        navigate("/pricing")
    }

    return (
        <div>
            <Header />
            <Container>
                <div className="window-container">
                    <div className="windows">
                        <div className="window top-left" onClick={onStaticDataClick}>Static Data</div>
                        <div className="window top-right" onClick={onChartClick}>
                            <div>Charts</div>
                        </div>
                        <div className="window bottom-left" onClick={() => navigate("/pricing")}>Pricing</div>
                        <div className="window bottom-right" onClick={() => onMarketDataClick()}>Market Data</div>
                        <div className="title__logo">
                            <img src={displayWidth < 768 ? logo_short : logo} alt="logo"/>
                        </div>
                        {displayWidth < 768 && <div className="title__logo second"><img src={logo_short} alt="logo"/></div>}
                        {displayWidth < 768 && <div className="title__logo third"><img src={logo_short} alt="logo"/></div>}
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Title;