import './ResultField.css'
import image from './../../static/copy.svg'
import {useState} from "react";
import Loader from "../Loader/Loader";
import copy from 'clipboard-copy'
import useInfoPopup from "../../hooks/useInfoPopup";

const ResultField = ({load, state, description}) => {
    const [isHover, setIsHover] = useState(false)
    const infoPopup = useInfoPopup()
    const truncateStr = (str) => {
        return str.length > 10 ? str.substring(0, 10) + "..." : str
    }
    return (
        <div className={`result_field`}>
            <div className={`result_field-title`}>Result</div>
            <div className={`result_field-value`}
                 onClick={() => {
                    copy(description ?? state)
                        .then(() => infoPopup.setPopupStatus(true, 'Copied to clipboard', true))
                }}
                 onMouseEnter={() => {setIsHover(true)}}
                 onMouseLeave={() => {setIsHover(false)}}
            >
                {load ? <Loader /> : <p id="priceResult">{truncateStr(state)}</p> }
                {isHover ? <img style={{backgroundColor: 'rgba(227, 227, 229, 1)', marginLeft: '8px'}} src={image} alt={`copy`} /> : null}
            </div>
        </div>
    )
}
export default ResultField