/**
 * Convert frequency days to string value
 * @param num integer value of frequency days. Possible values: 30, 91, 180, 365
 * @returns {string} string value of frequency days. Possible values: "mon", "qtr", "s/a", "ann"
 */
export const freqDaysToStr = (num) => {
    if (num === 30) {
        num = "mon"
    } else if (num === 91) {
        num = "qtr"
    } else if (num === 180) {
        num = "s/a"
    } else if (num === 365) {
        num = "ann"
    }
    return num
}

/**
 * Convert string value of frequency days to integer value
 * @param str string value of frequency days. Possible values: "mon", "qtr", "s/a", "ann"
 * @returns {number} integer value of frequency days. Possible values: 30, 91, 180, 365
 */
export const strToFreqDays = (str) => {
    if (str === "mon") {
        str = 30
    } else if (str === "qtr") {
        str = 91
    } else if (str === "s/a") {
        str = 180
    } else if (str === "ann") {
        str = 365
    }
    return str
}


/**
 * Inserting new ticker to tickers array
 * @param tickers array of tickers
 * @param ticker new ticker
 * @returns {array} array of tickers with new ticker
 */
export const insertNewTickerToTickers = (tickers, ticker) => {
    return [...tickers, ticker]
}


export const insertNewTickerToRefSpots = (refSpots, ticker) => {
    return {...refSpots, [ticker]: 100}
}

export const insertNewTickerToSpots = (spots, ticker) => {
    return {...spots, [ticker]: 100}
}

export const insertNewTickerToCorrMtx = (corrMtx, ticker) => {
    const result = {
        [ticker]: {[ticker]: 1}
    }
    for (const key of Object.keys(corrMtx)) {
        result[key] = {...corrMtx[key], [ticker]: 1}
        result[ticker] = {...result[ticker], [key]: 1}
    }
    console.log(result)
    return result
}

export const insertNewTickerToVols = (vols, ticker) => {
    return {
        ...vols, [ticker]: {
            "amax": 0.9,
            "amin": 0.9,
            "curve": 50,
            "eps_curve": 50,
            "eps_skew": 50,
            "long": 33.9,
            "model": "parametric",
            "short": 17.5,
            "skew": -6,
            "speed": 50,
            "vol_cap": 10,
            "vol_floor": 0.1
        }
    }
}

export const insertNewTickerToDivs = (divs, ticker) => {
    return {...divs, [ticker]: []}
}

export const insertNewTickerToServiceVols = (serviceVols, ticker) => {
    return {...serviceVols, [ticker]: '📈'}
}

export const insertNewTickerToServiceDivs = (serviceDivs, ticker) => {
    return {...serviceDivs, [ticker]: '📈'}
}

export const removeTickerFromTickers = (tickers, ticker) => {
    return tickers.filter(elem => elem !== ticker)
}

export const removeTickerFromRefSpots = (refSpots, ticker) => {
    const result = {...refSpots}
    delete result[ticker]
    return result
}

export const removeTickerFromSpots = (spots, ticker) => {
    const result = {...spots}
    delete result[ticker]
    return result
}

export const removeTickerFromCorrMtx = (corrMtx, ticker) => {
    const result = {...corrMtx}
    delete result[ticker]
    for (const key of Object.keys(result)) {
        delete result[key][ticker]
    }
    return result
}

export const removeTickerFromVols = (vols, ticker) => {
    const result = {...vols}
    delete result[ticker]
    return result
}

export const removeTickerFromDivs = (divs, ticker) => {
    const result = {...divs}
    delete result[ticker]
    return result
}

export const removeTickerFromServiceVols = (serviceVols, ticker) => {
    const result = {...serviceVols}
    delete result[ticker]
    return result
}

export const removeTickerFromServiceDivs = (serviceDivs, ticker) => {
    const result = {...serviceDivs}
    delete result[ticker]
    return result
}

export const createVoDates = (arrayOfDays, startDate) => {
    const dates = []
    arrayOfDays.map((el, i) => {
        const t = new Date(startDate)
        t.setDate(t.getDate() + el)
        dates[i] = t.toISOString().slice(0, 10)
        return undefined
    })
    return dates
}

export const daysDifference = (date1, date2) => {
    // Преобразование строк в объекты Date
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    // Разница между датами в миллисекундах
    const diffInMilliseconds = Math.abs(d1 - d2);
    // Перевод миллисекунд в дни
    const diffInDays = diffInMilliseconds / (24 * 60 * 60 * 1000);
    return Math.round(diffInDays);
}

export const compareObjects = (obj1, obj2) => {
    const entries1 = Object.entries(obj1);
    const entries2 = Object.entries(obj2);

    // Сортируем массивы по ключам (алфавитный порядок)
    entries1.sort(([key1], [key2]) => key1.localeCompare(key2));
    entries2.sort(([key1], [key2]) => key1.localeCompare(key2));

    // Преобразуем отсортированные массивы обратно в объекты
    const sortedObj1 = Object.fromEntries(entries1);
    const sortedObj2 = Object.fromEntries(entries2);
    console.log("compareObjects", sortedObj1, sortedObj2)
    return JSON.stringify(sortedObj1) === JSON.stringify(sortedObj2)
}
